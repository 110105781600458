.withdrawal-confirm-warpper {
	.withdrawal-confirm-title {
		font-weight: normal;
	}
	.withdrawal-confirm--image {
		width: 22rem;
	}
	.withdrawal-confirm-option {
		background-color: var(--base_wallet-sidebar-and-popup);
		padding: 25px;
	}
}
