.color_picker-container {
	margin-bottom: 22px;
	position: relative;
    color: #ffffff;
}
.color_picker-container .picker-wrapper {
	line-height: 22px;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	display: flex;
	margin: 0;
	padding: 4px 8px;
	cursor: pointer;
	align-items: center;
}
.color_picker-container .picker-wrapper .pickr {
	align-items: center;
	display: flex;
	margin-right: 5px;
}
.color_picker-container .picker-wrapper .pickr .pcr-button {
	padding: 2px;
	height: 1rem;
	width: 1rem;
}
.color_picker-container .twitter-picker {
	position: absolute !important;
	right: 0;
	bottom: -5px;
	z-index: 10;
}
