@import url(_customVariables.css);
@import 'https://code.highcharts.com/css/highcharts.css';
@import url(_customVariables.css);
@import url(_customVariables.css);
@font-face {
  font-family: 'Open Sans';
  src: url("./assets/fonts/OPENSANS-REGULAR.TTF");
  font-weight: normal; }

@font-face {
  font-family: 'Open Sans Condensed Bold';
  src: url("./assets/fonts/OPENSANSCONDENSED-BOLD.TTF");
  font-weight: bold; }

@font-face {
  font-family: 'Open Sans Condensed Light';
  src: url("./assets/fonts/OPENSANSCONDENSED-LIGHT.TTF");
  font-weight: normal; }

@font-face {
  font-family: 'Open Sans Condensed Light Italic';
  src: url("./assets/fonts/OPENSANSCONDENSED-LIGHTITALIC.TTF");
  font-style: italic;
  font-weight: normal; }

@font-face {
  font-family: 'Open Sans Extrabold';
  src: url("./assets/fonts/OPENSANS-EXTRABOLD.TTF");
  font-weight: bold; }

@font-face {
  font-family: 'Open Sans Extrabold Italic';
  src: url("./assets/fonts/OPENSANS-EXTRABOLDITALIC.TTF");
  font-style: italic;
  font-weight: bold; }

@font-face {
  font-family: 'Open Sans Italic';
  src: url("./assets/fonts/OPENSANS-ITALIC.TTF");
  font-style: italic; }

@font-face {
  font-family: 'Open Sans Light';
  src: url("./assets/fonts/OPENSANS-LIGHT.TTF");
  font-weight: normal; }

@font-face {
  font-family: 'Open Sans Light Italic';
  src: url("./assets/fonts/OPENSANS-LIGHTITALIC.TTF");
  font-style: italic;
  font-weight: normal; }

@font-face {
  font-family: 'Open Sans Semibold';
  src: url("./assets/fonts/OPENSANS-SEMIBOLD.TTF");
  font-weight: bold; }

@font-face {
  font-family: 'Open Sans Semibold Italic';
  src: url("./assets/fonts/OPENSANS-SEMIBOLDITALIC.TTF");
  font-style: italic;
  font-weight: bold; }

@font-face {
  font-family: 'Raleway';
  src: url("./assets/fonts/raleway/Raleway-Bold.ttf");
  font-weight: bold; }

@font-face {
  font-family: 'Raleway';
  src: url("./assets/fonts/raleway/Raleway-Regular.ttf"); }

@font-face {
  font-family: 'Raleway Extrabold';
  src: url("./assets/fonts/raleway/Raleway-ExtraBold.ttf");
  font-weight: bold; }

@font-face {
  font-family: 'Raleway Medium';
  src: url("./assets/fonts/raleway/Raleway-Medium.ttf"); }

@font-face {
  font-family: 'Raleway Medium';
  src: url("./assets/fonts/raleway/Raleway-MediumItalic.ttf");
  font-style: italic; }

@font-face {
  font-family: 'Raleway Medium';
  src: url("./assets/fonts/raleway/Raleway-Medium.ttf"); }

@font-face {
  font-family: 'Raleway Light';
  src: url("./assets/fonts/raleway/Raleway-Light.ttf"); }

.pointer {
  cursor: pointer; }

.not_allowed_cursor {
  cursor: not-allowed; }

.hidden {
  display: none !important; }

.flex-auto {
  flex: auto; }

.f-0 {
  flex: 0; }

.f-0-i {
  flex: 0 !important; }

.f-1 {
  flex: 1; }

.f-05 {
  flex: 0.5; }

.overflow-y {
  overflow-y: auto; }

.overflow-x {
  overflow-x: auto; }

.p-relative {
  position: relative; }

.text_overflow {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.contrast {
  color: white;
  background: var(--labels_important-active-labels-text-graphics); }

.font-small {
  font-size: 1rem; }

.font-title {
  font-size: 1.8rem; }

.b {
  border: 1px solid red; }

.c {
  border: 1px solid green; }

.d {
  border: 1px solid blue; }

.separator {
  width: 2rem; }

table th {
  text-align: inherit; }

.cursor_disabled {
  cursor: not-allowed; }

.direction_ltr .title-font {
  font-family: "Raleway"; }

.blue-link {
  color: var(--specials_buttons-links-and-highlights) !important; }

.blue-link:hover {
  opacity: 0.5; }

.arrow {
  border: solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: 2rem;
  width: 2rem; }
  .arrow.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .arrow.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  .arrow.up {
    transform: rotate(-135deg) translate(-1px, 0);
    -webkit-transform: rotate(-135deg) translate(-1px, 0); }
  .arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }
  .arrow.small {
    height: 1rem;
    width: 1rem; }

.caps {
  text-transform: uppercase; }

.caps-first {
  text-transform: capitalize; }

.d-inline {
  display: inline; }

.d-inline-block {
  display: inline-block; }

.content-center {
  justify-content: center !important; }

.bold {
  font-weight: bold; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-start {
  justify-content: flex-start; }

.justify-content-between {
  justify-content: space-between; }

.align-center {
  align-items: center !important; }

.align-start {
  align-items: flex-start !important; }

.align-end {
  align-items: flex-end !important; }

.underline-text {
  text-decoration: underline !important; }

.disabled-link {
  pointer-events: none; }

.object-fit-contain {
  object-fit: contain;
  height: 100%; }

.background-contain {
  -webkit-background-size: contain;
  background-size: contain; }

.custom-select-style .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.custom-select-style .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: var(--labels_important-active-labels-text-graphics);
  background-color: var(--base_wallet-sidebar-and-popup); }

.custom-select-style .ant-select-item,
.custom-select-style .ant-select-item-option {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.custom-select-style .ant-select-item-option-disabled {
  color: var(--labels_inactive-button); }

.custom-select-style.ant-select-dropdown {
  background-color: var(--base_background); }

.gap-1 {
  gap: 1rem; }

.filter-dropdown {
  width: 150px !important; }

.custom-select-input-style.no-border .ant-select-selector {
  border: none !important; }

.custom-select-input-style .ant-select-selector {
  border-radius: 0.35rem !important;
  border-color: var(--calculated_important-border) !important;
  background-color: transparent !important;
  color: var(--labels_important-active-labels-text-graphics) !important;
  height: 20px !important;
  font-weight: 400 !important;
  font-size: 0.9rem !important; }

.custom-select-input-style .ant-select-selection-item {
  line-height: 20px !important;
  text-transform: capitalize; }

.custom-select-input-style .ant-select-selection-search-input {
  height: 20px !important; }

.custom-select-input-style .anticon {
  color: var(--labels_important-active-labels-text-graphics) !important; }

.custom-select-input-style.appbar.ant-select {
  margin: 0 0.4rem !important;
  max-width: 100px !important;
  min-width: 80px; }

.custom-select-input-style.appbar .ant-select-selector {
  border-color: var(--calculated_base_top-bar-navigation_text-inactive) !important;
  color: var(--calculated_base_top-bar-navigation_text-inactive) !important; }

.custom-select-input-style.appbar .anticon {
  color: var(--calculated_base_top-bar-navigation_text-inactive) !important; }

.custom-select-input-style.elevated {
  z-index: 1; }

.custom-select-input-style .ant-select-arrow {
  z-index: -1; }

.border {
  border-color: var(--calculated_secondary-border) !important; }

.border-main {
  border-color: var(--calculated_important-border) !important; }

.border-pale {
  border-color: var(--calculated_super-pale_label-text-graphics) !important; }

.text-align-center {
  text-align: center; }

.align-items-baseline {
  align-items: baseline; }

.no-border {
  border: none !important; }

.flex-direction-column {
  flex-direction: column !important; }

.important-text {
  color: var(--labels_important-active-labels-text-graphics); }

.secondary-text {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.blue-icon svg path {
  fill: var(--specials_buttons-links-and-highlights); }

.kit-divider {
  margin: 1rem 0;
  border-bottom: solid 1px var(--labels_secondary-inactive-label-text-graphics); }

.flex-direction-row {
  flex-direction: row !important; }

.text-align-left {
  text-align: left !important; }

.normal {
  font-weight: normal; }

.text-align-right {
  text-align: right !important; }

.zoom-in {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.inline-flex {
  display: inline-flex !important; }

.no-wrap {
  white-space: nowrap !important; }

.margin-auto {
  margin: auto; }

.row-reverse {
  flex-direction: row-reverse; }

.danger-zone {
  border-top: var(--specials_notifications-alerts-warnings) 4px solid;
  background-color: var(--calculated_specials_notifications-alerts-pale); }

.app_container {
  min-height: 100vh;
  background-color: var(--base_background);
  color: var(--labels_important-active-labels-text-graphics); }
  .app_container.layout-desktop .app_container-content {
    height: auto;
    flex: 1; }
  .app_container.layout-mobile .app_container-content {
    min-height: calc( 100vh - 10rem);
    max-height: calc( 100vh - 10rem);
    max-width: 100vw;
    overflow-y: scroll; }
    .app_container.layout-mobile .app_container-content.chart-embed {
      min-height: 100vh;
      max-height: 100vh; }
    .app_container.layout-mobile .app_container-content.no_bottom_navigation {
      height: auto;
      flex: 1;
      min-height: calc(100vh);
      max-height: calc(100vh);
      max-width: 100vw;
      overflow-y: scroll; }
    .app_container.layout-mobile .app_container-content .app_container-main {
      transition: 0.3s; }
      .app_container.layout-mobile .app_container-content .app_container-main.no_bottom_navigation {
        height: 100%; }
  .app_container.layout-mobile .content-with-bar {
    min-height: calc( 100vh - 17rem);
    max-height: calc( 100vh - 17rem);
    max-width: 100vw;
    padding: 1rem;
    margin: 0;
    overflow-y: scroll; }
  .app_container .app_container-content .app_container-main {
    width: 100%;
    height: auto;
    position: relative; }
    .app_container .app_container-content .app_container-main .presentation_container {
      width: 75rem;
      height: inherit; }
    .app_container .app_container-content .app_container-main .ant-tabs {
      color: var(--labels_secondary-inactive-label-text-graphics); }
    .app_container .app_container-content .app_container-main .ant-tabs-ink-bar {
      background: var(--specials_buttons-links-and-highlights); }
    .app_container .app_container-content .app_container-main .ant-tabs-top > .ant-tabs-nav::before {
      border-color: var(--labels_secondary-inactive-label-text-graphics); }
    .app_container .app_container-content .app_container-main .ant-tabs-tab {
      color: var(--labels_secondary-inactive-label-text-graphics); }
      .app_container .app_container-content .app_container-main .ant-tabs-tab .ant-tabs-tab-btn {
        color: var(--labels_secondary-inactive-label-text-graphics); }
    .app_container .app_container-content .app_container-main .ant-tabs-tab-active {
      color: var(--labels_important-active-labels-text-graphics); }
      .app_container .app_container-content .app_container-main .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--labels_important-active-labels-text-graphics);
        font-weight: bold; }
  .app_container .app_container-sidebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 20rem;
    max-width: 20rem;
    background-color: var(--base_wallet-sidebar-and-popup);
    border-left: 1px solid var(--calculated_super-pale_label-text-graphics);
    padding: 0;
    transition: all 0.2s ease-out 0s; }
    .app_container .app_container-sidebar .sidebar-toggle-wrapper {
      display: flex;
      align-items: center;
      width: 0 !important;
      overflow: visible; }
    .app_container .app_container-sidebar .sidebar-toggle {
      transform: translateX(-50%); }
      .app_container .app_container-sidebar .sidebar-toggle.ant-btn-primary {
        background-color: var(--base_secondary-navigation-bar);
        border-color: var(--calculated_secondary-border); }
      .app_container .app_container-sidebar .sidebar-toggle .anticon {
        color: var(--labels_important-active-labels-text-graphics); }
      .app_container .app_container-sidebar .sidebar-toggle.ant-btn-icon-only {
        height: 30px;
        width: 18px; }
    .app_container .app_container-sidebar.close-sidebar {
      min-width: 0;
      max-width: 0; }
      .app_container .app_container-sidebar.close-sidebar .sidebar-toggle {
        transform: translateX(-100%); }
  .app_container .exchange-warning {
    background-color: #fff100; }

.layout-mobile .presentation_container {
  padding-top: 4rem !important; }

.layout-mobile .connectViaDesktop_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .layout-mobile .connectViaDesktop_wrapper .w-100 .subtitleClass {
    font-size: 15px;
    text-align: center;
    justify-content: center;
    align-items: baseline; }
  .layout-mobile .connectViaDesktop_wrapper .connect_desktop_img {
    margin: 8rem 0; }
  .layout-mobile .connectViaDesktop_wrapper .connect_desktop_footer {
    font-size: 16px;
    text-align: center; }

.presentation_container {
  width: 100rem;
  max-width: calc(100% - 2rem);
  margin: 1rem auto;
  height: 100%; }
  .presentation_container .inner_container {
    position: relative; }
    .presentation_container .inner_container.with_border_top {
      border-top: 1px solid var(--calculated_super-pale_label-text-graphics); }
    .presentation_container .inner_container.with_border_bottom {
      border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics); }
    .presentation_container .inner_container .information_block {
      position: relative;
      margin-bottom: 1rem; }
      .presentation_container .inner_container .information_block .information_block-text_wrapper .title {
        font-size: 1.1rem; }
      .presentation_container .inner_container .information_block .information_block-text_wrapper .text {
        font-size: 1rem;
        color: var(--labels_secondary-inactive-label-text-graphics); }
        .presentation_container .inner_container .information_block .information_block-text_wrapper .text > p {
          margin: 0.5rem 0; }

.app_container-bottom_bar {
  display: flex;
  flex: 0;
  width: 100%;
  max-width: 100vw;
  position: -webkit-fixed;
  position: fixed;
  bottom: 0;
  align-self: flex-end;
  z-index: 9999; }

@media (min-width: 1920px) {
  .font-small,
  .holla-button,
  .field-wrapper,
  .action_notification-text,
  .wallet-assets_block-table,
  .accordion_section_content,
  .verification_container .header-content,
  .verification_container .content-text,
  .table_body-wrapper,
  .wallet_section-title-amount,
  .wallet_section-title,
  .trade_tabs-container .trade_tabs-content,
  .trade_block-wrapper .trade_block-content,
  .information_block-text_wrapper .text,
  .qr-text,
  .header_title-children,
  .placeholder,
  .notification-content-wrapper,
  .otp_form-section-wrapper .otp_form-section-text,
  .popup_info-wrapper .popup_info-text,
  .otp_secret {
    font-size: 1rem !important; }
  .action_notification-text {
    line-height: 1rem !important; }
  .presentation_container,
  .trade_tabs-container {
    width: 100rem !important; }
  .app_bar-pair-tab {
    min-width: 25rem !important;
    max-width: 25rem !important; }
  .app_container .app_container-sidebar {
    min-width: 25rem;
    max-width: 25rem; } }

@media screen and (max-width: 1096px) {
  .font-small,
  .holla-button,
  .field-wrapper,
  .action_notification-text,
  .wallet-assets_block-table,
  .accordion_section_content,
  .verification_container .content-text,
  .table_body-wrapper,
  .wallet_section-title-amount,
  .wallet_section-title,
  .trade_tabs-container .trade_tabs-content,
  .trade_block-wrapper .trade_block-content,
  .information_block-text_wrapper .text,
  .qr-text,
  .header_title-children,
  .placeholder,
  .notification-content-wrapper,
  .otp_form-section-wrapper .otp_form-section-text,
  .popup_info-wrapper .popup_info-text,
  .otp_secret {
    font-size: 0.9rem !important; }
  .action_notification-text {
    line-height: 0.9rem !important; }
  .verification_container .header-content {
    font-size: 1.2rem !important; }
  .app_bar-pair-tab {
    min-width: 19rem !important;
    max-width: 19rem !important; }
  .presentation_container,
  .trade_tabs-container {
    width: 65rem !important; }
  .app_container .app_container-sidebar {
    min-width: 18rem;
    max-width: 18rem; }
  .app_container .sidebar_hub-content {
    margin: 1.3rem !important; } }

.react-grid-item > .react-resizable-handle::after {
  border-color: var(--labels_secondary-inactive-label-text-graphics) !important;
  right: 5px;
  bottom: 5px; }

.background-color-layer {
  box-shadow: inset 0 0 0 1000px var(--calculated_background-overlay); }

.light-auth-body {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }
  .light-auth-body:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 130%; }

.exchange-warning {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; }

.exchange-expired {
  background-color: #ed1c24 !important;
  color: #ffffff !important; }

.exchange-trial {
  background-color: #fff100;
  color: #000000; }

.layout-mobile.auth-wrapper .link_wrapper {
  font-size: 1.2rem; }

.layout-mobile.auth-wrapper .auth-container {
  max-width: unset !important;
  height: 100%; }
  .layout-mobile.auth-wrapper .auth-container > div {
    height: 100%; }
    .layout-mobile.auth-wrapper .auth-container > div .auth_wrapper {
      padding: 0 1rem !important;
      height: 100%; }
  .layout-mobile.auth-wrapper .auth-container .icon_title-wrapper .icon_title-text.title {
    font-size: 5rem; }
  .layout-mobile.auth-wrapper .auth-container .font-small {
    font-size: 1.2rem; }
  .layout-mobile.auth-wrapper .auth-container .auth_form-wrapper .field-wrapper {
    font-size: 1.2rem; }
    .layout-mobile.auth-wrapper .auth-container .auth_form-wrapper .field-wrapper .field-label,
    .layout-mobile.auth-wrapper .auth-container .auth_form-wrapper .field-wrapper .input_field-input {
      font-size: 1.2rem; }

.auth-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: scroll; }
  .auth-wrapper:before {
    position: fixed;
    content: '';
    width: 100%;
    height: 100%; }
  .auth-wrapper.white-theme {
    color: var(--labels_important-active-labels-text-graphics); }
  .auth-wrapper .auth-container {
    max-width: 575px;
    max-height: 100vh;
    z-index: 1; }
    .auth-wrapper .auth-container .auth_wrapper {
      background-color: var(--base_wallet-sidebar-and-popup);
      padding: 4rem 4rem 6rem; }
      .auth-wrapper .auth-container .auth_wrapper .icon_title-svg .hollaEX_logo_gray {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
    .auth-wrapper .auth-container .auth_form-wrapper {
      margin-top: 2rem;
      position: relative; }
  .auth-wrapper .link_wrapper {
    margin: 1rem;
    margin-top: 0; }
    .auth-wrapper .link_wrapper.multi_links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .auth-wrapper .link_wrapper.multi_links > div:not(:last-child) {
        padding-bottom: 0.5rem; }
  .auth-wrapper .blue-link {
    padding-left: 0.25rem; }
  .auth-wrapper .error_text {
    padding-bottom: 0.5rem; }
  .auth-wrapper .button-margin {
    margin-top: 1.5rem; }
  .auth-wrapper .icon_title-wrapper {
    margin-top: 0; }
    .auth-wrapper .icon_title-wrapper.underline:after {
      height: 0.2rem; }
    .auth-wrapper .icon_title-wrapper .icon_title-text {
      margin: 1.5rem auto; }
      .auth-wrapper .icon_title-wrapper .icon_title-text.title {
        text-transform: capitalize;
        font-size: 3rem; }
    .auth-wrapper .icon_title-wrapper .icon_title-svg {
      width: 100%;
      display: flex;
      justify-content: center; }
      .auth-wrapper .icon_title-wrapper .icon_title-svg svg {
        width: 6rem;
        height: 6rem; }
    .auth-wrapper .icon_title-wrapper.holla-logo .icon_title-svg {
      height: inherit; }
      .auth-wrapper .icon_title-wrapper.holla-logo .icon_title-svg svg {
        width: inherit;
        height: 4rem; }
  .auth-wrapper .holla-button {
    margin-top: 1rem; }
  .auth-wrapper .link_wrapper {
    margin: 1.5rem 0 0; }

.footer-wrapper {
  position: relative; }

@media screen and (max-width: 1096px) {
  .auth-wrapper .auth-container {
    max-width: 475px !important; }
    .auth-wrapper .auth-container .auth_wrapper {
      padding: 3rem 4rem 3rem; }
    .auth-wrapper .auth-container .auth_form-wrapper {
      margin-top: 1rem; }
      .auth-wrapper .auth-container .auth_form-wrapper .field-wrapper {
        margin: 0.5rem 0; }
    .auth-wrapper .auth-container .holla-button {
      margin-top: 0.5rem; }
  .auth-wrapper .link_wrapper {
    margin-top: 1rem; }
  .auth-wrapper .icon_title-wrapper .icon_title-svg svg {
    width: 5rem;
    height: 5rem; }
  .auth-wrapper .icon_title-wrapper.holla-logo .icon_title-svg svg {
    height: 3rem; }
  .auth-wrapper .icon_title-wrapper .icon_title-text {
    margin: 0.8rem auto; }
    .auth-wrapper .icon_title-wrapper .icon_title-text.title {
      font-size: 2rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.72);
    -webkit-transform: scale(0.72);
    transform-origin: 0 0;
    -webkit-transform-origin: 0% 0%; } }

@media screen and (max-width: 1096px) and (min-width: 768px) {
  .auth-wrapper .auth-container .auth_form-wrapper .captcha-wrapper {
    height: 5.5rem; } }

@media screen and (max-width: 334px) and (min-width: 280px) {
  .auth-wrapper .auth-container .auth_form-wrapper .captcha-wrapper {
    height: 7rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    transform-origin: 0 0;
    -webkit-transform-origin: 0% 0%; } }

@media screen and (max-width: 460px) and (min-width: 335px) {
  .auth-wrapper .auth-container .auth_form-wrapper .captcha-wrapper {
    height: 7rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.62);
    -webkit-transform: scale(0.62);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

.information_section {
  font-size: 1rem;
  color: var(--labels_secondary-inactive-label-text-graphics);
  padding: 0 0 0.5rem; }
  .information_section .information_svg {
    fill: var(--labels_secondary-inactive-label-text-graphics); }
  .information_section .information_change {
    color: var(--specials_buttons-links-and-highlights);
    width: fit-content; }
  .information_section > div:not(:first-child) {
    margin-top: 0.75rem; }

.user_verification-form_section {
  padding-bottom: 1rem; }

.direction_ltr .information_section .information_text-text {
  margin-left: 0.5rem; }

.direction_rtl .information_section .information_text-text {
  margin-right: 0.5rem; }

.user-limits .user-limits-table {
  width: 100%;
  font-size: 1rem; }
  .user-limits .user-limits-table thead tr,
  .user-limits .user-limits-table tfoot tr {
    height: 3.5rem;
    vertical-align: bottom; }
  .user-limits .user-limits-table tfoot {
    font-size: 1.1rem; }
    .user-limits .user-limits-table tfoot td {
      white-space: nowrap; }
  .user-limits .user-limits-table .table-row {
    height: 2.5rem;
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .user-limits .user-limits-table .table-row .td-levelball .td-levelball-content {
      border: 1px solid var(--calculated_important-border);
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem; }
    .user-limits .user-limits-table .table-row.user-level {
      color: var(--labels_important-active-labels-text-graphics); }
      .user-limits .user-limits-table .table-row.user-level td:not(:first-child) {
        background-color: var(--labels_inactive-button); }
      .user-limits .user-limits-table .table-row.user-level .td-levelball .td-levelball-content {
        color: white;
        background-color: var(--labels_important-active-labels-text-graphics); }
  .user-limits .user-limits-table .table-bottom-border td,
  .user-limits .user-limits-table th {
    position: relative; }
    .user-limits .user-limits-table .table-bottom-border td:not(:first-child),
    .user-limits .user-limits-table th:not(:first-child) {
      border-bottom: 1px solid var(--calculated_important-border);
      padding: 0 0.25rem;
      min-width: 25%; }
  .user-limits .user-limits-table .td-fit {
    width: 1%;
    white-space: nowrap; }
  .user-limits .user-limits-table .td-name {
    min-width: 75px !important; }
  .user-limits .user-limits-table .table-icon > div {
    vertical-align: middle;
    margin: auto; }
  .user-limits .user-limits-table .td-amount {
    direction: ltr; }

.direction_ltr .user-limits .user-limits-table th {
  font-family: "Raleway"; }

.direction_ltr .user-limits .user-limits-table th,
.direction_ltr .user-limits .user-limits-table td {
  text-align: left; }

.direction_ltr .user-limits .user-limits-table .align-opposite {
  text-align: right; }

.direction_ltr .user-limits .user-limits-table .table-icon {
  padding-right: 0.5rem; }

.direction_rtl .user-limits .user-limits-table th,
.direction_rtl .user-limits .user-limits-table td {
  text-align: right; }

.direction_rtl .user-limits .user-limits-table .align-opposite {
  text-align: left; }

.direction_rtl .user-limits .user-limits-table .table-icon {
  padding-left: 0.5rem; }

.user_security-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 30px;
  background-color: var(--base_wallet-sidebar-and-popup); }
  .user_security-wrapper > *:not(:last-child) {
    margin-bottom: 2rem; }

.OTP_Success {
  width: 30px;
  height: 30px; }
  .OTP_Success svg {
    width: 30px;
    height: 30px; }

.otp_form-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  width: 30rem; }
  .otp_form-wrapper .otp_form-section-wrapper {
    border-top: 1px solid var(--calculated_important-border);
    padding: 0.75rem 0; }
    .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-content {
      margin: 1rem 0; }
      .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-content .qr-code-wrapper {
        background: white;
        margin: 0.5rem;
        width: 10rem;
        height: 10rem; }
        .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-content .qr-code-wrapper canvas {
          width: 9rem !important;
          height: 9rem !important; }
    .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-title {
      font-size: 1.1rem;
      font-weight: bold; }
    .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-text {
      font-size: 1rem; }
    .otp_form-wrapper .otp_form-section-wrapper .otp_secret {
      font-size: 1rem;
      background-color: var(--calculated_specials_highlight-box);
      padding: 0.25rem 0.5rem; }
  .otp_form-wrapper .otp_form-fields-wrapper {
    margin-bottom: 1rem; }
  .otp_form-wrapper .icon_title-wrapper .icon_title-text.title {
    font-size: 1.8rem; }
  .otp_form-wrapper .icon_title-wrapper .icon_title-svg {
    width: 6.5rem;
    height: 6.5rem; }
    .otp_form-wrapper .icon_title-wrapper .icon_title-svg svg {
      width: 6.5rem;
      height: 6.5rem; }

.tokens-name,
.tokens-tokenkey {
  word-break: break-word;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important; }

.tokens-tokenkey {
  max-width: 5rem; }

.tokens-date {
  min-width: 5rem;
  max-width: 5rem; }

.session-login-date {
  min-width: 7rem;
  max-width: 8rem; }

.expander-handle {
  min-width: 1rem;
  max-width: 1rem; }

.tokens-icon,
.tokens-revoke {
  min-width: 3rem;
  max-width: 3rem; }

.tokens-icon > div {
  display: flex;
  justify-content: center;
  align-items: center; }

.tokens-icon svg {
  width: 2.5rem;
  height: 2.5rem; }

.tokens-icon.small-icon svg {
  padding: 0.325rem 0; }

.tokens-icon-svg svg {
  fill: var(--labels_important-active-labels-text-graphics); }

.checkbutton-input-wrapper--image svg {
  stroke: var(--labels_important-active-labels-text-graphics);
  stroke-width: 1rem; }

.popup_info-wrapper .popup_info-title {
  font-family: "Raleway";
  font-size: 1.3rem;
  border-bottom: 1px solid var(--calculated_secondary-border); }

.popup_info-wrapper .popup_info-text {
  font-size: 1rem; }

.popup_info-wrapper .secret_key .action_notification-wrapper .action_notification-text {
  margin-left: 1rem !important; }

.token-no-otp {
  color: var(--labels_inactive-button);
  cursor: not-allowed !important; }

.dumb-field-wrapper.token-value-input .copy-wrapper {
  top: calc(50% - 0.5rem); }

.dumb-field-wrapper.token-value-input .custom {
  word-break: break-all; }

.direction_ltr .dumb-field-wrapper.token-value-input .custom {
  padding-right: 4rem; }

.direction_rtl .dumb-field-wrapper.token-value-input .custom {
  padding-left: 4rem; }

.logout-menu-wrapper {
  color: var(--labels_secondary-inactive-label-text-graphics);
  font-size: 1.75rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--calculated_important-border); }
  .logout-menu-wrapper .logout-icon {
    width: 1.5rem; }

.change-password-form,
.dev-section-wrapper {
  border-radius: 4px;
  margin: 30px 0px 40px;
  background-color: var(--base_wallet-sidebar-and-popup); }

.dev-section-wrapper {
  padding: 30px; }

.change-password-form-wrapper .change-password-form {
  padding: 20px 60px 20px; }
  .change-password-form-wrapper .change-password-form .field-content {
    border-bottom: 1px solid; }

.change-password-form-wrapper .password-btn {
  width: 300px; }

.user-security-container .icon_title-wrapper {
  display: flex;
  justify-content: flex-start !important;
  flex-direction: row;
  align-items: center; }

.user-security-container .checkbutton-input-wrapper {
  margin-top: 0px !important; }

.layout-mobile .user_security-wrapper .warning_text {
  font-size: 13px !important; }

.layout-mobile .user_security-wrapper .checkbutton-wrapper .checkbutton-input-wrapper {
  width: 240px !important; }
  .layout-mobile .user_security-wrapper .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--label {
    font-size: 13px;
    color: var(--specials_buttons-links-and-highlights); }

.layout-mobile .dev-section-wrapper {
  padding: 5px; }
  .layout-mobile .dev-section-wrapper .table-wrapper th,
  .layout-mobile .dev-section-wrapper .table-wrapper td {
    padding: 0; }
  .layout-mobile .dev-section-wrapper .field-error-content .field-error-text {
    margin-top: 10px; }

.layout-mobile .change-password-form-wrapper .change-password-form .field-label-wrapper {
  flex-direction: column; }
  .layout-mobile .change-password-form-wrapper .change-password-form .field-label-wrapper .field-label {
    font-size: 10px; }
  .layout-mobile .change-password-form-wrapper .change-password-form .field-label-wrapper .input-box-field {
    padding: 4px;
    height: 30px;
    margin-top: 5px; }

.layout-mobile .change-password-form-wrapper .change-password-form .input_field-input::placeholder {
  font-size: 10px; }

.layout-mobile .otp_form-wrapper {
  min-width: auto;
  width: auto; }
  .layout-mobile .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-title {
    font-size: 15px; }
  .layout-mobile .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-text {
    font-size: 13px !important; }
  .layout-mobile .otp_form-wrapper .otp_form-section-content .qr-code-wrapper {
    width: 15rem;
    height: 15rem; }
    .layout-mobile .otp_form-wrapper .otp_form-section-content .qr-code-wrapper canvas {
      width: 14rem !important;
      height: 14rem !important; }

.layout-mobile .accordion_section_content .warning_text {
  font-size: 13px !important; }

.layout-mobile .presentation_container .edit_token {
  flex-direction: column; }
  .layout-mobile .presentation_container .edit_token .token-keys-wrapper .field-content,
  .layout-mobile .presentation_container .edit_token .token-keys-wrapper .field-children {
    font-size: 1rem !important; }

.layout-mobile .presentation_container .token-keys-wrapper {
  max-width: 35rem; }

@media (max-width: 1060px) {
  .change-password-form-wrapper .change-password-form {
    padding: 10px; }
    .change-password-form-wrapper .change-password-form .field-label-wrapper .field-label {
      font-size: 12px; }
    .change-password-form-wrapper .change-password-form .input_field-input::placeholder {
      font-size: 12px; }
  .user-security-wrapper .tab_controller-tabs .tab_item {
    transition: 0.3s;
    font-size: 12px !important; } }

.edit_token.overlay {
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0.5; }

.edit_token .qr-code-bg {
  padding: 0 1.5rem 0 1rem; }

.edit_token .qr-code-bg > *:first-child {
  width: 8rem !important;
  height: 8rem !important; }

.edit_token .ant-radio-wrapper {
  color: var(--labels_important-active-labels-text-graphics); }

.edit_token .holla-button {
  width: 150px; }

.edit_token .ant-input {
  color: var(--labels_important-active-labels-text-graphics); }

.edit_token .ant-btn-link {
  color: var(--specials_buttons-links-and-highlights); }

.edit_token .ant-btn-link[disabled] {
  color: var(--calculated_notifications-blue-disabled); }

.small-expandable .ant-checkbox-wrapper,
.small-expandable .ant-radio-wrapper,
.small-expandable .ant-input,
.small-expandable .ant-btn,
.small-expandable .ant-tag {
  font-size: 0.84rem !important; }

.small-expandable .field-label {
  font-size: 0.9rem; }

.small-expandable .field-wrapper {
  margin: 0; }

.small-expandable .field-children {
  padding: 0; }

.small-expandable .ant-checkbox-wrapper {
  color: var(--labels_important-active-labels-text-graphics); }

.small-expandable .ant-tag {
  color: var(--labels_important-active-labels-text-graphics);
  background: var(--calculated_tag-background);
  border: none;
  border-radius: 70px;
  margin-bottom: 7px; }

.small-expandable .ant-tag-close-icon {
  color: var(--labels_important-active-labels-text-graphics);
  margin-left: 0.8rem; }

.token-keys-wrapper {
  max-width: 40rem; }

.flex-col {
  flex-basis: 0;
  flex-grow: 1;
  padding-right: 1rem; }

.session-dialog-content {
  width: 38rem; }
  .session-dialog-content .icon_title-wrapper {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0.5rem; }
    .session-dialog-content .icon_title-wrapper .icon_title-text.title {
      font-size: 2rem;
      font-weight: bold;
      margin: 0.5rem; }
    .session-dialog-content .icon_title-wrapper .icon_title-svg {
      height: 3rem;
      width: 3rem; }
      .session-dialog-content .icon_title-wrapper .icon_title-svg svg {
        height: 3rem;
        width: 3rem; }

.signup_success-wrapper {
  text-align: center; }
  .signup_success-wrapper .signup_success-content {
    margin: 2rem 0 4rem; }

.terms_label-wrapper {
  padding: 0 0.25rem; }

.signup-warning {
  margin-top: 1.5rem; }

.warning-icon {
  width: 1.5rem;
  margin-right: 1rem; }

.signup-warning-text {
  color: var(--specials_notifications-alerts-warnings); }

.dialog-link {
  color: var(--specials_buttons-links-and-highlights);
  padding: 0 0.25rem; }

.signup-modal-wrapper {
  max-width: 767px;
  max-height: 100%; }

.layout-mobile .signup_success-wrapper {
  height: calc(100% - 4rem) !important; }

.home-page_content .tabs-pair-details {
  font-size: 1.2rem;
  margin: auto;
  white-space: nowrap; }

.home-page_content .trade_tab-pair-title {
  color: var(--labels_important-active-labels-text-graphics);
  font-size: 1.1rem;
  font-weight: bold; }

.home-page_content .trade_tab-pair-sub-title {
  font-size: 1rem; }

.home-page_content .trade_tab-pair-price {
  color: var(--labels_important-active-labels-text-graphics);
  font-size: 1.8rem;
  font-weight: 700;
  font-family: Raleway; }

.home-page_content .trade_tab-pair-volume {
  font-size: 1rem; }

.home-page_content .market-card__sparkline-wrapper {
  height: 5rem !important;
  width: 10rem !important; }
  .home-page_content .market-card__sparkline-wrapper .highcharts-area {
    fill: none !important; }
    .home-page_content .market-card__sparkline-wrapper .highcharts-area.highcharts-negative {
      fill: none !important; }

.home-page__market-wrapper {
  background-color: var(--calculated_landing_markets_table); }
  .home-page__market-wrapper .table-row {
    color: var(--labels_important-active-labels-text-graphics) !important; }
  .home-page__market-wrapper thead {
    color: var(--labels_important-active-labels-text-graphics) !important;
    background-color: var(--base_wallet-sidebar-and-popup); }
    .home-page__market-wrapper thead tr {
      height: 5rem !important; }
  .home-page__market-wrapper th:nth-child(1) {
    padding-left: 2rem !important; }
  .home-page__market-wrapper td:nth-child(1) {
    padding-left: 1rem !important; }
  .home-page__market-wrapper th:last-child {
    padding-right: 2rem !important; }
  .home-page__market-wrapper td:last-child {
    padding-right: 1rem !important; }
  .home-page__market-wrapper .market-list__footer {
    height: 4.5rem !important; }
  .home-page__market-wrapper .market-list__block-table {
    margin-bottom: 0 !important; }
  .home-page__market-wrapper .price-diff-down {
    color: var(--trading_selling-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .home-page__market-wrapper .glance-price-diff-down.entering, .home-page__market-wrapper .glance-price-diff-down.exiting {
    color: var(--calculated_trading_selling-related-text);
    background-color: var(--trading_selling-related-elements); }
    .home-page__market-wrapper .glance-price-diff-down.entering.glance-trade-tab-price_diff_down:before, .home-page__market-wrapper .glance-price-diff-down.exiting.glance-trade-tab-price_diff_down:before {
      border-color: var(--calculated_trading_selling-related-text); }
  .home-page__market-wrapper .price-diff-up {
    color: var(--trading_buying-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .home-page__market-wrapper .glance-price-diff-up.entering, .home-page__market-wrapper .glance-price-diff-up.exiting {
    color: var(--calculated_trading_buying-related-text);
    background-color: var(--trading_buying-related-elements); }
    .home-page__market-wrapper .glance-price-diff-up.entering.glance-trade-tab-price_diff_up:before, .home-page__market-wrapper .glance-price-diff-up.exiting.glance-trade-tab-price_diff_up:before {
      border-color: var(--calculated_trading_buying-related-text); }
  .home-page__market-wrapper .trade-tab-price_diff_up {
    margin: auto 0; }
  .home-page__market-wrapper .trade-tab-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .home-page__market-wrapper .trade-tab-price_diff_down {
    margin: auto 0; }
  .home-page__market-wrapper .trade-tab-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }

.layout-mobile .home-page__market-wrapper th:nth-child(1) {
  padding-left: 1rem !important; }

.layout-mobile .home-page__market-wrapper td:nth-child(1) {
  padding-left: 0.5rem !important; }

.layout-mobile .home-page__market-wrapper th:last-child {
  padding-right: 0.1rem !important; }

.layout-mobile .home-page__market-wrapper td:last-child {
  padding-right: 0.5rem !important; }

.layout-mobile .home_container .quick_trade-wrapper {
  flex-direction: column; }

.layout-mobile .html_card_section {
  flex-direction: column; }
  .layout-mobile .html_card_section .card-section-wrapper {
    padding: 2rem; }
    .layout-mobile .html_card_section .card-section-wrapper .card-section .header_txt {
      font-size: 14px; }

.layout-mobile .home-page_section-wrapper {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.layout-mobile .home_carousel_section .slideshow-wrapper {
  height: 12rem; }

.home_container {
  background-repeat: no-repeat;
  background-size: cover; }
  .home_container .main-section_button_invert {
    color: var(--specials_buttons-links-and-highlights) !important;
    background-color: var(--labels_important-active-labels-text-graphics) !important; }
  .home_container .main-section_button {
    color: var(--labels_important-active-labels-text-graphics) !important;
    background-color: var(--specials_buttons-links-and-highlights) !important; }
  .home_container .quick_trade-wrapper {
    max-width: 40rem;
    background-color: var(--calculated_quick_trade-bg); }
  .home_container .exchange-expired {
    background-color: #ed1c24 !important;
    color: #ffffff !important; }
  .home_container .exchange-warning {
    background-color: #fff100;
    color: #000000; }
  .home_container .features-container {
    padding: 2rem; }
  .home_container .home-page_overlay {
    width: 100% !important;
    height: 100% !important;
    background: linear-gradient(to bottom, var(--calculated_landing_background), var(--calculated_landing_background)); }
  .home_container .section_1-content {
    position: relative; }
    .home_container .section_1-content .scroll-button {
      position: absolute;
      bottom: 1rem;
      width: 2rem;
      height: 2rem; }
      .home_container .section_1-content .scroll-button svg {
        width: 2rem;
        height: 2rem; }
        .home_container .section_1-content .scroll-button svg .st0 {
          stroke: #4d4d4d;
          stroke-width: 2; }
  .home_container .features-container {
    background: white; }
    .home_container .features-container .features-card_container {
      max-width: 75rem; }
  .home_container .home-card-container {
    border-top: 1px solid var(--calculated_important-border);
    width: 17.5rem;
    margin: 3rem;
    margin-top: 0; }
    .home_container .home-card-container .home-card-icon-wrapper > div {
      display: flex;
      justify-content: center;
      align-items: center; }
      .home_container .home-card-container .home-card-icon-wrapper > div .home-card-icon {
        fill: var(--labels_important-active-labels-text-graphics); }
    .home_container .home-card-container .home-card-icon {
      width: 4rem;
      height: 4rem; }
    .home_container .home-card-container .home-card-text-container {
      font-size: 1rem;
      color: var(--labels_secondary-inactive-label-text-graphics); }
      .home_container .home-card-container .home-card-text-container .home-card-title {
        margin: 0.5rem 0 0.25rem;
        font-weight: bold;
        text-transform: uppercase; }
  .home_container .home-title,
  .home_container .features-title {
    font-size: 3.5rem;
    line-height: normal;
    font-weight: bold;
    text-align: center; }
  .home_container .text-section,
  .home_container .buttons-section {
    margin: 0.5rem; }
  .home_container .text-section {
    font-size: 1.3rem;
    font-weight: bold; }
  .home_container .buttons-section--button {
    width: 15rem;
    height: 36px;
    font-size: 1rem;
    border: 1px solid var(--calculated_important-border);
    margin: 0 1rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold; }
    .home_container .buttons-section--button.contrast {
      background-color: var(--labels_important-active-labels-text-graphics); }

.home-page_content .alice-carousel__stage-item {
  border-right: 1px var(--calculated_secondary-border) solid; }

.live-markets_header {
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem;
  color: var(--labels_important-active-labels-text-graphics);
  font-weight: bolder; }

.home-page_section-wrapper {
  margin-left: auto;
  margin-right: auto;
  padding-top: 148px;
  padding-bottom: 335px !important;
  max-width: 100rem; }
  .home-page_section-wrapper:not(.main-section-wrapper) {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .home-page_section-wrapper.main-section-wrapper {
    padding-bottom: 5rem; }
  .home-page_section-wrapper .quick_trade-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.direction_ltr .home-card-text-container {
  margin-left: 0.75rem; }

.direction_rtl .home-card-text-container {
  margin-right: 0.75rem; }

.html_card_section {
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-bottom: 1px var(--calculated_secondary-border) solid;
  opacity: 0.8; }
  .html_card_section .card-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 3rem; }
    .html_card_section .card-section-wrapper .card-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20rem;
      margin-right: 1rem; }
      .html_card_section .card-section-wrapper .card-section .card_section_logo {
        width: 50px;
        height: 50px; }
        .html_card_section .card-section-wrapper .card-section .card_section_logo svg {
          width: 50px;
          height: 50px; }
          .html_card_section .card-section-wrapper .card-section .card_section_logo svg path {
            fill: var(--labels_important-active-labels-text-graphics); }
      .html_card_section .card-section-wrapper .card-section .fill-none {
        width: 50px;
        height: 50px; }
        .html_card_section .card-section-wrapper .card-section .fill-none svg {
          width: 50px;
          height: 50px; }
          .html_card_section .card-section-wrapper .card-section .fill-none svg path:not(:first-child) {
            fill: var(--labels_important-active-labels-text-graphics); }
      .html_card_section .card-section-wrapper .card-section .header_txt {
        font-weight: bold;
        font-size: 18px; }
      .html_card_section .card-section-wrapper .card-section .card_section_main {
        text-align: center; }
        .html_card_section .card-section-wrapper .card-section .card_section_main .edit-wrapper__icons-container {
          transform: translate(-20px, 0px) !important; }

.home_carousel_section {
  border-top: 1px var(--calculated_secondary-border) solid;
  border-bottom: 1px var(--calculated_secondary-border) solid; }
  .home_carousel_section .slideshow-wrapper {
    height: 11rem;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0); }
  .home_carousel_section .slideshow-wrapper > div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: translate3d(0, 0, 0); }
  .home_carousel_section .slideshow-wrapper .parent-slider {
    animation: moveSlideshow 50s linear infinite; }
  .home_carousel_section .parent-slider:hover {
    animation-play-state: paused; }
  .home_carousel_section .section {
    opacity: 0.8;
    border-right: 1px solid #60605d;
    padding: 1.5rem 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30rem; }
  .home_carousel_section .section:hover {
    opacity: 1;
    cursor: pointer;
    background-color: var(--base_wallet-sidebar-and-popup); }
  .home_carousel_section .height-40 {
    height: 40px;
    width: 16rem; }
  .home_carousel_section .section:hover .trade_tab-pair-title,
  .home_carousel_section .section:hover .trade_tab-pair-sub-title,
  .home_carousel_section .section:hover .trade_tab-pair-volume {
    opacity: 1; }
  .home_carousel_section .section .trade_tab-pair-title,
  .home_carousel_section .section .trade_tab-pair-sub-title,
  .home_carousel_section .section .trade_tab-pair-volume {
    opacity: 0.5; }

@keyframes moveSlideshow {
  100% {
    transform: translateX(-66.6666%); } }

@media (max-width: 767px) {
  .home_container .home-card-container {
    margin: 0 2rem 3rem; }
  .home_container .buttons-section .holla-button {
    font-size: 1.2rem !important; }
  .home_container .text-section {
    font-size: 1.5rem !important; }
  .home_container .live-markets_header {
    font-size: 1.5rem !important; }
  .home_container .features-container .features-card_container {
    max-width: 45rem; }
  .home_container .html_card_section {
    flex-direction: column; }
    .home_container .html_card_section .card-section-wrapper {
      padding: 2rem; }
      .home_container .html_card_section .card-section-wrapper .card-section .header_txt {
        font-size: 14px; }
  .home_container .home_carousel_section .section {
    padding: 0 30px !important; } }

@media (max-width: 1199px) {
  .home_container .html_card_section {
    flex-direction: column; }
    .home_container .html_card_section .card-section-wrapper {
      padding: 2rem; }
      .home_container .html_card_section .card-section-wrapper .card-section .header_txt {
        font-size: 14px; } }

.wallet-hover:hover {
  opacity: 0.5; }

.layout-mobile .button-container {
  min-height: 4.5rem; }

.layout-mobile .currency-list-container .wallet-currency {
  max-width: calc(100vw - 6rem); }

.layout-mobile .currency-list-container .balance-big {
  font-size: 4rem; }

.layout-mobile .currency-list-container .balance-small {
  font-size: 1.5rem; }

.layout-mobile .currency-list-container .button-container .holla-button {
  font-size: 13px !important; }

.layout-mobile .currency-list-container .donut-container {
  height: 30rem !important;
  width: 100%; }

.layout-mobile .currency-list-container .rounded-loading {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border: 1px solid #808080;
  background-color: var(--calculated_secondary-border) !important;
  animation: outer-round-anim 3s ease infinite; }

.layout-mobile .currency-list-container .inner-round {
  background-color: var(--base_wallet-sidebar-and-popup);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
  border: 1px solid #808080; }

.layout-mobile .currency-list-container .loading-wrapper {
  height: 16rem;
  flex-direction: column; }
  .layout-mobile .currency-list-container .loading-wrapper .d-none {
    display: none; }
  .layout-mobile .currency-list-container .loading-wrapper .loading-txt {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 5rem; }

.layout-mobile .currency-list-container .dot-flashing {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #34363a;
  color: #34363a;
  animation: dotFlashing 3s infinite; }

.layout-mobile .wallet-container .action_notification-wrapper .action_notification-text {
  font-size: 1.2rem !important;
  line-height: 1.2rem !important; }

.layout-mobile .wallet-container .accordion_wrapper .accordion_section {
  border-width: 2px; }
  .layout-mobile .wallet-container .accordion_wrapper .accordion_section .accordion_section_title .accordion_section_content_text {
    font-size: 1.75rem;
    text-transform: uppercase;
    font-weight: bold; }
  .layout-mobile .wallet-container .accordion_wrapper .accordion_section .action_notification-wrapper {
    margin: 0; }

.layout-mobile .wallet-container .wallet-assets_block {
  padding: 6px; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table thead tr th:nth-child(3) {
    text-align: center; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-wallet,
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-amount,
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-name {
    min-width: unset !important; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-wallet .deposit-withdrawal-wrapper {
    margin: 0 auto;
    width: 18rem; }
    .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-wallet .deposit-withdrawal-wrapper .action_notification-wrapper .action_notification-text {
      font-size: 1.2rem;
      line-height: 1.2rem; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .currency_ball-wrapper.s {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.8rem; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .table-bottom-border {
    font-size: 13px !important; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table tfoot {
    font-size: 11px !important; }
  .layout-mobile .wallet-container .wallet-assets_block .zero-balance-wrapper {
    flex-direction: column; }

.layout-mobile .wallet-container .action-button-wrapper {
  padding: 5px 2px; }

.layout-mobile .currency-wallet-wrapper .title-wrapper {
  margin-top: 1rem;
  font-size: 2rem; }

.layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .wallet-details-wrapper {
  text-align: center; }

.layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .btn-wrapper {
  display: flex;
  flex-direction: column; }
  .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .btn-wrapper .withdraw-wrapper {
    margin-top: 10%;
    width: 100% !important; }
  .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .btn-wrapper .deposit-wrapper {
    width: 100% !important; }

.layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .rounded-loading {
  margin: 5rem 0rem !important; }

@media screen and (max-width: 550px) {
  .layout-mobile .button-container .holla-button {
    font-size: 10px !important; }
  .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper {
    flex-direction: column;
    height: 100% !important; }
    .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .wallet-details-wrapper {
      width: 100% !important; }
    .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper {
      width: 100%;
      height: 100% !important;
      font-size: 14px; }
      .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .rounded-loading {
        margin: 1rem 0rem 0rem 12rem; }
      .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .inner-round {
        margin-left: 1.5rem; } }

@media screen and (max-width: 450px) {
  .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .rounded-loading {
    margin: 1rem 0rem 0rem 7rem; } }

@media screen and (max-width: 320px) {
  .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .donut-title {
    font-size: 14px; }
  .layout-mobile .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .rounded-loading {
    margin: 1rem 0rem 0rem 3rem; } }

@keyframes mymove {
  10% {
    top: 0%;
    background-color: var(--base_wallet-sidebar-and-popup);
    opacity: 0; }
  30% {
    top: 30%;
    background-color: var(--base_wallet-sidebar-and-popup);
    opacity: 0.3; }
  50% {
    top: 50%;
    background-color: var(--calculated_secondary-border);
    opacity: 0.5; }
  70% {
    top: 70%;
    background-color: var(--calculated_secondary-border);
    opacity: 0.8; }
  100% {
    top: 100%;
    background-color: var(--base_wallet-sidebar-and-popup); } }

.dust-dialog-content {
  width: 38rem; }
  .dust-dialog-content .dust-note {
    color: var(--specials_pending-waiting-caution); }
  .dust-dialog-content .large-font {
    font-size: 4rem !important; }

.wallet-container {
  border-top: 1px solid var(--labels_important-active-labels-text-graphics); }
  .wallet-container .header-wrapper .image-Wrapper .action_notification-svg svg {
    width: 1.25rem;
    height: 1.25rem;
    background-color: var(--specials_buttons-links-and-highlights);
    border-radius: 1rem;
    padding: 2px; }
  .wallet-container .header-wrapper .action_notification-svg {
    padding-top: 7px; }
  .wallet-container.no-border {
    border: none !important; }
  .wallet-container .ant-switch {
    background-color: var(--base_background); }
    .wallet-container .ant-switch .ant-switch-handle:before {
      background-color: var(--labels_secondary-inactive-label-text-graphics); }
  .wallet-container .ant-switch-checked {
    background-color: var(--base_background); }
    .wallet-container .ant-switch-checked .ant-switch-handle:before {
      background-color: var(--labels_important-active-labels-text-graphics); }
  .wallet-container .wallet-header_block > * {
    margin-bottom: 2rem; }
  .wallet-container .wallet-header_block .wallet-header_block-currency_title {
    font-size: 1.3rem;
    position: relative; }
  .wallet-container .wallet-header_block .with_price-block_amount-value {
    font-size: 4rem;
    line-height: 4rem; }
  .wallet-container .paper-clip-icon .action_notification-svg {
    background-color: var(--specials_buttons-links-and-highlights);
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: flex;
    margin-left: 5px; }
    .wallet-container .paper-clip-icon .action_notification-svg svg {
      width: 0.8rem !important;
      height: 0.8rem !important; }
  .wallet-container .wallet-buttons_action {
    margin: 2.5rem 0 3.75rem; }
    .wallet-container .wallet-buttons_action > *:first-child {
      flex: 2; }
    .wallet-container .wallet-buttons_action > *:last-child {
      flex: 1; }
    .wallet-container .wallet-buttons_action .separator {
      width: 1rem; }
  .wallet-container .accordion_wrapper .accordion_section_content_text {
    color: var(--labels_important-active-labels-text-graphics) !important; }
    .wallet-container .accordion_wrapper .accordion_section_content_text:after {
      border-left-color: var(--calculated_important-border) !important; }
  .wallet-container .empty-wallet-assets_block {
    padding: 20px !important; }
  .wallet-container .wallet-assets_block {
    background-color: var(--base_wallet-sidebar-and-popup);
    padding: 0px 20px;
    overflow-y: auto; }
    .wallet-container .wallet-assets_block .donut-container {
      width: auto !important;
      min-width: auto !important; }
    .wallet-container .wallet-assets_block .donut-container-empty {
      width: 13rem !important;
      min-width: 13rem !important; }
    .wallet-container .wallet-assets_block .td-wallet {
      min-width: 225px; }
      .wallet-container .wallet-assets_block .td-wallet .deposit-withdrawal-wrapper {
        margin-left: -3rem;
        width: 14rem; }
    .wallet-container .wallet-assets_block .wallet-assets_block-table {
      width: 100%;
      font-size: 1rem;
      margin-bottom: 3rem; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table thead tr,
      .wallet-container .wallet-assets_block .wallet-assets_block-table tfoot tr {
        height: 3.5rem;
        vertical-align: bottom; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table tfoot {
        font-size: 1.1rem; }
        .wallet-container .wallet-assets_block .wallet-assets_block-table tfoot td {
          white-space: nowrap; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .table-row {
        height: 2.5rem; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .table-bottom-border td,
      .wallet-container .wallet-assets_block .wallet-assets_block-table th {
        position: relative; }
        .wallet-container .wallet-assets_block .wallet-assets_block-table .table-bottom-border td:not(:first-child),
        .wallet-container .wallet-assets_block .wallet-assets_block-table th:not(:first-child) {
          border-bottom: 1px solid var(--calculated_important-border);
          padding: 1rem 0.25rem; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .td-fit {
        width: 1%;
        white-space: nowrap; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .td-name {
        min-width: 200px !important; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .table-icon > div {
        vertical-align: middle;
        margin: auto; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .td-amount {
        min-width: 150px !important;
        direction: ltr; }
    .wallet-container .wallet-assets_block .search-field {
      background-color: var(--base_wallet-sidebar-and-popup); }
    .wallet-container .wallet-assets_block .loading-anime {
      width: 100px;
      height: 20px;
      border-radius: 7px;
      animation: mymove 3s infinite; }
    .wallet-container .wallet-assets_block .loading-row-anime {
      height: 20px;
      border-radius: 7px;
      animation: mymove 3s infinite; }
    .wallet-container .wallet-assets_block .loading-row-anime.w-half {
      width: 100px; }
    .wallet-container .wallet-assets_block .loading-row-anime.w-full {
      width: 200px; }
    .wallet-container .wallet-assets_block .wallet-graphic-icon {
      opacity: 0.1; }
  .wallet-container .accordion_wrapper .action_notification-wrapper {
    position: relative; }
  .wallet-container .action-button-wrapper {
    border: 1px solid var(--specials_buttons-links-and-highlights);
    padding: 5px 12px; }
    .wallet-container .action-button-wrapper:hover {
      background-color: var(--specials_buttons-links-and-highlights); }
      .wallet-container .action-button-wrapper:hover .notification-info {
        color: white !important; }

.show-equals:after {
  content: '=';
  width: auto;
  position: absolute; }

.wallet-notification .action_notification-text {
  font-size: 1.1rem !important;
  line-height: 1.1rem !important; }

.direction_ltr .wallet-container .wallet-header_block {
  font-family: "Raleway"; }

.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table th {
  font-family: "Raleway"; }

.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table th,
.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table td {
  text-align: left; }

.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table .align-opposite {
  text-align: right; }

.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table .table-icon {
  padding-right: 0.5rem; }

.direction_ltr .show-equals:after {
  left: 0; }

.direction_rtl .wallet-container .wallet-assets_block .wallet-assets_block-table th,
.direction_rtl .wallet-container .wallet-assets_block .wallet-assets_block-table td {
  text-align: right; }

.direction_rtl .wallet-container .wallet-assets_block .wallet-assets_block-table .align-opposite {
  text-align: left; }

.direction_rtl .wallet-container .wallet-assets_block .wallet-assets_block-table .table-icon {
  padding-left: 0.5rem; }

.direction_rtl .show-equals:after {
  right: 0; }

.need-help svg {
  width: 1rem !important;
  height: 1rem !important; }

.wallet-search-improvement {
  border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics);
  color: var(--labels_secondary-inactive-label-text-graphics) !important; }

.dust-estimated-balance {
  border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics);
  text-align: end; }

.dust-image-title-wrapper {
  width: 5.5rem;
  height: 5.5rem;
  opacity: 0.08;
  margin-bottom: 1rem; }

.custom-dropdown-style .ant-dropdown-menu-item {
  color: var(--labels_secondary-inactive-label-text-graphics);
  line-height: 1.1rem;
  font-size: 1rem; }
  .custom-dropdown-style .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
    color: var(--labels_important-active-labels-text-graphics);
    background-color: var(--base_wallet-sidebar-and-popup); }

.custom-dropdown-style .ant-dropdown-menu {
  background-color: var(--base_background);
  max-height: 10rem;
  overflow-y: scroll; }

.custom-dropdown-style .app_bar-pair-font {
  font-size: 0.9rem; }

.link-container {
  font-size: 14px; }

.link-content {
  text-decoration: underline !important;
  color: var(--specials_buttons-links-and-highlights) !important; }

.dust-convert-button {
  width: 300px !important; }

.currency-wallet-wrapper {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Raleway"; }
  .currency-wallet-wrapper .title-wrapper {
    font-size: 3rem; }
    .currency-wallet-wrapper .title-wrapper .edit-wrapper__container {
      margin-left: 1rem;
      display: unset !important; }
  .currency-wallet-wrapper .edit-wrapper__container {
    display: unset !important;
    line-height: unset !important; }
  .currency-wallet-wrapper .link-container .trade-link-wrapper {
    margin: 1%; }
  .currency-wallet-wrapper .wallet-content-wrapper {
    height: 65%;
    border: 1px solid #808080;
    border-radius: 5px;
    justify-content: space-between; }
    .currency-wallet-wrapper .wallet-content-wrapper .wallet-details-wrapper {
      padding: 4rem 3rem; }
    .currency-wallet-wrapper .wallet-content-wrapper .balance-wrapper {
      font-size: 2rem; }
    .currency-wallet-wrapper .wallet-content-wrapper .available-balance-wrapper {
      font-size: 14px; }
    .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper {
      width: 35%;
      align-items: flex-end;
      background-color: var(--base_wallet-sidebar-and-popup);
      border-radius: 5px;
      text-align: center; }
      .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .donut-title {
        font-size: 16px; }
      .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .wallet-icon-wrapper {
        display: flex;
        justify-content: center;
        margin: 10%;
        height: 30%; }
      .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .deposit-link,
      .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .buy-link {
        text-decoration: underline;
        color: #5d63ff; }
      .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .rounded-loading {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        text-align: center;
        margin: 5rem 7rem;
        display: flex;
        align-items: center;
        border: 1px solid #808080;
        background-color: var(--calculated_secondary-border) !important;
        animation: outer-round-anim 3s ease infinite; }
      .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .inner-round {
        background-color: var(--base_wallet-sidebar-and-popup);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-left: 1rem;
        border: 1px solid #808080; }
      .currency-wallet-wrapper .wallet-content-wrapper .donut-wrapper .animation-wrapper {
        display: flex;
        justify-content: center; }

.review-wrapper .review-icon {
  width: 8rem;
  height: 8rem;
  margin: 0 auto; }
  .review-wrapper .review-icon svg {
    width: 8rem;
    height: 8rem; }

.review-wrapper .review-info_container {
  margin: 2rem; }

.review-wrapper .review-crypto-amount {
  font-size: 1.8rem;
  border-bottom: 1px solid var(--labels_important-active-labels-text-graphics);
  text-align: center; }
  .review-wrapper .review-crypto-amount .review-fee_message {
    font-size: 1.1rem; }

.review-wrapper .review-warning_arrow {
  position: relative;
  width: 1px;
  height: 3rem;
  background-color: var(--labels_secondary-inactive-label-text-graphics); }
  .review-wrapper .review-warning_arrow:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0;
    left: calc(50% - 4px);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid var(--labels_secondary-inactive-label-text-graphics); }

.review-wrapper .review-crypto-address {
  font-size: 1.8rem;
  border: 1px solid var(--labels_secondary-inactive-label-text-graphics);
  padding: 0.25rem 1rem; }

.review-wrapper .review-crypto-mail {
  font-size: 1.8rem;
  border: 1px solid var(--labels_secondary-inactive-label-text-graphics);
  padding: 1.5rem 12rem;
  word-break: break-all; }
  .review-wrapper .review-crypto-mail .review-fee_message {
    font-size: 1.1rem; }

.review-wrapper .review-info_message {
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold; }

.review-wrapper .button-separator {
  width: 2rem; }

.bank_account-information-wrapper {
  margin: 2rem 0; }

.withdrawal-container .icon_title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.withdrawal-container .withdraw-form-wrapper .withdraw-form {
  border-radius: 4px;
  margin: 0 0 40px;
  background-color: var(--base_wallet-sidebar-and-popup);
  padding: 30px; }
  .withdrawal-container .withdraw-form-wrapper .withdraw-form .field-children .field-valid {
    top: 8px;
    right: -20px; }

.withdrawal-container .withdraw-form-wrapper .btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
  .withdrawal-container .withdraw-form-wrapper .btn-wrapper .holla-button {
    width: 300px; }

.layout-mobile .review-wrapper {
  min-width: 0;
  margin: auto; }

.layout-mobile .presentation_container .action_notification-svg svg {
  width: 1.5rem !important;
  height: 1.5rem !important; }

.layout-mobile .presentation_container .inner_container .result-message {
  font-size: 1.2rem; }

.layout-mobile .presentation_container .inner_container .deposit_info-wrapper .deposit_info-crypto-wrapper {
  width: 100%; }
  .layout-mobile .presentation_container .inner_container .deposit_info-wrapper .deposit_info-crypto-wrapper .field-label {
    font-size: 1.75rem; }
  .layout-mobile .presentation_container .inner_container .deposit_info-wrapper .deposit_info-crypto-wrapper .field-children .custom {
    font-size: 1.5rem; }

.layout-mobile .presentation_container .inner_container .qr_code-wrapper {
  width: 24rem;
  max-width: 24rem; }
  .layout-mobile .presentation_container .inner_container .qr_code-wrapper .qr-code-bg > *:first-child {
    width: 24rem !important;
    height: 24rem !important; }
  .layout-mobile .presentation_container .inner_container .qr_code-wrapper .qr-text {
    font-size: 11px !important; }

.layout-mobile .presentation_container .inner_container .information_block .information_block-text_wrapper .title {
  font-size: 15px; }

.layout-mobile .presentation_container .inner_container .information_block .information_block-text_wrapper .text {
  font-size: 13px !important; }

.layout-mobile .presentation_container .inner_container .field-label,
.layout-mobile .presentation_container .inner_container .field-children,
.layout-mobile .presentation_container .inner_container .input_field-input {
  font-size: 13px !important; }

.layout-mobile .presentation_container .inner_container .field-error-content {
  font-size: 1.2rem; }

.layout-mobile .presentation_container .inner_container .action_notification-wrapper .action_notification-text {
  font-size: 1.2rem;
  line-height: 1.2rem; }

.layout-mobile .presentation_container .inner_container .holla-button {
  font-size: 13px !important; }

.layout-mobile .review_email-wrapper {
  margin: 0px auto !important; }

.layout-mobile .withdrawal-container .withdraw-form-wrapper .withdraw-form .field-wrapper .field-label-wrapper {
  flex-direction: column; }

@media screen and (max-width: 550px) {
  .layout-mobile .presentation_container .inner_container .information_block .information_block-text_wrapper .title {
    font-size: 14px !important; }
  .layout-mobile .presentation_container .inner_container .information_block .information_block-text_wrapper .text {
    font-size: 12px !important; } }

@media screen and (max-width: 390px) {
  .layout-mobile .presentation_container .inner_container .information_block .information_block-text_wrapper .title {
    font-size: 13px !important; }
  .layout-mobile .presentation_container .inner_container .information_block .information_block-text_wrapper .text {
    font-size: 10px !important; }
  .layout-mobile .presentation_container .inner_container .holla-button {
    font-size: 12px !important; } }

.review_email-wrapper {
  margin: auto; }
  .review_email-wrapper .review_email-content {
    border-top: 1px solid;
    padding: 1rem; }

@media screen and (max-width: 1096px) {
  .withdrawal-container .withdraw-form-wrapper .withdraw-form .field-wrapper .field-label {
    font-size: 11px; }
  .withdrawal-container .withdraw-form-wrapper .withdraw-form .field-wrapper .input-box-field .input_field-input::placeholder {
    font-size: 11px; }
  .withdrawal-container .withdraw-form-wrapper .btn-wrapper .holla-button {
    width: 200px; } }

.qr_reader_wrapper {
  width: 25rem;
  margin: 2rem; }

.extra_information-wrapper {
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 1rem; }
  .extra_information-wrapper .link {
    color: var(--specials_buttons-links-and-highlights);
    padding: 0 0.25rem; }

.margin-aligner {
  margin-bottom: 24px;
  margin-right: 1rem; }
  .margin-aligner svg {
    width: 30px !important;
    height: 30px !important; }

.deposit_info-wrapper > div {
  flex: 1; }

.qr_code-wrapper {
  margin: 0.5rem;
  width: 12rem;
  max-width: 12rem; }
  .qr_code-wrapper .qr-code-bg {
    background-color: white;
    margin: 1rem; }
    .qr_code-wrapper .qr-code-bg > *:first-child {
      width: 11rem !important;
      height: 11rem !important; }

.deposit_info-qr-wrapper .qr-text {
  text-align: center;
  display: flex;
  font-size: 1rem;
  padding: 1.5rem 0;
  margin-left: 1rem;
  max-width: 20rem; }

.deposit_info-qr-wrapper .qr-title {
  font-size: 1.5rem; }

.review-wrapper {
  min-width: 575px; }
  .review-wrapper .review-section {
    font-size: 1rem; }
  .review-wrapper .review-title {
    font-size: 1.8rem;
    margin-bottom: 0.5rem; }
  .review-wrapper .separator {
    margin: 1rem 0;
    height: 2px;
    width: 100%;
    background-color: var(--labels_secondary-inactive-label-text-graphics); }

.verification-wrapper .verification-content-wrapper {
  position: relative; }

.verification-wrapper .error {
  font-size: 1.8rem; }

.verification-wrapper .block-wrapper {
  margin: 1rem; }

.multiple-actions-wrapper .mini-qr {
  fill: var(--specials_buttons-links-and-highlights); }

.transaction-history-wrapper .ant-row,
.login-history-section-wrapper .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0.5rem; }

.transaction-history-wrapper .ant-form-item,
.login-history-section-wrapper .ant-form-item {
  margin-bottom: 0; }

.transaction-history-wrapper .ant-radio-button-wrapper,
.login-history-section-wrapper .ant-radio-button-wrapper {
  color: var(--labels_secondary-inactive-label-text-graphics);
  background-color: transparent !important;
  border: none !important; }
  .transaction-history-wrapper .ant-radio-button-wrapper:active, .transaction-history-wrapper .ant-radio-button-wrapper:hover,
  .login-history-section-wrapper .ant-radio-button-wrapper:active,
  .login-history-section-wrapper .ant-radio-button-wrapper:hover {
    color: var(--labels_important-active-labels-text-graphics); }

.transaction-history-wrapper #trades-filters_size *,
.login-history-section-wrapper #trades-filters_size * {
  transition: 0.2s; }

.transaction-history-wrapper .ant-radio-button-wrapper-checked::before,
.transaction-history-wrapper .ant-radio-button-wrapper::before,
.login-history-section-wrapper .ant-radio-button-wrapper-checked::before,
.login-history-section-wrapper .ant-radio-button-wrapper::before {
  background-color: transparent !important; }

.transaction-history-wrapper .ant-radio-button-wrapper-checked,
.login-history-section-wrapper .ant-radio-button-wrapper-checked {
  color: var(--labels_important-active-labels-text-graphics);
  border: var(--labels_important-active-labels-text-graphics) 1px solid !important;
  border-radius: 0.2rem; }

.transaction-history-wrapper .ant-picker,
.login-history-section-wrapper .ant-picker {
  color: var(--labels_secondary-inactive-label-text-graphics);
  background-color: transparent;
  border-bottom: 1px solid var(--calculated_secondary-border);
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0 3px; }

.transaction-history-wrapper .ant-picker-separator,
.login-history-section-wrapper .ant-picker-separator {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.transaction-history-wrapper .ant-picker-input > input,
.login-history-section-wrapper .ant-picker-input > input {
  color: var(--labels_important-active-labels-text-graphics); }

.transaction-history-wrapper .ant-picker-focused,
.transaction-history-wrapper .ant-picker:hover,
.transaction-history-wrapper .ant-picker:active,
.login-history-section-wrapper .ant-picker-focused,
.login-history-section-wrapper .ant-picker:hover,
.login-history-section-wrapper .ant-picker:active {
  border-color: var(--calculated_important-border); }

.transaction-history-wrapper .ant-picker-clear,
.login-history-section-wrapper .ant-picker-clear {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.transaction-history-wrapper .ant-picker-active-bar,
.login-history-section-wrapper .ant-picker-active-bar {
  background: var(--specials_buttons-links-and-highlights); }

.transaction-history-wrapper .ant-form-item-label > label,
.login-history-section-wrapper .ant-form-item-label > label {
  color: var(--labels_important-active-labels-text-graphics); }

.transaction-history-wrapper .ant-radio-group,
.login-history-section-wrapper .ant-radio-group {
  margin-top: 0;
  padding: 0 15px;
  border-left: 1px var(--calculated_secondary-border) solid; }

.transaction-history-wrapper .ant-btn-ghost,
.login-history-section-wrapper .ant-btn-ghost {
  color: var(--labels_secondary-inactive-label-text-graphics);
  border-color: var(--labels_secondary-inactive-label-text-graphics); }
  .transaction-history-wrapper .ant-btn-ghost:hover,
  .transaction-history-wrapper .ant-btn-ghost :active,
  .login-history-section-wrapper .ant-btn-ghost:hover,
  .login-history-section-wrapper .ant-btn-ghost :active {
    color: var(--labels_important-active-labels-text-graphics);
    border-color: var(--labels_important-active-labels-text-graphics); }
  .transaction-history-wrapper .ant-btn-ghost.active_search_button,
  .login-history-section-wrapper .ant-btn-ghost.active_search_button {
    animation: shine 2s linear infinite alternate;
    animation-fill-mode: both;
    color: var(--labels_secondary-inactive-label-text-graphics);
    box-shadow: 0 0 8px 0.1px var(--base_wallet-sidebar-and-popup);
    position: relative; }

.transaction-history-wrapper .cusStyle1 .ant-form-item-control-input-content,
.login-history-section-wrapper .cusStyle1 .ant-form-item-control-input-content {
  color: var(--labels_important-active-labels-text-graphics);
  border: var(--labels_important-active-labels-text-graphics) 1px solid !important;
  border-radius: 0.2rem;
  cursor: pointer;
  padding: 0 5px; }

.transaction-history-wrapper .cusStyle2,
.login-history-section-wrapper .cusStyle2 {
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .transaction-history-wrapper .cusStyle2 .ant-form-item-control-input-content,
  .login-history-section-wrapper .cusStyle2 .ant-form-item-control-input-content {
    padding: 0 5px; }

.transaction-history-wrapper .cusStyle2:hover,
.login-history-section-wrapper .cusStyle2:hover {
  cursor: pointer; }

@keyframes shine {
  10% {
    color: var(--labels_secondary-inactive-label-text-graphics);
    box-shadow: 0 0 8px 0.1px var(--base_wallet-sidebar-and-popup);
    transition-property: color, box-shadow;
    transition-duration: 0.7s, 0.7s;
    transition-timing-function: ease; }
  100% {
    color: var(--labels_important-active-labels-text-graphics);
    box-shadow: 0 0 8px 0.1px var(--labels_secondary-inactive-label-text-graphics);
    transition-property: color, box-shadow; } }

.layout-mobile .tab_controller-wrapper {
  height: auto; }

.layout-mobile .inner_container {
  height: calc(100% - 4.5rem); }
  .layout-mobile .inner_container .history_block-wrapper {
    max-height: calc(100% - 1rem);
    overflow-x: scroll; }
    .layout-mobile .inner_container .history_block-wrapper .table-content {
      overflow-x: scroll;
      white-space: nowrap;
      width: 100%; }
    .layout-mobile .inner_container .history_block-wrapper .table-wrapper .table_header-wrapper,
    .layout-mobile .inner_container .history_block-wrapper .table-wrapper .table_body-wrapper {
      font-size: 1rem !important; }
    .layout-mobile .inner_container .history_block-wrapper .table-wrapper .table_body-wrapper {
      height: 4.5rem; }

.layout-mobile .display_table-wrapper .display_table-cell {
  min-height: 2rem !important; }

.layout-mobile .table_container .table-content.transactions-history-table {
  overflow-x: auto;
  position: relative; }
  .layout-mobile .table_container .table-content.transactions-history-table thead,
  .layout-mobile .table_container .table-content.transactions-history-table td {
    font-size: 1.2rem !important; }
  .layout-mobile .table_container .table-content.transactions-history-table td {
    padding: 0.75rem;
    min-width: 9rem; }
  .layout-mobile .table_container .table-content.transactions-history-table .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: var(--base_wallet-sidebar-and-popup);
    left: 0;
    width: 10rem !important;
    min-width: 10rem !important;
    max-width: 10rem !important;
    z-index: 1; }
    .layout-mobile .table_container .table-content.transactions-history-table .sticky-col.coin-cell {
      width: 12rem !important;
      min-width: 12rem !important;
      max-width: 12rem !important; }

.icon-cell {
  width: 3rem; }
  .icon-cell > div {
    margin: auto; }

.cell_box-type > div {
  color: white; }

.cell_box-type .withdraw,
.cell_box-type .sell {
  background-color: var(--trading_selling-related-elements);
  color: var(--calculated_trading_selling-related-text); }

.cell_box-type .deposit,
.cell_box-type .buy {
  background-color: var(--trading_buying-related-elements);
  color: var(--calculated_trading_buying-related-text); }

.cell_box-type.fullfilled .cell-wrapper {
  border: 1px solid var(--calculated_important-border);
  margin: 0;
  padding: 1px;
  min-width: 5rem;
  max-width: 7rem; }
  .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper {
    padding: 0 0.15rem;
    position: relative;
    width: 100%;
    color: var(--labels_secondary-inactive-label-text-graphics);
    z-index: 2; }
    .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper .cell_value-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: var(--calculated_specials_highlight-box);
      z-index: -1; }

.amount_text .positive {
  color: var(--specials_checks-okay-done); }

.amount_text .negative {
  color: var(--specials_notifications-alerts-warnings); }

.history_block-wrapper {
  position: relative;
  background-color: var(--base_wallet-sidebar-and-popup);
  padding: 20px;
  margin: 20px 0; }
  .history_block-wrapper .action_notification-svg svg {
    width: 1.2rem !important;
    height: 1.2rem !important; }
  .history_block-wrapper .check-deposit-wrapper {
    position: absolute;
    right: 100px;
    top: 2px;
    text-transform: uppercase;
    color: var(--specials_buttons-links-and-highlights);
    font-size: 11px;
    cursor: pointer; }
  .history_block-wrapper .new-tag-wrapper {
    position: relative; }
    .history_block-wrapper .new-tag-wrapper .new-tag {
      position: absolute;
      right: 100%;
      background-color: #008509;
      padding: 0 7px;
      color: #ffffff;
      border-radius: 20px;
      margin-right: 10px; }
  .history_block-wrapper .loader_wrapper {
    top: 0;
    left: 0; }
  .history_block-wrapper .table-wrapper .table_body-wrapper.with_icon tr td:first-child {
    border-top: 1px solid var(--calculated_secondary-border) !important;
    border-bottom: 1px solid var(--calculated_secondary-border) !important; }
  .history_block-wrapper .table-wrapper .table_body-wrapper.with_icon tr td:first-child.category-label {
    border: none !important; }
  .history_block-wrapper .ant-picker-clear {
    background: none; }
  .history_block-wrapper .action_notification-wrapper {
    top: 21px; }
  .history_block-wrapper .loader_background {
    filter: opacity(0.25) !important; }

.action_notification-svg svg {
  width: 2rem;
  height: 2rem; }

.btn-tx,
.withdrawal-cancel {
  cursor: pointer; }

.transaction-history-wrapper svg .deposit-history-3,
.transaction-history-wrapper svg .withdrawals-history-3,
.transaction-history-wrapper svg .transaction-history-1,
.transaction-history-wrapper svg .transaction-history-5 {
  fill: var(--labels_secondary-inactive-label-text-graphics); }

.transaction-history-wrapper .icon_title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.transaction-history-wrapper .with_border_top {
  border-top: none !important; }

.display_table-wrapper .display_table-cell {
  min-height: 1.2rem !important;
  padding: 0 0.1rem !important; }

.display_table-wrapper:first-child .display_table-body {
  padding-top: 0.1rem !important; }

.transaction-status {
  font-weight: bold; }
  .transaction-status .completed {
    color: var(--specials_checks-okay-done); }
  .transaction-status .pending {
    color: var(--specials_pending-waiting-caution); }
  .transaction-status .rejected {
    color: var(--specials_notifications-alerts-warnings); }

.flex-row {
  flex-direction: row; }

.expandable-container {
  display: flex;
  flex-direction: column; }
  .expandable-container div {
    display: flex;
    flex-direction: row; }
  .expandable-container p {
    margin-right: 1rem;
    font-weight: thin;
    margin-bottom: 0px; }
  .expandable-container .font-bold {
    font-weight: bolder; }

.action_notification-container {
  display: flex; }
  .action_notification-container .action_notification-wrapper {
    position: unset; }
  .action_notification-container > div:not(:last-child)::after {
    padding: 0 0.8rem;
    content: '|';
    color: var(--labels_secondary-inactive-label-text-graphics); }

.trade-container {
  flex: 1; }
  .trade-container .dialog-link {
    color: var(--specials_buttons-links-and-highlights);
    padding: 0 0.25rem; }
  .trade-container .trade-col_main_wrapper .trade-main_content {
    flex: 6;
    min-height: 25vh;
    max-height: 60vh; }
    .trade-container .trade-col_main_wrapper .trade-main_content.chart-embed {
      min-height: 100vh;
      max-height: 100vh; }
  .trade-container .trade-col_main_wrapper .trade-tabs_content {
    flex: 3;
    height: 30vh; }
    .trade-container .trade-col_main_wrapper .trade-tabs_content > * {
      flex: 1 !important; }
    .trade-container .trade-col_main_wrapper .trade-tabs_content .xht-logo {
      width: 3rem;
      height: 3rem; }
  .trade-container .trade-col_side_wrapper {
    flex: 0;
    min-width: 23rem;
    max-width: 23rem; }
    .trade-container .trade-col_side_wrapper > div {
      flex: 1; }
  .trade-container .trade-col_action_wrapper {
    flex: 0;
    min-width: 20rem;
    max-width: 20rem; }
  .trade-container .table-wrapper {
    margin: 0; }

.xht-order-heading {
  font-weight: bold;
  font-size: 1.1rem; }

.vertical-line-seperator {
  width: 1px;
  height: 50%;
  margin: 0.4rem 0.33rem;
  border: 1px solid var(--calculated_secondary-border); }

.trade-daily-value-container {
  display: flex;
  text-transform: none !important; }
  .trade-daily-value-container .trade_header_values {
    opacity: 0.55;
    margin-left: 0.25rem; }

.xht-order-content {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.layout-mobile .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form-action, .layout-mobile .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form-action {
  height: 4.5rem !important; }

.layout-mobile .trade_order_entry-wrapper .trade_order_entry-review,
.layout-mobile .trade_order_entry-wrapper .trade_order_entry-selector,
.layout-mobile .trade_order_entry-wrapper .trade_order_entry-action_selector,
.layout-mobile .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper,
.layout-mobile .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper {
  margin-bottom: 3rem !important; }

.layout-mobile .trade_block-title {
  font-size: 1.75rem;
  border-top-width: 0.4rem !important;
  margin-bottom: unset !important; }

.layout-mobile .trade_order_entry-action_selector {
  height: 4rem !important; }

.layout-mobile .trade_order_entry-selector {
  font-size: 1.2rem; }
  .layout-mobile .trade_order_entry-selector > div {
    line-height: 3rem !important; }

.layout-mobile .trade_orderbook-headers {
  border-bottom: none !important;
  font-size: 1.2rem; }

.layout-mobile .trade_orderbook-asks,
.layout-mobile .trade_orderbook-bids,
.layout-mobile .trade_orderbook-spread {
  font-size: 1.5rem; }

.layout-mobile .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
  font-size: 1.2rem !important; }

.layout-mobile .trade_orderbook-spread {
  min-height: 3rem !important;
  margin: 1rem 0; }

.layout-mobile .trade_orderbook-asks,
.layout-mobile .trade_orderbook-bids {
  min-height: unset; }

.layout-mobile .trade_orderbook-wrapper {
  height: 100% !important; }

.layout-mobile .trade_input-wrapper .trade_input-label {
  font-size: 1.2rem; }

.layout-mobile .trade_input-wrapper .trade_input-input-wrapper > input {
  height: 4.5rem !important;
  font-size: 1.5rem; }

.layout-mobile .trade_input-wrapper .trade_input-input-wrapper .trade_input-input-currency {
  height: 4.5rem !important;
  font-size: 1.2rem; }

.layout-mobile .trade_order_entry-review > div {
  margin-bottom: 0.5rem;
  font-size: 1.2rem; }

.layout-mobile .xht-logo {
  width: 3rem;
  height: 3rem; }

@media (min-width: 1920px) {
  .trade-container .trade-col_side_wrapper {
    min-width: 24rem;
    max-width: 24rem; }
  .trade-container .trade-col_action_wrapper {
    min-width: 20rem;
    max-width: 20rem; } }

@media screen and (max-width: 1096px) {
  .trade-container .trade-col_side_wrapper {
    min-width: 23rem;
    max-width: 23rem; }
  .trade-container .trade-col_action_wrapper {
    min-width: 20rem;
    max-width: 20rem; } }

.trade__active-orders_cancel-All {
  position: relative;
  min-height: 2rem;
  min-width: 6rem; }

.trade__active-orders-header_cancel-All {
  position: relative; }

.trade_cancel-All-confirmation .ant-modal-header,
.trade_cancel-All-confirmation .ant-modal-content,
.trade_cancel-All-confirmation .ant-modal-confirm-body .ant-modal-confirm-content {
  background-color: var(--base_wallet-sidebar-and-popup);
  color: var(--labels_important-active-labels-text-graphics); }

.layout-mobile .action_notification-wrapper .action_notification-svg svg {
  width: 1.5rem;
  height: 1.5rem; }

.layout-mobile .action_notification-wrapper .action_notification-text {
  font-size: 1.2rem;
  line-height: 1.2rem; }

.layout-mobile .trade_block-wrapper .trade_block-content {
  padding: 0;
  font-size: 1.2rem; }
  .layout-mobile .trade_block-wrapper .trade_block-content .table_body-wrapper {
    font-size: 1.2rem; }

.layout-mobile .trade_block-wrapper .trade_block-title {
  cursor: default;
  background-color: inherit;
  padding: 0; }
  .layout-mobile .trade_block-wrapper .trade_block-title .action_notification-wrapper .action_notification-text {
    font-size: 13px !important;
    line-height: 1.2rem !important; }

.layout-mobile .trade_history-wrapper {
  height: 38vh; }
  .layout-mobile .trade_history-wrapper .display_table-wrapper .display_table-body {
    font-size: 13px; }

.trade_block-wrapper {
  height: 100%;
  background-color: var(--base_wallet-sidebar-and-popup);
  border: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .trade_block-wrapper.chart-embed {
    padding: 1.2rem;
    max-height: 100vh; }
    .trade_block-wrapper.chart-embed .trade_block-title {
      cursor: default;
      background-color: inherit;
      padding: 0; }
    .trade_block-wrapper.chart-embed .trade_block-content {
      padding: 0; }
  .trade_block-wrapper .trade_block-title {
    cursor: move;
    background-color: var(--calculated_trade-block_highlight);
    padding: 0.5rem 1.2rem 0.3rem 1.2rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1rem;
    flex: none;
    position: relative; }
    .trade_block-wrapper .trade_block-title .trade_block-title-currency {
      color: var(--labels_important-active-labels-text-graphics);
      font-size: 0.9rem;
      margin-left: 0.3rem;
      margin-top: 0.1rem; }
  .trade_block-wrapper .trade_block-content {
    flex: auto;
    padding: 0 1.2rem 1.2rem 1.2rem;
    font-size: 1rem;
    overflow-y: scroll;
    height: 100%; }
    .trade_block-wrapper .trade_block-content .sidebar_hub-content {
      height: 100%;
      overflow-y: auto; }

.trade_block_tabs-wrapper .trade_block-title {
  position: relative; }
  .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items {
    display: flex;
    font-weight: normal;
    line-height: 25px; }
    .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items .active {
      font-weight: bold;
      position: relative; }
      .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items .active:after {
        content: '';
        height: 4px;
        width: 100%;
        background-color: var(--calculated_important-border);
        top: 0;
        left: 0;
        right: 0;
        position: absolute; }

.trade_block-column-wrapper > div {
  flex: 1 !important;
  overflow-y: scroll; }

.direction_ltr .trade_block-title {
  font-family: "Raleway Extrabold"; }

.direction_ltr .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items > div:not(:last-child) {
  margin-right: 2rem; }

.direction_rtl .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items > div:not(:last-child) {
  margin-left: 2rem; }

.trade_history-wrapper {
  height: 100%; }
  .trade_history-wrapper .display_table-wrapper .display_table-header :nth-child(2) {
    text-align: center; }
  .trade_history-wrapper .display_table-wrapper .display_table-header .ant-select-arrow {
    z-index: 1; }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell :nth-child(2),
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell :nth-child(3) {
    text-align: right; }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell div {
    overflow: unset !important; }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill {
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 0; }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill-buy {
    background-image: linear-gradient(var(--calculated_trading_buying-orderbook-bar), var(--calculated_trading_buying-orderbook-bar)); }
    .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill-buy .public-sale_zeros {
      color: var(--calculated_public-sale_buying-zeros); }
    .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill-buy .public-sale_digits {
      color: var(--trading_buying-related-elements); }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill-sell {
    background-image: linear-gradient(var(--calculated_trading_selling-orderbook-bar), var(--calculated_trading_selling-orderbook-bar)); }
    .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill-sell .public-sale_zeros {
      color: var(--calculated_public-sale_selling-zeros); }
    .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill-sell .public-sale_digits {
      color: var(--trading_selling-related-elements); }

.layout-mobile .trade-container .orderBook-wrapper {
  max-height: 100% !important;
  min-height: 100% !important; }

.layout-mobile .trade_block-wrapper {
  padding: 1.2rem;
  margin: 6px 3px 3px 3px;
  max-height: 50vh;
  border: none; }
  .layout-mobile .trade_block-wrapper.chart-embed {
    max-height: 100vh; }

.trade-wrapper-action {
  margin-top: 2.5rem;
  margin-right: 1rem; }

.trade_order_entry-wrapper.order_side-selector-sell {
  flex: 1;
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .trade_order_entry-wrapper.order_side-selector-sell > div:not(:last-child) {
    margin-bottom: 0.5rem; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector {
    position: relative; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector :before {
      content: '';
      position: absolute;
      top: -2px;
      background: var(--labels_important-active-labels-text-graphics);
      left: 0;
      width: 100%;
      height: 1px; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector > div {
      line-height: 2rem; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector .active {
      position: relative;
      color: var(--labels_important-active-labels-text-graphics);
      font-weight: bold; }
      .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector .active:after {
        content: '';
        position: absolute;
        top: -2px;
        background: var(--labels_important-active-labels-text-graphics);
        left: 0;
        width: 100%;
        height: 4px; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-action_selector {
    border: 1px solid var(--trading_selling-related-elements); }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-action_selector .active {
      background-color: var(--trading_selling-related-elements);
      color: var(--calculated_trading_selling-related-text);
      font-weight: bold; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-review {
    border-top: 1px solid var(--calculated_secondary-border);
    line-height: 1.5rem; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-review .text-price {
      color: var(--labels_important-active-labels-text-graphics); }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-review .blue-link {
      color: var(--specials_buttons-links-and-highlights); }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-action_selector,
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form-action {
    height: 2.5rem; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector > *,
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-action_selector > *,
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form > * {
    flex: 1; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .trade_order_entry-form_fields-wrapper > div:not(:last-child) {
    margin-bottom: 0.5rem; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .trade_order_entry-form-action {
    margin-top: 0.5rem;
    background-color: var(--trading_selling-related-elements) !important;
    color: var(--calculated_trading_selling-related-text);
    font-weight: bold; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .trade_order_entry-form-action:disabled {
      background-color: var(--labels_inactive-button) !important;
      color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .warning_text {
    margin-top: 0.25rem; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .form-error {
    margin: 0.5rem 0; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper {
    margin-bottom: 0.2rem !important; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper .ant-collapse-header {
      text-align: right;
      color: var(--labels_secondary-inactive-label-text-graphics);
      padding: 0;
      font-size: 13px; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper .ant-collapse-content-box {
      padding: 0; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper .underline-text {
      font-size: 11px; }

.trade_order_entry-wrapper.order_side-selector-buy {
  flex: 1;
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .trade_order_entry-wrapper.order_side-selector-buy > div:not(:last-child) {
    margin-bottom: 0.5rem; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector {
    position: relative; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector :before {
      content: '';
      position: absolute;
      top: -2px;
      background: var(--labels_important-active-labels-text-graphics);
      left: 0;
      width: 100%;
      height: 1px; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector > div {
      line-height: 2rem; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector .active {
      position: relative;
      color: var(--labels_important-active-labels-text-graphics);
      font-weight: bold; }
      .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector .active:after {
        content: '';
        position: absolute;
        top: -2px;
        background: var(--labels_important-active-labels-text-graphics);
        left: 0;
        width: 100%;
        height: 4px; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-action_selector {
    border: 1px solid var(--trading_buying-related-elements); }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-action_selector .active {
      background-color: var(--trading_buying-related-elements);
      color: var(--calculated_trading_buying-related-text);
      font-weight: bold; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-review {
    border-top: 1px solid var(--calculated_secondary-border);
    line-height: 1.5rem; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-review .text-price {
      color: var(--labels_important-active-labels-text-graphics); }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-review .blue-link {
      color: var(--specials_buttons-links-and-highlights); }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-action_selector,
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form-action {
    height: 2.5rem; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector > *,
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-action_selector > *,
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form > * {
    flex: 1; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .trade_order_entry-form_fields-wrapper > div:not(:last-child) {
    margin-bottom: 0.5rem; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .trade_order_entry-form-action {
    margin-top: 0.5rem;
    background-color: var(--trading_buying-related-elements) !important;
    color: var(--calculated_trading_buying-related-text);
    font-weight: bold; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .trade_order_entry-form-action:disabled {
      background-color: var(--labels_inactive-button) !important;
      color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .warning_text {
    margin-top: 0.25rem; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .form-error {
    margin: 0.5rem 0; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper {
    margin-bottom: 0.2rem !important; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper .ant-collapse-header {
      text-align: right;
      color: var(--labels_secondary-inactive-label-text-graphics);
      padding: 0;
      font-size: 13px; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper .ant-collapse-content-box {
      padding: 0; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper .underline-text {
      font-size: 11px; }

.trade_order_entry-wrapper .post-only-txt {
  font-size: 1rem;
  color: var(--labels_secondary-inactive-label-text-graphics);
  cursor: pointer; }

.risky-trade-wrapper {
  width: 32rem; }
  .risky-trade-wrapper .icon_title-wrapper .icon_title-svg {
    height: 10rem;
    width: 10rem; }
  .risky-trade-wrapper .icon_title-wrapper .icon_title-svg svg {
    height: 10rem;
    width: 10rem; }
  .risky-trade-wrapper .icon_title-wrapper .icon_title-text.title {
    font-size: 2rem; }
  .risky-trade-wrapper .icon_title-wrapper .icon_title-text {
    margin-top: 3rem;
    text-align: left !important;
    width: 100%; }
  .risky-trade-wrapper .risky_managment_percentage {
    color: var(--specials_notifications-alerts-warnings); }

.layout-mobile .trade_order_entry-selector {
  font-size: 13px !important; }

.layout-mobile .trade_orderbook-bids .trade_orderbook-cell {
  font-size: 13px !important; }

.layout-mobile .trade_input-wrapper .trade_input-label {
  font-size: 11px !important; }

.layout-mobile .trade_input-wrapper .trade_input-input-wrapper > input {
  font-size: 13px !important; }

.layout-mobile .trade_order_entry-review > div {
  font-size: 11px !important; }

.layout-mobile .holla-button {
  font-size: 13px !important; }

.layout-mobile .trade_orderbook-wrapper .trade_asks-limit_bar .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
  font-size: 11px !important; }

.layout-mobile .risky-trade-wrapper {
  margin: auto; }

.trade_orderbook-wrapper {
  height: 100%; }
  .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell {
    font-size: 1rem !important;
    flex-direction: column; }
    .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell .custom-select-input-style {
      font-size: 1rem !important;
      max-width: 8rem; }
      .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell .custom-select-input-style .ant-select-selector {
        height: 1.5rem !important;
        line-height: 1.5rem !important;
        padding: 0;
        font-size: 1rem !important; }
      .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell .custom-select-input-style .ant-select-selection-item {
        height: 1.5rem !important;
        line-height: 1.5rem !important;
        font-weight: bold; }
      .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell .custom-select-input-style .ant-select-arrow {
        right: 2px; }
  .trade_orderbook-wrapper .trade_orderbook-cell {
    padding: 0 0.25rem; }
    .trade_orderbook-wrapper .trade_orderbook-cell .ant-select-arrow {
      color: var(--labels_important-active-labels-text-graphics);
      z-index: 1; }
    .trade_orderbook-wrapper .trade_orderbook-cell .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
      padding-right: 0; }
  .trade_orderbook-wrapper .price-row-wrapper {
    margin: 1px 0;
    text-align: right;
    transition: all 0.5s ease-out 0s; }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-price {
      transition: all 0.5s ease-out 0s;
      text-align: left; }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-amount {
      text-align: right; }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-amount {
      color: var(--calculated_trading-orderbook-text); }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell_total {
      color: var(--calculated_trading-orderbook-secondary-text); }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-price:hover,
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-amount:hover,
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell_total:hover {
      color: var(--labels_important-active-labels-text-graphics) !important;
      font-weight: bold; }
      .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-price:hover .important-text,
      .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-price:hover .secondary-text,
      .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-amount:hover .important-text,
      .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-amount:hover .secondary-text,
      .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell_total:hover .important-text,
      .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell_total:hover .secondary-text {
        color: var(--labels_important-active-labels-text-graphics) !important;
        font-weight: bold; }
  .trade_orderbook-wrapper .fill {
    background-repeat: no-repeat;
    background-size: 0;
    background-position: right; }
  .trade_orderbook-wrapper .fill-bid {
    background-image: linear-gradient(var(--calculated_trading_buying-orderbook-bar), var(--calculated_trading_buying-orderbook-bar)); }
  .trade_orderbook-wrapper .fill-ask {
    background-image: linear-gradient(var(--calculated_trading_selling-orderbook-bar), var(--calculated_trading_selling-orderbook-bar)); }
  .trade_orderbook-wrapper .trade_orderbook-headers {
    font-weight: bold;
    border-bottom: 1px solid var(--calculated_secondary-border);
    text-transform: capitalize;
    min-height: 1.5rem;
    font-size: 0.85rem; }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-spread {
    flex: 0;
    min-height: 4rem;
    color: var(--labels_secondary-inactive-label-text-graphics);
    padding: 0 0.25rem; }
    .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-spread .trade_orderbook-spread-text {
      color: var(--labels_important-active-labels-text-graphics);
      margin-left: 0.25rem; }
    .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-spread .trade_orderbook-market-price {
      margin-left: 0.25rem;
      font-size: 1.3rem;
      font-weight: bold;
      color: var(--labels_important-active-labels-text-graphics); }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-asks .value-row,
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-bids .value-row {
    min-height: 1.5rem; }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-bids .trade_orderbook-cell-price {
    color: var(--trading_buying-related-elements); }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-bids .price-row-wrapper:hover {
    background-color: var(--calculated_trading_buying-orderbook-hover); }
    .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-bids .price-row-wrapper:hover .trade_orderbook-cell-price {
      color: var(--labels_important-active-labels-text-graphics) !important; }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-asks .trade_orderbook-cell-price {
    color: var(--trading_selling-related-elements); }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-asks .price-row-wrapper:hover {
    background-color: var(--calculated_trading_selling-orderbook-hover); }
    .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-asks .price-row-wrapper:hover .trade_orderbook-cell-price {
      color: var(--labels_important-active-labels-text-graphics) !important; }
  .trade_orderbook-wrapper .trade_asks-limit_bar .orderbook-limit_bar-wrapper {
    color: var(--trading_selling-related-elements); }
    .trade_orderbook-wrapper .trade_asks-limit_bar .orderbook-limit_bar-wrapper .orderbook-limit_bar-line {
      background-color: var(--trading_selling-related-elements); }
  .trade_orderbook-wrapper .trade_orderbook-asks {
    color: var(--trading_selling-related-elements); }
    .trade_orderbook-wrapper .trade_orderbook-asks .price-row-wrapper.up.entering, .trade_orderbook-wrapper .trade_orderbook-asks .price-row-wrapper.up.exiting {
      background-color: var(--calculated_trading_selling-added-bg); }
      .trade_orderbook-wrapper .trade_orderbook-asks .price-row-wrapper.up.entering .trade_orderbook-cell-price, .trade_orderbook-wrapper .trade_orderbook-asks .price-row-wrapper.up.exiting .trade_orderbook-cell-price {
        color: var(--labels_important-active-labels-text-graphics) !important; }
    .trade_orderbook-wrapper .trade_orderbook-asks .price-row-wrapper.down.entering, .trade_orderbook-wrapper .trade_orderbook-asks .price-row-wrapper.down.exiting {
      background-color: var(--calculated_trading_selling-subtracted-bg); }
      .trade_orderbook-wrapper .trade_orderbook-asks .price-row-wrapper.down.entering .trade_orderbook-cell-price, .trade_orderbook-wrapper .trade_orderbook-asks .price-row-wrapper.down.exiting .trade_orderbook-cell-price {
        color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .trade_orderbook-wrapper .trade_orderbook-asks > .price-row-wrapper:first-child {
    background-color: var(--calculated_trading_selling-orderbook-best-bar);
    font-weight: bold; }
    .trade_orderbook-wrapper .trade_orderbook-asks > .price-row-wrapper:first-child .trade_orderbook-cell-price {
      color: var(--calculated_trading-orderbook-text); }
    .trade_orderbook-wrapper .trade_orderbook-asks > .price-row-wrapper:first-child.down.entering, .trade_orderbook-wrapper .trade_orderbook-asks > .price-row-wrapper:first-child.down.exiting {
      background-color: var(--calculated_trading_selling-best-subtracted); }
  .trade_orderbook-wrapper .trade_orderbook-bids > .price-row-wrapper:first-child {
    background-color: var(--calculated_trading_buying-orderbook-best-bar);
    font-weight: bold; }
    .trade_orderbook-wrapper .trade_orderbook-bids > .price-row-wrapper:first-child .trade_orderbook-cell-price {
      color: var(--calculated_trading-orderbook-text); }
    .trade_orderbook-wrapper .trade_orderbook-bids > .price-row-wrapper:first-child.down.entering, .trade_orderbook-wrapper .trade_orderbook-bids > .price-row-wrapper:first-child.down.exiting {
      background-color: var(--calculated_trading_buying-best-subtracted); }
  .trade_orderbook-wrapper .trade_orderbook-bids {
    color: var(--trading_buying-related-elements); }
    .trade_orderbook-wrapper .trade_orderbook-bids .price-row-wrapper.up.entering, .trade_orderbook-wrapper .trade_orderbook-bids .price-row-wrapper.up.exiting {
      background-color: var(--calculated_trading_buying-added-bg); }
      .trade_orderbook-wrapper .trade_orderbook-bids .price-row-wrapper.up.entering .trade_orderbook-cell-price, .trade_orderbook-wrapper .trade_orderbook-bids .price-row-wrapper.up.exiting .trade_orderbook-cell-price {
        color: var(--labels_important-active-labels-text-graphics) !important; }
    .trade_orderbook-wrapper .trade_orderbook-bids .price-row-wrapper.down.entering, .trade_orderbook-wrapper .trade_orderbook-bids .price-row-wrapper.down.exiting {
      background-color: var(--calculated_trading_buying-subtracted-bg); }
      .trade_orderbook-wrapper .trade_orderbook-bids .price-row-wrapper.down.entering .trade_orderbook-cell-price, .trade_orderbook-wrapper .trade_orderbook-bids .price-row-wrapper.down.exiting .trade_orderbook-cell-price {
        color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .trade_orderbook-wrapper .trade_bids-limit_bar .orderbook-limit_bar-wrapper {
    color: var(--trading_buying-related-elements); }
    .trade_orderbook-wrapper .trade_bids-limit_bar .orderbook-limit_bar-wrapper .orderbook-limit_bar-line {
      background-color: var(--trading_buying-related-elements); }
  .trade_orderbook-wrapper .orderbook-limit_bar-wrapper .orderbook-limit_bar-line {
    height: 2px; }
  .trade_orderbook-wrapper .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
    font-size: 1.1rem; }

.trade-select-option-wrapper .ant-select-item,
.trade-select-option-wrapper .ant-select-item-option-content {
  font-size: 11px; }

.order-book-select-coin .ant-select-item,
.order-book-select-coin .ant-select-item-option {
  font-size: 0.8rem; }

.trade_orderbook-depth-selector {
  padding: 4px; }
  .trade_orderbook-depth-selector .ant-btn-text {
    color: var(--labels_important-active-labels-text-graphics); }
  .trade_orderbook-depth-selector .ant-btn-text[disabled] {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .trade_orderbook-depth-selector .ant-btn {
    padding: 0;
    height: 16px;
    margin: 4px 0;
    line-height: 1; }

.trade_orderbook-depth {
  padding: 4px;
  min-width: 40px;
  text-align: center; }

.direction_ltr .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
  padding-left: 0.5rem; }

.direction_rtl .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
  padding-right: 0.5rem; }

.layout-mobile .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-bids .value-row .trade_orderbook-cell {
  font-size: 1.5rem !important; }

.trade_orderbook-cell .ant-select-selector {
  color: var(--labels_important-active-labels-text-graphics) !important; }

.price-arrow {
  color: var(--labels_important-active-labels-text-graphics);
  transition: all 0.2s ease-out 0s;
  opacity: 0; }
  .price-arrow.up.entering, .price-arrow.up.exiting {
    color: var(--trading_buying-related-elements);
    opacity: 1; }
  .price-arrow.down.entering, .price-arrow.down.exiting {
    color: var(--trading_selling-related-elements);
    opacity: 1; }

.last-price {
  color: var(--labels_important-active-labels-text-graphics) !important;
  transition: all 0.2s ease-out 0s; }
  .last-price.up.entering, .last-price.up.exiting {
    color: var(--trading_buying-related-elements) !important; }
  .last-price.down.entering, .last-price.down.exiting {
    color: var(--trading_selling-related-elements) !important; }

.orderbook_ask_row-enter {
  opacity: 0;
  background-color: var(--calculated_trading_selling-added-bg) !important; }

.orderbook_ask_row-enter-active {
  opacity: 1;
  transition: all 0.5s ease-out 0s;
  background-color: var(--calculated_trading_selling-added-bg) !important; }

.orderbook_ask_row-enter-done {
  opacity: 1;
  transition: all 0.5s ease-out 0s;
  background-color: transparent; }

.orderbook_bid_row-enter {
  opacity: 0;
  background-color: var(--calculated_trading_buying-added-bg) !important; }

.orderbook_bid_row-enter-active {
  opacity: 1;
  transition: all 0.5s ease-out 0s;
  background-color: var(--calculated_trading_buying-added-bg) !important; }

.orderbook_bid_row-enter-done {
  opacity: 1;
  transition: all 0.5s ease-out 0s;
  background-color: transparent; }

.orderbook_ask_row-exit {
  opacity: 1;
  transition: all 0.5s ease-out 0s;
  background-color: var(--calculated_trading_selling-best-subtracted) !important; }

.orderbook_ask_row-exit-active {
  opacity: 0;
  transition: all 0.5s ease-out 0s;
  background-color: var(--calculated_trading_selling-best-subtracted) !important; }

.orderbook_bid_row-exit {
  opacity: 1;
  transition: all 0.5s ease-out 0s;
  background-color: var(--calculated_trading_selling-best-subtracted) !important; }

.orderbook_bid_row-exit-active {
  opacity: 0;
  transition: all 0.5s ease-out 0s;
  background-color: var(--calculated_trading_buying-best-subtracted) !important; }

.depth-chart__bids .highcharts-area {
  fill: var(--calculated_trading_buying-sparkline-fill) !important; }

.depth-chart__bids .highcharts-graph {
  stroke: var(--trading_buying-related-elements) !important; }

.depth-chart__asks .highcharts-area {
  fill: var(--calculated_trading_selling-sparkline-fill) !important; }

.depth-chart__asks .highcharts-graph {
  stroke: var(--trading_selling-related-elements) !important; }

.highcharts-root text {
  fill: var(--labels_secondary-inactive-label-text-graphics) !important;
  color: var(--labels_secondary-inactive-label-text-graphics) !important; }

.highcharts-grid-line {
  stroke: var(--calculated_secondary-border-1) !important; }

.highcharts-axis-line,
.highcharts-tick {
  stroke: var(--calculated_secondary-border) !important; }

.trade-history-record-enter {
  opacity: 0; }
  .trade-history-record-enter .trade_history-row.sell {
    color: var(--calculated_trading_selling-related-text) !important;
    background-color: var(--trading_selling-related-elements) !important; }
  .trade-history-record-enter .trade_history-row.buy {
    color: var(--calculated_trading_buying-related-text) !important;
    background-color: var(--trading_buying-related-elements) !important; }

.trade-history-record-enter-active {
  opacity: 1;
  transition: all 0.2s ease-in; }
  .trade-history-record-enter-active .trade_history-row.sell {
    color: var(--calculated_trading_selling-related-text) !important;
    background-color: var(--trading_selling-related-elements) !important; }
  .trade-history-record-enter-active .trade_history-row.buy {
    color: var(--calculated_trading_buying-related-text) !important;
    background-color: var(--trading_buying-related-elements) !important; }

.trade-history-record-enter-done .trade_history-row.sell {
  color: var(--trading_selling-related-elements);
  background-color: transparent;
  transition: all 1s ease-in; }

.trade-history-record-enter-done .trade_history-row.buy {
  color: var(--trading_buying-related-elements) !important;
  background-color: transparent;
  transition: all 1s ease-in; }

.ask,
.buy {
  color: var(--trading_selling-related-elements); }

.bid,
.sell {
  color: var(--trading_buying-related-elements); }

.trade_history-row-wrapper {
  margin: 1px 0; }

.trade_history-row {
  height: 100%; }
  .trade_history-row .trade_history-icon {
    width: 1rem;
    margin-right: 3px; }
  .trade_history-row.sell {
    color: var(--trading_selling-related-elements); }
    .trade_history-row.sell .trade_history-icon svg .up-icon,
    .trade_history-row.sell .trade_history-icon svg .down-icon {
      fill: var(--trading_selling-related-elements); }
    .trade_history-row.sell .trade_history-icon svg polygon {
      fill: var(--calculated_trading_selling-related-text); }
  .trade_history-row.buy {
    color: var(--trading_buying-related-elements); }
    .trade_history-row.buy .trade_history-icon svg .up-icon,
    .trade_history-row.buy .trade_history-icon svg .down-icon {
      fill: var(--trading_buying-related-elements); }
    .trade_history-row.buy .trade_history-icon svg polygon {
      fill: var(--calculated_trading_buying-related-text); }

.layout-mobile .trade_active_orders-wrapper {
  max-height: 35vh !important;
  white-space: nowrap; }
  .layout-mobile .trade_active_orders-wrapper .table_header-wrapper,
  .layout-mobile .trade_active_orders-wrapper .table_body-wrapper {
    font-size: 1rem !important; }

.trade_active-lg-view {
  max-height: 17vh; }

.trade_active_orders-wrapper {
  overflow-y: auto;
  flex: 1; }
  .trade_active_orders-wrapper .cell_box-type {
    padding: 0.1rem; }
    .trade_active_orders-wrapper .cell_box-type > div {
      margin: 0; }
    .trade_active_orders-wrapper .cell_box-type .buy {
      color: var(--calculated_trading_buying-related-text) !important;
      background-color: var(--trading_buying-related-elements); }
    .trade_active_orders-wrapper .cell_box-type .sell {
      color: var(--calculated_trading_selling-related-text) !important;
      background-color: var(--trading_selling-related-elements); }
    .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper {
      border: 1px solid var(--calculated_important-border);
      margin: 0;
      padding: 1px;
      min-width: 5rem;
      max-width: 7rem; }
      .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper {
        padding: 0 0.15rem;
        position: relative;
        width: 100%;
        color: var(--labels_secondary-inactive-label-text-graphics);
        z-index: 2; }
        .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper .cell_value-bar {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          background-color: var(--calculated_specials_highlight-box);
          z-index: -1; }
  .trade_active_orders-wrapper .recent-trades.cell_box-type .buy {
    min-width: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .trade_active_orders-wrapper .recent-trades.cell_box-type .sell {
    min-width: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }

.quick-trade-tab {
  border: 1px solid var(--calculated_secondary-border);
  border-radius: 9px;
  vertical-align: middle;
  text-align: center; }

.direction_ltr .trade_active_orders-wrapper .cell_box-type {
  padding-left: 0; }
  .direction_ltr .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper .cell_value-bar {
    left: 0; }

.direction_rtl .trade_active_orders-wrapper .cell_box-type {
  padding-right: 0; }
  .direction_rtl .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper .cell_value-bar {
    right: 0; }

.trade-tab__market-selector {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.75rem;
  font-family: "Raleway Extrabold"; }

.mobile-chart__market-selector-wrapper {
  font-family: 'Open Sans' !important;
  font-weight: normal; }

.mobile-market-selector-trigger {
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 3rem;
  max-height: 3rem; }

.public-history__header {
  font-size: 1rem !important; }
  .public-history__header .custom-select-input-style {
    font-size: 1rem !important;
    max-width: 8rem; }
    .public-history__header .custom-select-input-style .ant-select-selector {
      height: 1.5rem !important;
      line-height: 1.5rem !important;
      padding: 0;
      font-size: 1rem !important; }
    .public-history__header .custom-select-input-style .ant-select-selection-item {
      height: 1.5rem !important;
      line-height: 1.5rem !important;
      font-weight: bold; }
    .public-history__header .custom-select-input-style .ant-select-arrow {
      right: 2px; }

.mobile-tab_market-indicator {
  width: 2.5rem !important;
  margin: 0.3rem 0.3rem 0 0.3rem; }
  .mobile-tab_market-indicator svg .plus,
  .mobile-tab_market-indicator svg .minus {
    stroke: var(--labels_secondary-inactive-label-text-graphics) !important;
    stroke-width: 0.2rem; }
  .mobile-tab_market-indicator svg .st0 {
    fill: var(--calculated_base_top-bar-navigation_text);
    stroke-width: 0rem; }
  .mobile-tab_market-indicator svg .icon-check-1 {
    stroke: var(--calculated_base_top-bar-navigation_text); }

.general-record-enter {
  opacity: 0; }

.general-record-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in; }

.open-order-wrapper .trade__active-orders_cancel-All .action_notification-wrapper {
  position: relative; }

.open-order-wrapper .table_body-row td:nth-child(even) .action_notification-wrapper {
  margin-top: 0.75rem;
  padding-left: 0.25rem; }

.trade-details-wrapper {
  width: 100%;
  padding: 40px 65px 30px 55px;
  font-family: "Open Sans";
  background-color: var(--base_background); }
  .trade-details-wrapper .trade-details-content {
    height: 450px; }
    .trade-details-wrapper .trade-details-content .pb-24 {
      padding-bottom: 24px; }
    .trade-details-wrapper .trade-details-content .pb-30 {
      padding-bottom: 30px; }
    .trade-details-wrapper .trade-details-content .pb-35 {
      padding-bottom: 35px; }
    .trade-details-wrapper .trade-details-content .pl-6 {
      padding-left: 6rem; }
    .trade-details-wrapper .trade-details-content .chart {
      height: 100px;
      margin: 10px 0; }
      .trade-details-wrapper .trade-details-content .chart div:last-child {
        padding-top: 1rem; }
      .trade-details-wrapper .trade-details-content .chart .fade-area {
        position: relative; }
      .trade-details-wrapper .trade-details-content .chart .fade-area::before {
        content: '';
        position: absolute;
        height: 100px;
        width: 100%;
        background-image: linear-gradient(to right, var(--base_background), rgba(0, 0, 0, 0));
        z-index: -1; }
    .trade-details-wrapper .trade-details-content .trade_tabs-container {
      margin: 0 !important;
      width: 100% !important; }
    .trade-details-wrapper .trade-details-content .main-coin-wrapper {
      opacity: 0.3; }
  .trade-details-wrapper .f-size-16 {
    font-size: 16px;
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-weight: bold; }
  .trade-details-wrapper .f-size-22 {
    font-size: 22px;
    font-weight: bold; }
  .trade-details-wrapper .pairs {
    color: var(--specials_buttons-links-and-highlights);
    font-size: 16px; }
  .trade-details-wrapper .underline {
    text-decoration: underline; }
  .trade-details-wrapper .fullname {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 11px;
    white-space: nowrap; }
  .trade-details-wrapper .sub-title {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font: normal normal 300 11px/15px "Open Sans";
    letter-spacing: 2.2px;
    opacity: 1;
    white-space: nowrap; }
  .trade-details-wrapper .white-txt {
    color: var(--labels_important-active-labels-text-graphics); }
  .trade-details-wrapper .day-change-block {
    text-align: end;
    margin-bottom: 1.5rem; }
    .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper {
      background: transparent;
      color: var(--labels_secondary-inactive-label-text-graphics);
      border-color: var(--labels_secondary-inactive-label-text-graphics);
      height: 28px;
      padding: 0 12px;
      line-height: 24px; }
      .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper:first-child {
        border-radius: 0.5rem 0 0 0.5rem; }
      .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper:last-child {
        border-radius: 0 0.5rem 0.5rem 0; }
      .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper::before {
        background: #fff; }
    .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper-checked {
      border-color: #fff;
      color: #fff; }

.layout-mobile .trade-details-wrapper {
  width: 100%; }
  .layout-mobile .trade-details-wrapper .trade-details-content {
    width: 100%;
    height: 100%; }

@media (max-width: 1020px) {
  .trade-details-wrapper {
    width: 100%;
    padding: 15px; }
    .trade-details-wrapper .trade-details-content {
      width: 100%;
      height: 100%; } }

.quote-container {
  background-color: var(--base_background);
  overflow-y: scroll; }

.base_negative_balance,
.success-review {
  margin: 1rem 0;
  width: 30rem; }

.quote-review-wrapper {
  margin: 1rem 0;
  width: 40rem !important;
  position: relative; }
  .quote-review-wrapper .quote_header {
    font-size: 22px;
    font-weight: bold; }
  .quote-review-wrapper .quote_content {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .quote-review-wrapper .disabled-area {
    opacity: 0.3; }
  .quote-review-wrapper .quote_expiry_content {
    margin-top: 1rem; }
    .quote-review-wrapper .quote_expiry_content p {
      margin: 0; }
    .quote-review-wrapper .quote_expiry_content .clock-icon {
      margin-right: 0.75rem; }
    .quote-review-wrapper .quote_expiry_content.expired-content * {
      color: #fe9d00; }
  .quote-review-wrapper .expiry-progress {
    display: flex; }
    .quote-review-wrapper .expiry-progress .ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-small,
    .quote-review-wrapper .expiry-progress .ant-progress-outer {
      display: flex;
      border-radius: 0; }
    .quote-review-wrapper .expiry-progress .ant-progress-inner {
      background-color: transparent;
      border-radius: 0; }
    .quote-review-wrapper .expiry-progress .ant-progress-bg {
      border-radius: 0; }
  .quote-review-wrapper .expired-block {
    opacity: 0.25; }

.quote-countdown-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .quote-countdown-wrapper .counter {
    font-size: 2.6rem; }

.quote-success-review-text {
  margin: 1rem 0 2rem;
  font-size: 1.1rem;
  text-align: center; }

.quote-success__title {
  text-transform: uppercase;
  font-family: Raleway;
  font-weight: bold;
  font-size: 24px;
  text-align: center; }

.quick-trade__icon {
  height: 7rem;
  width: 7rem;
  margin-bottom: 2rem;
  margin-right: auto;
  margin-left: auto; }

.layout-mobile .quote-container {
  background-color: transparent;
  overflow-y: scroll;
  min-height: calc( 100vh - 17rem);
  max-height: calc( 100vh - 17rem); }
  .layout-mobile .quote-container .quick_trade-wrapper {
    flex: 1 1;
    min-width: 95vw !important;
    max-width: 100vw !important;
    margin: 10px;
    flex-direction: column;
    padding: 0;
    height: fit-content; }

.layout-mobile .base_negative_balance,
.layout-mobile .success-review,
.layout-mobile .quote-review-wrapper {
  margin: auto; }

.risky-trade-disclaimer .disclaimer-title {
  font-size: xx-large;
  text-align: center;
  margin: 2rem auto; }

.risky-trade-disclaimer .disclaimer-msg {
  font-size: small;
  margin: 2dvh auto; }

.risky-trade-disclaimer .extreme-volatility-msg {
  color: #FFAA00; }

.risky-trade-disclaimer .disclaimer-checkbox span {
  color: #fff;
  opacity: 0.7; }

.risky-trade-disclaimer .disclaimer-checkbox.disclaimer-checkbox-selected span {
  opacity: 1; }

.risky-trade-disclaimer .coin-icon-container {
  text-align: center; }

.contact_form-wrapper {
  min-width: 30rem; }
  .contact_form-wrapper .icon_title-wrapper .icon_title-text.title {
    text-transform: capitalize;
    margin: 1.5rem auto; }
  .contact_form-wrapper .icon_title-wrapper .icon_title-image {
    height: 6rem;
    width: 6rem; }
  .contact_form-wrapper .field-wrapper {
    margin: 1rem 0; }
  .contact_form-wrapper .buttons-wrapper {
    margin-top: 1.5rem; }

.layout-mobile .contact_form-wrapper {
  min-width: auto;
  flex: 1; }

.verification_container {
  margin: 0 auto;
  padding: 1rem; }
  .verification_container .icon_title-wrapper {
    flex-direction: row;
    justify-content: flex-start; }
  .verification_container .title-inactive {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .verification_container .verification-buttons-wrapper {
    width: 100%; }
    .verification_container .verification-buttons-wrapper .holla-button {
      width: 300px; }
  .verification_container .warning_text {
    margin: 1rem 0;
    padding: 0; }
  .verification_container .header-content {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 1.1rem; }
  .verification_container .content-text {
    font-size: 1.1rem; }
  .verification_container .document-note-icon {
    width: 3.5rem; }
  .verification_container .inner-content {
    border-radius: 4px;
    padding: 30px;
    margin: 30px 0px 70px;
    background-color: var(--base_wallet-sidebar-and-popup);
    padding-bottom: 20px; }
    .verification_container .inner-content .custom-tab-wrapper {
      margin: 0 0.5rem;
      overflow-x: auto; }
      .verification_container .inner-content .custom-tab-wrapper .tab_item {
        min-width: 9rem; }
    .verification_container .inner-content .btn-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center; }
      .verification_container .inner-content .btn-wrapper .holla-verification-button {
        position: absolute;
        top: 60px;
        width: 300px; }
        .verification_container .inner-content .btn-wrapper .holla-verification-button.static {
          position: static; }

.verification-form-panel {
  padding: 30px 50px;
  background-color: var(--base_wallet-sidebar-and-popup); }
  .verification-form-panel .action_notification-wrapper .notification-info {
    white-space: nowrap; }

.verification_content-form-wrapper {
  padding: 0.25rem; }

.account-pending-icon {
  width: 3rem; }

.verification_link {
  color: var(--specials_buttons-links-and-highlights); }

.verification-info-txt {
  width: 70%; }

.verification_document-sample {
  width: 65%; }

.header_title-wrapper .header_title-children .important_information {
  color: var(--labels_important-active-labels-text-graphics);
  font-weight: bold; }

.header_title-wrapper .header_title-children .header_title-list {
  padding: 0 1.5rem; }

.header_title-wrapper .header_title-children .header_title-content:not(:last-child) {
  margin-bottom: 0.75rem; }

.header_title-wrapper .id-warning {
  color: var(--specials_notifications-alerts-warnings); }

.layout-mobile .verification_container .verification-info-txt {
  width: 100%;
  font-size: 13px !important; }

.layout-mobile .verification_container .holla-verification-button {
  font-size: 13px !important;
  position: unset !important;
  top: 0 !important;
  width: 100% !important; }

.layout-mobile .verification_container .inner-content {
  padding: 5px 8px; }

.layout-mobile .verification_container .icon_title-wrapper {
  margin-top: 0; }
  .layout-mobile .verification_container .icon_title-wrapper .icon_title-text.title {
    font-size: 2.6rem; }

.layout-mobile .verification_container .verification_content-form-wrapper .verification-form-panel {
  padding: 5px 8px; }
  .layout-mobile .verification_container .verification_content-form-wrapper .verification-form-panel .field-wrapper .field-label-wrapper {
    flex-direction: column; }

.layout-mobile .verification_container .verification_content-form-wrapper .content-text {
  width: 100%; }

.layout-mobile .verification_container .input-box-field .input_field-input::placeholder {
  font-size: 10px; }

.layout-mobile .verification_container .verification-buttons-wrapper .holla-button {
  width: 100% !important;
  flex: 1; }

.layout-mobile .verification_container .separator {
  width: auto;
  margin: 1rem; }

.layout-mobile .mobile-bar-tab .bartab-text-wrapper {
  font-size: 1.5rem;
  line-height: 1.5rem; }

.layout-mobile .presentation_container.verification_container {
  padding-top: 0 !important;
  max-height: calc( 100vh - 10rem); }
  .layout-mobile .presentation_container.verification_container .header-content {
    font-size: 13px !important; }

.layout-mobile .header_title-wrapper .header_title-text {
  font-size: 15px; }

.layout-mobile .header_title-wrapper .header_title-children {
  font-size: 13px !important; }

.layout-mobile .field-wrapper .field-content .field-label,
.layout-mobile .field-wrapper .field-content .field-children,
.layout-mobile .field-wrapper .field-content .inner_container,
.layout-mobile .field-wrapper .field-content .input_field-input,
.layout-mobile .field-wrapper .field-content .placeholder {
  font-size: 13px !important; }

.layout-mobile .content-text {
  font-size: 13px; }

.direction_ltr .divider {
  margin-right: 0.5rem; }

.direction_rtl .divider {
  margin-left: 0.5rem; }

@media (max-width: 1060px) {
  .verification_container .inner-content .custom-tab-wrapper {
    overflow-x: auto; }
  .verification_container .verification-buttons-wrapper .holla-button {
    width: 80%; }
  .verification_container .verification_content-form-wrapper .verification-form-panel .field-wrapper .field-label {
    font-size: 11px; }
  .verification_container .verification_content-form-wrapper .verification-form-panel .field-wrapper .input-box-field .input_field-input::placeholder {
    font-size: 11px; } }

.help-wrapper {
  min-width: 30rem;
  max-width: 38rem; }
  .help-wrapper .icon_title-wrapper .icon_title-text.title {
    text-transform: capitalize;
    margin: 1.5rem auto; }
  .help-wrapper .icon_title-wrapper .icon_title-image {
    height: 6rem;
    width: 6rem; }
  .help-wrapper .field-wrapper {
    margin: 1rem 0; }
  .help-wrapper .buttons-wrapper {
    margin-top: 1.5rem; }
  .help-wrapper .text {
    font-size: 12px;
    flex: 6;
    margin-left: 1rem; }
  .help-wrapper .separator {
    width: 2rem; }
  .help-wrapper .help_icons {
    width: 3rem;
    height: 3rem; }

.layout-mobile .contact_form-wrapper {
  min-width: auto;
  flex: 1; }

.withdrawal-confirm-warpper .withdrawal-confirm-title {
  font-weight: normal; }

.withdrawal-confirm-warpper .withdrawal-confirm--image {
  width: 22rem; }

.withdrawal-confirm-warpper .withdrawal-confirm-option {
  background-color: var(--base_wallet-sidebar-and-popup);
  padding: 25px; }

.direction_ltr .summary-container .trader-account-icon {
  margin-right: 1rem; }

.direction_rtl .summary-container .trader-account-icon {
  margin-left: 1rem; }

.logout-wrapper {
  width: 32rem; }

.layout-mobile .digital-market-wrapper .link-container {
  font-size: 10px; }

.layout-mobile .fee-limits-wrapper {
  margin: 1rem auto 3rem; }
  .layout-mobile .fee-limits-wrapper .icon_title-wrapper .icon_title-svg {
    height: 12rem;
    width: 12rem; }
  .layout-mobile .fee-limits-wrapper .icon_title-wrapper .icon_title-svg svg {
    height: 12rem;
    width: 12rem; }

.layout-mobile .logout-wrapper {
  margin: 0 auto; }

.layout-mobile .confirm-text {
  font-size: 2rem; }

.layout-mobile .summary-container .trader-account-wrapper .summary-block_wrapper .trade-account-secondary-txt {
  width: 100%; }
  .layout-mobile .summary-container .trader-account-wrapper .summary-block_wrapper .trade-account-secondary-txt .account-details-content {
    font-size: 13px; }
  .layout-mobile .summary-container .trader-account-wrapper .summary-block_wrapper .trade-account-secondary-txt .trade-account-link {
    font-size: 13px; }

.layout-mobile .summary-container .summary-section_1 {
  height: auto !important; }

.layout-mobile .summary-container .summary-content-txt {
  font-size: 13px !important; }

.layout-mobile .summary-container .assets-wrapper .price-text {
  font-size: 13px !important; }

.layout-mobile .summary-container .assets-wrapper .donut-container {
  height: 30rem !important;
  width: 100%; }

.layout-mobile .summary-container .assets-wrapper .rounded-loading {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border: 1px solid #808080;
  background-color: var(--calculated_secondary-border) !important;
  animation: outer-round-anim 3s ease infinite; }

.layout-mobile .summary-container .assets-wrapper .inner-round {
  background-color: var(--base_wallet-sidebar-and-popup);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto;
  border: 1px solid #808080; }

.layout-mobile .summary-container .assets-wrapper .loading-wrapper {
  height: 16rem;
  flex-direction: column; }
  .layout-mobile .summary-container .assets-wrapper .loading-wrapper .d-none {
    display: none; }
  .layout-mobile .summary-container .assets-wrapper .loading-wrapper .loading-txt {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 5rem; }

.layout-mobile .summary-container .assets-wrapper .dot-flashing {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #34363a;
  color: #34363a;
  animation: dotFlashing 3s infinite; }

.layout-mobile .summary-container .account-details-wrapper .account-type-menu {
  height: auto !important; }

@keyframes outer-round-anim {
  0% {
    transform: scale(0.5, 0.5);
    background-color: var(--calculated_secondary-border);
    opacity: 0.5; }
  10% {
    background-color: var(--calculated_secondary-border);
    opacity: 0.3; }
  30% {
    background-color: var(--calculated_secondary-border);
    opacity: 0.2; }
  50% {
    transform: scale(1.5, 1.5);
    background-color: var(--calculated_secondary-border); }
  70% {
    background-color: var(--calculated_secondary-border);
    opacity: 0; }
  100% {
    transform: scale(1, 1);
    background-color: var(--calculated_secondary-border);
    opacity: 0; } }

@keyframes dotFlashing {
  0% {
    background-color: var(--calculated_secondary-border);
    opacity: 0.5; }
  50% {
    background-color: #34363a;
    opacity: 0.5; } }

.digital-market-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 5rem; }
  .digital-market-wrapper .market-wrapper {
    width: 70%; }
    .digital-market-wrapper .market-wrapper .gray-text {
      color: var(--labels_secondary-inactive-label-text-graphics); }
    .digital-market-wrapper .market-wrapper .header-container {
      padding-bottom: 1rem;
      border-bottom: 2px solid var(--calculated_important-border); }
      .digital-market-wrapper .market-wrapper .header-container .link-content {
        color: var(--specials_buttons-links-and-highlights);
        text-decoration: underline;
        cursor: pointer; }
      .digital-market-wrapper .market-wrapper .header-container .digital-assets-icon {
        display: flex !important;
        flex-direction: inherit !important; }
      .digital-market-wrapper .market-wrapper .header-container .icon_title-wrapper .icon_title-svg svg {
        width: 3.5rem !important;
        height: 3.5rem !important;
        margin-top: 1rem !important; }
      .digital-market-wrapper .market-wrapper .header-container .icon_title-wrapper .icon_title-svg {
        width: 3.5rem !important; }
    .digital-market-wrapper .market-wrapper .link-container {
      color: var(--specials_buttons-links-and-highlights);
      padding-top: 5px; }
      .digital-market-wrapper .market-wrapper .link-container .link-1,
      .digital-market-wrapper .market-wrapper .link-container .link-2 {
        border-right: 1px solid var(--calculated_footer_border);
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
      .digital-market-wrapper .market-wrapper .link-container .link-3 {
        padding-left: 0.5rem; }

.summary-container {
  height: inherit;
  max-width: 100%;
  margin: 1rem auto;
  width: 75rem; }
  .summary-container .icon_title-wrapper {
    justify-content: flex-start;
    flex-direction: row; }
  .summary-container .account-details-text {
    padding-top: 1rem;
    padding-bottom: 2rem; }
  .summary-container .summary-block_wrapper {
    color: var(--labels_secondary-inactive-label-text-graphics);
    margin: 11px 0;
    padding: 1.5rem 1rem 1rem 1.5rem;
    background-color: var(--base_wallet-sidebar-and-popup);
    border-radius: 3px;
    border: 1px solid var(--calculated_super-pale_label-text-graphics); }
    .summary-container .summary-block_wrapper .link-text {
      color: var(--specials_buttons-links-and-highlights);
      cursor: pointer;
      text-decoration: underline; }
    .summary-container .summary-block_wrapper .summary-block-title {
      color: var(--labels_secondary-inactive-label-text-graphics);
      font-size: 1.2rem;
      font-weight: bold; }
    .summary-container .summary-block_wrapper .summary-title-icon {
      width: 2rem; }
  .summary-container .summary-content-txt {
    font-size: 1.1rem; }
  .summary-container .trader-account-wrapper {
    margin-right: 1.5rem;
    width: 25rem; }
    .summary-container .trader-account-wrapper .trade-account-secondary-txt {
      margin: auto 0;
      width: 60%; }
  .summary-container .trader-acc-detail-icon {
    width: 8rem;
    margin-right: 1rem; }
    .summary-container .trader-acc-detail-icon svg .level_icon {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .summary-container .trader-account-icon {
    height: 4.5rem;
    width: 4.5rem;
    margin-right: 1rem; }
  .summary-container .trade-account-link {
    color: var(--specials_buttons-links-and-highlights);
    font-weight: bold; }
  .summary-container .summary-section_1 .assets-wrapper .rounded-loading {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border: 1px solid #808080;
    background-color: var(--calculated_secondary-border) !important;
    animation: outer-round-anim 3s ease infinite; }
  .summary-container .summary-section_1 .assets-wrapper .inner-round {
    background-color: var(--base_wallet-sidebar-and-popup);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto;
    border: 1px solid #808080; }
  .summary-container .summary-section_1 .assets-wrapper .donut-container {
    height: 20rem;
    width: 100%; }
  .summary-container .summary-section_1 .assets-wrapper .loading-wrapper {
    height: 16rem;
    flex-direction: column; }
    .summary-container .summary-section_1 .assets-wrapper .loading-wrapper .d-none {
      display: none; }
    .summary-container .summary-section_1 .assets-wrapper .loading-wrapper .loading-txt {
      text-align: center;
      margin-bottom: 1rem;
      margin-top: 5rem; }
  .summary-container .summary-section_1 .assets-wrapper .dot-flashing {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #34363a;
    color: #34363a;
    animation: dotFlashing 3s infinite; }
  .summary-container .requirements-content-text {
    margin: auto 0; }
  .summary-container .requirements-content {
    width: 11rem; }
  .summary-container .requirement-verified {
    color: var(--specials_checks-okay-done); }
    .summary-container .requirement-verified svg circle {
      fill: var(--specials_checks-okay-done); }
    .summary-container .requirement-verified svg path,
    .summary-container .requirement-verified svg polygon,
    .summary-container .requirement-verified svg polyline {
      fill: var(--calculated_specials_notifications-success-text); }
  .summary-container .requirement-not-verified {
    color: var(--specials_notifications-alerts-warnings); }
    .summary-container .requirement-not-verified svg circle {
      fill: var(--specials_notifications-alerts-warnings); }
    .summary-container .requirement-not-verified svg path,
    .summary-container .requirement-not-verified svg polygon,
    .summary-container .requirement-not-verified svg polyline {
      fill: var(--calculated_specials_notifications-alerts-text); }
  .summary-container .requirement-rejected {
    color: var(--specials_notifications-alerts-warnings); }
    .summary-container .requirement-rejected svg circle {
      fill: var(--specials_notifications-alerts-warnings); }
    .summary-container .requirement-rejected svg path,
    .summary-container .requirement-rejected svg polygon,
    .summary-container .requirement-rejected svg polyline {
      fill: var(--calculated_specials_notifications-alerts-text); }
  .summary-container .requirement-pending {
    color: var(--specials_pending-waiting-caution); }
    .summary-container .requirement-pending svg circle {
      fill: var(--specials_pending-waiting-caution); }
    .summary-container .requirement-pending svg path,
    .summary-container .requirement-pending svg polygon,
    .summary-container .requirement-pending svg polyline {
      fill: var(--calculated_specials_notifications-pending-text); }
  .summary-container .requirement-incomplete {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .summary-container .requirement-incomplete svg circle {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
    .summary-container .requirement-incomplete svg path,
    .summary-container .requirement-incomplete svg polygon,
    .summary-container .requirement-incomplete svg polyline {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .summary-container .requirement-wrapper {
    width: 50rem; }
    .summary-container .requirement-wrapper .requirement-doc-icon {
      width: 6rem;
      margin: 1rem 1rem 0.5rem 0; }
      .summary-container .requirement-wrapper .requirement-doc-icon svg .verification-doc {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
  .summary-container .requirement-status-warning {
    height: 1rem;
    width: 1rem;
    border-radius: 1.5rem;
    background-color: var(--specials_notifications-alerts-warnings);
    color: var(--calculated_specials_notifications-alerts-text);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold; }
  .summary-container .assets-wrapper {
    font-size: 0.8rem; }
    .summary-container .assets-wrapper .assets-description {
      width: 86%; }
    .summary-container .assets-wrapper .donut-container {
      height: 23rem;
      width: 100%; }
    .summary-container .assets-wrapper .donut-container-xht {
      height: 40rem; }
    .summary-container .assets-wrapper .price-content {
      padding: 0 0.5rem; }
    .summary-container .assets-wrapper .price-text {
      color: var(--labels_secondary-inactive-label-text-graphics);
      font-weight: bold; }
    .summary-container .assets-wrapper .total-assets {
      background: repeating-linear-gradient(-45deg, var(--base_background), var(--base_background) 2px, #cccbcb 2px, #cccbcb 4px);
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0.5rem;
      color: var(--labels_secondary-inactive-label-text-graphics); }
    .summary-container .assets-wrapper .asset-changes {
      background-color: var(--specials_buttons-links-and-highlights); }
    .summary-container .assets-wrapper .coin-price-container {
      height: 3.5rem; }
  .summary-container .asset_wrapper_width {
    width: 100%; }
  .summary-container .xht_asset_wrapper_width {
    width: 100% !important; }
  .summary-container .trading-volume-wrapper {
    width: 50rem; }
    .summary-container .trading-volume-wrapper .wave-auction-icon {
      width: 1.5rem; }
    .summary-container .trading-volume-wrapper .wave-action-phase {
      border-top: 1px solid var(--calculated_secondary-border); }
      .summary-container .trading-volume-wrapper .wave-action-phase .table-wrapper {
        margin: 0.5rem 0 2rem; }
  .summary-container .account-details-wrapper .account-type-container {
    width: 30%; }
  .summary-container .account-details-wrapper .mobile-account-type {
    width: 80%; }
  .summary-container .account-details-wrapper .account-type-icon {
    width: 4rem; }
  .summary-container .account-details-wrapper .account-type-menu {
    opacity: 0.65;
    border-top: 1px solid var(--calculated_secondary-border);
    color: var(--specials_buttons-links-and-highlights);
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase; }
    .summary-container .account-details-wrapper .account-type-menu .account-current {
      color: var(--labels_secondary-inactive-label-text-graphics);
      text-transform: lowercase; }
  .summary-container .account-details-wrapper .account-type-menu-active {
    opacity: 1 !important;
    border-top: 2px solid var(--labels_important-active-labels-text-graphics);
    border-bottom: 2px solid var(--labels_important-active-labels-text-graphics);
    color: var(--labels_important-active-labels-text-graphics);
    font-size: 1rem;
    text-transform: uppercase; }
    .summary-container .account-details-wrapper .account-type-menu-active .account-current {
      color: var(--labels_important-active-labels-text-graphics);
      text-transform: lowercase; }
    .summary-container .account-details-wrapper .account-type-menu-active .trader-account-icon svg .level_icon {
      fill: var(--labels_important-active-labels-text-graphics); }
  .summary-container .account-details-wrapper .account-type-menu-last-active {
    border-bottom: none !important; }
  .summary-container .account-details-wrapper .requirement-header {
    border-bottom: 1px solid var(--calculated_secondary-border);
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-weight: bold; }
  .summary-container .account-details-wrapper .status-header {
    width: 6rem; }
  .summary-container .price-diff-down {
    color: var(--trading_selling-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .summary-container .glance-price-diff-down.entering, .summary-container .glance-price-diff-down.exiting {
    color: var(--calculated_trading_selling-related-text);
    background-color: var(--trading_selling-related-elements); }
    .summary-container .glance-price-diff-down.entering.glance-trade-tab-price_diff_down:before, .summary-container .glance-price-diff-down.exiting.glance-trade-tab-price_diff_down:before {
      border-color: var(--calculated_trading_selling-related-text); }
  .summary-container .price-diff-up {
    color: var(--trading_buying-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .summary-container .glance-price-diff-up.entering, .summary-container .glance-price-diff-up.exiting {
    color: var(--calculated_trading_buying-related-text);
    background-color: var(--trading_buying-related-elements); }
    .summary-container .glance-price-diff-up.entering.glance-trade-tab-price_diff_up:before, .summary-container .glance-price-diff-up.exiting.glance-trade-tab-price_diff_up:before {
      border-color: var(--calculated_trading_buying-related-text); }
  .summary-container .trade-tab-price_diff_up {
    margin: auto 0; }
  .summary-container .trade-tab-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .summary-container .trade-tab-price_diff_down {
    margin: auto 0; }
  .summary-container .trade-tab-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .summary-container .trade_tabs-search-field {
    background-color: var(--base_wallet-sidebar-and-popup);
    color: var(--labels_inactive-button);
    margin: 0;
    padding-right: 0.5rem; }
    .summary-container .trade_tabs-search-field::placeholder {
      color: var(--labels_inactive-button); }
    .summary-container .trade_tabs-search-field .field-error-content {
      display: none; }
  .summary-container .trade_tabs-search-outline {
    background: var(--labels_secondary-inactive-label-text-graphics); }

.trader-wrapper-icon {
  width: 5rem;
  margin-right: 1rem; }
  .trader-wrapper-icon svg .level_icon,
  .trader-wrapper-icon svg .hap-icon {
    fill: var(--labels_secondary-inactive-label-text-graphics); }

.fee-limits-wrapper {
  width: 38rem; }
  .fee-limits-wrapper .fee-limits-link {
    color: var(--specials_buttons-links-and-highlights);
    cursor: pointer;
    font-weight: bold; }
  .fee-limits-wrapper .content-txt {
    font-size: 1rem;
    max-height: 70vh;
    overflow-y: auto; }
    .fee-limits-wrapper .content-txt .fee-reduction-container {
      border: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .content-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin: 1.5rem 0; }
  .fee-limits-wrapper .icon_title-wrapper {
    margin-top: 1rem; }
    .fee-limits-wrapper .icon_title-wrapper .icon_title-svg {
      height: 12rem;
      width: 12rem; }
    .fee-limits-wrapper .icon_title-wrapper .icon_title-svg svg {
      height: 12rem;
      width: 12rem; }
      .fee-limits-wrapper .icon_title-wrapper .icon_title-svg svg .level_icon {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
    .fee-limits-wrapper .icon_title-wrapper .icon_title-text {
      margin: 0; }
      .fee-limits-wrapper .icon_title-wrapper .icon_title-text.title {
        font-size: 2rem; }
  .fee-limits-wrapper table {
    border-collapse: collapse;
    border: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper td,
  .fee-limits-wrapper th {
    border: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .account-limits {
    margin: 1rem 0;
    width: 100%; }
  .fee-limits-wrapper .account-limits-content {
    border: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .account-limits-maker {
    border-left: 1px solid var(--calculated_secondary-border);
    border-right: 1px solid var(--calculated_secondary-border);
    border-top: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .account-limits-taker {
    border-bottom: 1px solid var(--calculated_secondary-border);
    border-left: 1px solid var(--calculated_secondary-border);
    border-right: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .account-limits-coin {
    border-bottom: 1px solid var(--calculated_secondary-border);
    padding: 0.25rem 0.5rem;
    width: 14rem;
    max-width: 14rem;
    overflow: scroll; }
  .fee-limits-wrapper .account-limits-status {
    padding: 0.25rem 0.5rem; }
  .fee-limits-wrapper .account-limits-value {
    padding: 0.25rem 1rem; }
  .fee-limits-wrapper .limit-head-currency {
    font-weight: normal !important;
    padding: 0.25rem 0.5rem; }
  .fee-limits-wrapper .limit-head {
    font-weight: normal !important;
    padding: 0.25rem 1rem; }

.markets-section-wrapper .market-coin {
  width: 3rem; }

.markets-section-wrapper .price-diff-down {
  color: var(--trading_selling-related-elements); }

.markets-section-wrapper .price-diff-up {
  color: var(--trading_buying-related-elements); }

.markets-section-wrapper .app-price_diff_up {
  margin-left: 0.5rem; }

.markets-section-wrapper .app-price_diff_up:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: none !important;
  border-left: 0.4rem solid transparent !important;
  border-right: 0.4rem solid transparent !important;
  border-bottom: 0.4rem solid var(--trading_buying-related-elements);
  margin-bottom: 0rem;
  display: inline-block;
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;
  position: relative;
  top: calc(50% - 0.6rem); }

.markets-section-wrapper .app-price_diff_down {
  margin-left: 0.5rem; }

.markets-section-wrapper .app-price_diff_down:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: none !important;
  border-left: 0.4rem solid transparent !important;
  border-right: 0.4rem solid transparent !important;
  border-top: 0.4rem solid var(--trading_selling-related-elements);
  margin-top: 0rem;
  display: inline-block;
  margin-right: 0.5rem;
  position: relative;
  top: calc(50% - 0.6rem); }

.user-status.verified {
  color: var(--specials_checks-okay-done); }

.user-status.pending {
  color: var(--specials_pending-waiting-caution); }

.user-status.incompleted {
  color: var(--specials_pending-waiting-caution); }

.user-status.rejected {
  color: var(--specials_notifications-alerts-warnings); }

@media (max-width: 920px) {
  .summary-container .summary-block_wrapper .summary-block-title,
  .summary-container .summary-block-secondaryTitle {
    font-size: 1.8rem; }
  .summary-container .summary-content-txt {
    font-size: 1.2rem !important; } }

@media (min-width: 1920px) {
  .summary-container {
    width: 100rem !important; }
    .summary-container .summary-block_wrapper .summary-block-title {
      font-size: 1.2rem; }
    .summary-container .summary-content-txt {
      font-size: 1rem; }
    .summary-container .asset_wrapper_width {
      width: 100% !important; }
    .summary-container .xht_asset_wrapper_width {
      width: 100% !important; }
    .summary-container .assets-wrapper {
      font-size: 1rem; }
    .summary-container .trader-account-wrapper {
      font-size: 1rem;
      width: 35% !important; }
    .summary-container .trading-volume-wrapper,
    .summary-container .requirement-wrapper {
      width: 65% !important; }
  .fee-limits-wrapper .content-txt {
    font-size: 1rem; } }

@media screen and (max-width: 1096px) {
  .summary-container .summary-content-txt {
    font-size: 0.9rem; }
  .summary-container .assets-wrapper,
  .summary-container .trader-account-wrapper {
    font-size: 0.9rem; }
  .fee-limits-wrapper .content-txt {
    font-size: 0.9rem; } }

.highcharts-graph {
  stroke: var(--trading_buying-related-elements); }
  .highcharts-graph.highcharts-negative {
    stroke: var(--trading_selling-related-elements); }

.highcharts-area {
  fill: var(--calculated_trading_buying-sparkline-fill); }
  .highcharts-area.highcharts-negative {
    fill: var(--calculated_trading_selling-sparkline-fill); }

.highcharts-point {
  stroke: var(--trading_buying-related-elements);
  fill: var(--trading_buying-related-elements); }
  .highcharts-point.highcharts-negative {
    stroke: var(--trading_selling-related-elements);
    fill: var(--trading_selling-related-elements); }

.highcharts-background {
  fill: transparent; }

.highcharts-credits {
  display: none; }

.highcharts-container {
  width: 100% !important; }

.highcharts-root {
  width: 100% !important; }

.layout-mobile .highcharts-graph {
  stroke-width: 1px; }

.market-list__container .market-list__block .market-list__block-table {
  width: 100%;
  font-size: 1rem;
  margin-bottom: 3rem; }
  .market-list__container .market-list__block .market-list__block-table .loading-anime {
    height: 20px;
    width: 100px;
    border-radius: 7px;
    animation: mymove 3s infinite; }
  .market-list__container .market-list__block .market-list__block-table thead {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .market-list__container .market-list__block .market-list__block-table thead tr {
      height: 3.5rem;
      vertical-align: bottom; }
  .market-list__container .market-list__block .market-list__block-table .table-row {
    color: var(--labels_secondary-inactive-label-text-graphics);
    height: 2.5rem;
    cursor: pointer; }
    .market-list__container .market-list__block .market-list__block-table .table-row:hover {
      background-color: var(--base_secondary-navigation-bar);
      color: var(--labels_secondary-inactive-label-text-graphics); }
    .market-list__container .market-list__block .market-list__block-table .table-row .icon-container .squar-box {
      width: 15px;
      height: 15px;
      border: 1px solid;
      color: var(--labels_secondary-inactive-label-text-graphics); }
    .market-list__container .market-list__block .market-list__block-table .table-row .icon-container .circle-icon {
      width: 18px;
      height: 18px;
      border: 1px solid;
      color: var(--labels_secondary-inactive-label-text-graphics);
      border-radius: 10px; }
    .market-list__container .market-list__block .market-list__block-table .table-row .icon-container .triangle-icon {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid; }
    .market-list__container .market-list__block .market-list__block-table .table-row .td-chart {
      width: 150px;
      height: 50px; }
      .market-list__container .market-list__block .market-list__block-table .table-row .td-chart .highcharts-container {
        transform: scale(2, 2); }
        .market-list__container .market-list__block .market-list__block-table .table-row .td-chart .highcharts-container svg {
          padding-top: 3px; }
          .market-list__container .market-list__block .market-list__block-table .table-row .td-chart .highcharts-container svg .highcharts-graph {
            stroke-width: 0.6px !important; }
  .market-list__container .market-list__block .market-list__block-table .table-bottom-border td,
  .market-list__container .market-list__block .market-list__block-table th {
    position: relative;
    border-bottom: 1px solid var(--calculated_secondary-border);
    padding: 0 0.25rem; }
  .market-list__container .market-list__block .market-list__block-table .td-fit {
    width: 1%;
    white-space: nowrap; }
  .market-list__container .market-list__block .market-list__block-table .td-name {
    min-width: 200px !important; }
  .market-list__container .market-list__block .market-list__block-table .table-icon > div {
    vertical-align: middle;
    margin: auto; }
  .market-list__container .market-list__block .market-list__block-table .td-amount {
    min-width: 150px !important;
    direction: ltr; }

.market-list__container .market-list__block .market-list__caret span:not(.important-text) {
  opacity: 0.3; }

.market-list__container .market-list__block .market-list__caret .important-text {
  opacity: 0.8; }

.direction_ltr .market-list__container .market-list__block .market-list__block-table th {
  font-family: "Raleway";
  vertical-align: middle;
  padding: 0.5rem; }

.direction_ltr .market-list__container .market-list__block .market-list__block-table th,
.direction_ltr .market-list__container .market-list__block .market-list__block-table td {
  text-align: left; }

.direction_ltr .market-list__container .market-list__block .market-list__block-table .align-opposite {
  text-align: right; }

.direction_ltr .market-list__container .market-list__block .market-list__block-table .table-icon {
  padding-right: 0.5rem; }

.direction_ltr .market-list__container .market-list__block .market-list__block-table .loading-anime {
  height: 20px;
  width: 100px;
  border-radius: 7px;
  animation: mymove 3s infinite; }

.direction_rtl .market-list__container .market-list__block .market-list__block-table th,
.direction_rtl .market-list__container .market-list__block .market-list__block-table td {
  text-align: right; }

.direction_rtl .market-list__container .market-list__block .market-list__block-table .align-opposite {
  text-align: left; }

.direction_rtl .market-list__container .market-list__block .market-list__block-table .table-icon {
  padding-left: 0.5rem; }

.layout-mobile .market-list__container .market-list__block {
  overflow-x: auto;
  position: relative; }
  .layout-mobile .market-list__container .market-list__block .market-list__block-table thead {
    font-size: 1.3rem !important; }
  .layout-mobile .market-list__container .market-list__block .market-list__block-table .table-row {
    font-size: 1.3rem !important; }
    .layout-mobile .market-list__container .market-list__block .market-list__block-table .table-row td {
      padding: 0.5rem;
      min-width: 10rem; }
    .layout-mobile .market-list__container .market-list__block .market-list__block-table .table-row .td-chart {
      min-width: 15rem;
      padding: 0 !important; }
  .layout-mobile .market-list__container .market-list__block .market-list__block-table .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: var(--base_wallet-sidebar-and-popup);
    left: 0;
    width: 12rem !important;
    min-width: 12rem !important;
    max-width: 12rem !important;
    z-index: 1; }
  .layout-mobile .market-list__container .market-list__block .market-list__block-table .loading-anime {
    height: 20px;
    width: 100px;
    border-radius: 7px;
    animation: mymove 3s infinite; }

.layout-mobile .trade_tabs-container .sticky-col {
  background-color: var(--base_background) !important; }

.trade_tabs-container {
  color: var(--labels_secondary-inactive-label-text-graphics);
  height: inherit;
  max-width: 100%;
  margin: 3rem auto;
  width: 75rem; }
  .trade_tabs-container .app-icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 5rem !important;
    margin: 1rem auto;
    width: 8rem; }
  .trade_tabs-container .trade-tab-app-title {
    font-family: "Raleway Light";
    letter-spacing: 0.3rem;
    font-size: 0.8rem; }
  .trade_tabs-container .trade_tabs-content {
    border-top: 2px solid var(--calculated_important-border);
    font-size: 0.8rem; }
    .trade_tabs-container .trade_tabs-content .toggle_button-wrapper .toggle-content .toggle-action_button {
      width: 42px !important; }
      .trade_tabs-container .trade_tabs-content .toggle_button-wrapper .toggle-content .toggle-action_button.left {
        opacity: 1 !important; }
        .trade_tabs-container .trade_tabs-content .toggle_button-wrapper .toggle-content .toggle-action_button.left .toggle-action_button-display {
          right: calc(100% - 18px) !important; }
      .trade_tabs-container .trade_tabs-content .toggle_button-wrapper .toggle-content .toggle-action_button.right .toggle-action_button-display {
        right: 0 !important; }
  .trade_tabs-container .trade_tabs-link {
    color: var(--specials_buttons-links-and-highlights);
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    text-transform: uppercase; }
    .trade_tabs-container .trade_tabs-link:hover {
      opacity: 0.5; }
  .trade_tabs-container .link-separator {
    border-right: 1px solid var(--calculated_secondary-border); }
  .trade_tabs-container .trade_tabs-search-field {
    background-color: var(--base_background);
    color: var(--labels_inactive-button);
    margin: 0;
    padding-right: 0.5rem; }
    .trade_tabs-container .trade_tabs-search-field::placeholder {
      color: var(--labels_inactive-button); }
  .trade_tabs-container .trade_tabs-search-outline {
    background: var(--labels_secondary-inactive-label-text-graphics); }
  .trade_tabs-container .tabs-pair-details {
    font-size: 1.2rem;
    width: 32rem; }
  .trade_tabs-container .trade_tab-pair-title {
    color: var(--labels_important-active-labels-text-graphics);
    font-size: 1.1rem;
    font-weight: bold; }
  .trade_tabs-container .trade_tab-pair-sub-title {
    font-size: 1rem; }
  .trade_tabs-container .trade_tab-pair-volume {
    font-size: 1rem;
    text-align: right;
    padding-right: 0.3rem; }
  .trade_tabs-container .trade_tab-pair-price {
    color: var(--labels_important-active-labels-text-graphics);
    font-size: 1.8rem;
    font-weight: bold; }
  .trade_tabs-container .price-diff-down {
    color: var(--trading_selling-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .trade_tabs-container .glance-price-diff-down.entering, .trade_tabs-container .glance-price-diff-down.exiting {
    color: var(--calculated_trading_selling-related-text);
    background-color: var(--trading_selling-related-elements); }
    .trade_tabs-container .glance-price-diff-down.entering.glance-trade-tab-price_diff_down:before, .trade_tabs-container .glance-price-diff-down.exiting.glance-trade-tab-price_diff_down:before {
      border-color: var(--calculated_trading_selling-related-text); }
  .trade_tabs-container .price-diff-up {
    color: var(--trading_buying-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .trade_tabs-container .glance-price-diff-up.entering, .trade_tabs-container .glance-price-diff-up.exiting {
    color: var(--calculated_trading_buying-related-text);
    background-color: var(--trading_buying-related-elements); }
    .trade_tabs-container .glance-price-diff-up.entering.glance-trade-tab-price_diff_up:before, .trade_tabs-container .glance-price-diff-up.exiting.glance-trade-tab-price_diff_up:before {
      border-color: var(--calculated_trading_buying-related-text); }
  .trade_tabs-container .trade-tab-price_diff_up {
    margin: auto 0; }
  .trade_tabs-container .trade-tab-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .trade_tabs-container .trade-tab-price_diff_down {
    margin: auto 0; }
  .trade_tabs-container .trade-tab-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .trade_tabs-container .trade-tab-list {
    background-image: linear-gradient(var(--base_background), var(--calculated_market-card_gradient_background));
    border: 1px solid var(--calculated_super-pale_label-text-graphics);
    border-radius: 0.5rem;
    padding: 1rem 0 0;
    margin: 2rem 1.5rem;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16); }
    .trade_tabs-container .trade-tab-list:hover {
      border: 1px solid var(--calculated_secondary-border);
      color: var(--labels_secondary-inactive-label-text-graphics);
      position: relative;
      bottom: 0.5rem;
      right: 0.5rem; }
  .trade_tabs-container .market-card__sparkline-wrapper {
    padding-top: 10px;
    height: 12rem; }
  .trade_tabs-container .loading-anime {
    height: 20px;
    width: 100px;
    border-radius: 7px;
    animation: mymove 3s infinite; }

.layout-mobile .trade_tabs-container {
  overflow-y: scroll; }

@media screen and (max-width: 1096px) {
  .layout-mobile .trade_tabs-container .trade-tab-list {
    margin: 1.5rem 1rem; }
  .layout-mobile .trade_tabs-container .tabs-pair-details {
    font-size: 1rem;
    width: 28rem; }
  .layout-mobile .trade_tabs-container .market-card__sparkline-wrapper {
    height: 10rem; } }

@media screen and (max-width: 575px) {
  .layout-mobile .trade_tabs-container {
    overflow-y: scroll; }
    .layout-mobile .trade_tabs-container .trade-tab-list {
      margin: 1.5rem 1rem; }
    .layout-mobile .trade_tabs-container .tabs-pair-details {
      font-size: 1.5rem;
      width: 34rem;
      max-width: 96%; }
    .layout-mobile .trade_tabs-container .market-card__sparkline-wrapper {
      height: 12rem; } }

@media screen and (max-width: 1096px) {
  .trade_tabs-container .trade-tab-list {
    margin: 1.5rem 1rem; }
  .trade_tabs-container .tabs-pair-details {
    font-size: 1rem;
    width: 26rem; }
  .trade_tabs-container .market-card__sparkline-wrapper {
    height: 9rem; } }

@media screen and (max-width: 575px) {
  .trade_tabs-container .tabs-pair-details {
    font-size: 1rem;
    width: 20.5rem; }
  .trade_tabs-container .market-card__sparkline-wrapper {
    height: 7rem; } }

.toggle-wrapper {
  border-top: 1px solid var(--calculated_secondary-border);
  border-bottom: 1px solid var(--calculated_secondary-border); }

.toggle-wrapper-all {
  border-bottom: 2px solid var(--calculated_secondary-border);
  margin-bottom: 3rem !important; }

.portfolio-wrapper {
  width: 32rem; }
  .portfolio-wrapper .icon_title-wrapper .icon_title-svg {
    height: 10rem;
    width: 10rem; }
  .portfolio-wrapper .icon_title-wrapper .icon_title-svg svg {
    height: 10rem;
    width: 10rem; }
  .portfolio-wrapper .icon_title-wrapper .icon_title-text.title {
    font-size: 2rem; }
  .portfolio-wrapper .icon_title-wrapper .icon_title-text {
    margin-top: 3rem;
    text-align: left !important;
    width: 100%; }

.deactive_risk_data {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.settings_container .icon_title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.settings_container .header_title-wrapper .action_notification-wrapper {
  top: 30px; }

.settings_container .settings-form-wrapper .settings-form {
  padding: 10px 30px 30px 30px;
  border-radius: 4px;
  margin: 30px 0px;
  background-color: var(--base_wallet-sidebar-and-popup); }
  .settings_container .settings-form-wrapper .settings-form .notification-icon {
    width: 50px; }
  .settings_container .settings-form-wrapper .settings-form .icon_title-wrapper {
    margin: 0;
    flex-direction: row;
    justify-content: flex-start; }
    .settings_container .settings-form-wrapper .settings-form .icon_title-wrapper .icon_title-svg svg {
      width: 50px; }
    .settings_container .settings-form-wrapper .settings-form .icon_title-wrapper .icon_title-text {
      font-size: 15px;
      margin: 0; }

.settings_container .settings-form-wrapper .holla-button {
  width: 300px; }

.layout-mobile .portfolio-wrapper {
  margin: 25% auto; }

.layout-mobile .settings_container .header_title-wrapper .action_notification-wrapper {
  top: 0; }

.layout-mobile .settings_container .settings-form-wrapper .settings-form {
  margin: 15px 0 25px !important;
  padding: 10px; }
  .layout-mobile .settings_container .settings-form-wrapper .settings-form .field-wrapper .field-label-wrapper {
    flex-direction: column; }

@media (max-width: 1060px) {
  .settings_container .settings-form-wrapper .settings-form .icon_title-wrapper .icon_title-text {
    margin: 1rem;
    margin-bottom: 0; }
  .settings_container .settings-form-wrapper .settings-form .field-wrapper .field-label {
    font-size: 12px; }
  .settings_container .tab_controller-wrapper .tab_item {
    font-size: 13px; } }

.expired_exchange_wrapper {
  padding: 2rem; }
  .expired_exchange_wrapper .flex-cloumn {
    flex-direction: column; }
  .expired_exchange_wrapper .expired_img_icon {
    width: 7rem;
    height: 3.7rem;
    margin-top: 15rem; }
    .expired_exchange_wrapper .expired_img_icon svg .expired_icon {
      fill: #777677; }
  .expired_exchange_wrapper .expired_text {
    font-size: 2rem;
    font-weight: bold; }
  .expired_exchange_wrapper .expired_button {
    padding: 2rem 3rem; }

.terms_wrapper {
  width: 40rem; }
  .terms_wrapper .title_wrapper {
    font-size: 2rem;
    font-weight: bold; }
  .terms_wrapper .agreement_wrapper {
    font-size: 1rem;
    background-color: var(--calculated_specials_highlight-box);
    height: 32rem;
    overflow-y: scroll; }
  .terms_wrapper .download-wrapper {
    color: var(--specials_buttons-links-and-highlights);
    display: flex;
    align-items: center;
    width: fit-content; }
    .terms_wrapper .download-wrapper .download_pdf-svg > div {
      display: flex;
      align-items: center; }
    .terms_wrapper .download-wrapper .download_pdf-svg svg {
      width: 1rem;
      height: 1rem; }
  .terms_wrapper .checkfield-wrapper {
    margin: 0.5rem 0; }

.deposit_funds-wrapper {
  width: 42rem; }
  .deposit_funds-wrapper .funds-svg svg {
    width: 2rem;
    height: 2rem; }
  .deposit_funds-wrapper .icon_title-wrapper .icon_title-svg {
    width: 8rem;
    height: 8rem; }
    .deposit_funds-wrapper .icon_title-wrapper .icon_title-svg svg {
      width: 8rem;
      height: 8rem; }

.layout-mobile.home_container-mobile .app-icon {
  margin: 1rem auto;
  width: 15rem; }

.layout-mobile.home_container-mobile .trade-tab-app-title {
  font-family: 'Raleway Light';
  letter-spacing: 0.3rem;
  font-size: 1.2rem; }

.layout-mobile.home_container-mobile .market-button {
  background-color: #ffffff !important;
  border: 1px solid var(--calculated_secondary-border);
  color: var(--labels_secondary-inactive-label-text-graphics); }

.layout-mobile.home_container-mobile .post-wrapper {
  max-height: 45rem;
  overflow-y: auto;
  border-top: 0.4rem solid var(--calculated_secondary-border) !important; }
  .layout-mobile.home_container-mobile .post-wrapper .post-title {
    font-size: 1.75rem;
    font-weight: bold;
    text-transform: uppercase; }
  .layout-mobile.home_container-mobile .post-wrapper .trade_post_icon {
    width: 2rem; }
  .layout-mobile.home_container-mobile .post-wrapper .post_header {
    font-weight: bold;
    font-size: 12px; }
  .layout-mobile.home_container-mobile .post-wrapper .post-content {
    font-size: 12px; }
  .layout-mobile.home_container-mobile .post-wrapper .trade_announcement {
    font-size: 1.5rem; }

.layout-mobile.home_container-mobile .holla-button {
  font-size: 1.2rem; }

.init-container {
  flex: 1;
  height: 100%; }
  .init-container input {
    border: 1px solid #c4c4c4; }
  .init-container input:focus {
    border: 1px solid #ffffff;
    outline: 1px solid #ffffff; }
  .init-container .message {
    color: #ffffff;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    padding: 3px; }
  .init-container .success {
    background-color: #288500; }
  .init-container .error {
    background-color: #ff0000; }
  .init-container .header {
    font-size: 40px;
    font-weight: bold;
    margin: 1rem 0rem; }
  .init-container .description {
    font-size: 16px; }
    .init-container .description .desc-warning {
      color: #f28041; }
  .init-container .btn-container {
    margin: 2rem 0rem; }
  .init-container .ant-btn {
    background-color: #c4c4c4;
    color: #0000ff;
    font-size: 16px;
    font-weight: bold;
    width: 100%; }
  .init-container .loading_container {
    align-items: center;
    background-color: var(--labels_secondary-inactive-label-text-graphics);
    display: flex;
    height: 100%;
    justify-content: center; }
    .init-container .loading_container .loading-content {
      color: #ffffff; }
    .init-container .loading_container .delayed-show {
      visibility: hidden;
      animation: 10s ease forwards delayedShow; }
      .init-container .loading_container .delayed-show.dot-1 {
        animation-delay: 0.5s; }
      .init-container .loading_container .delayed-show.dot-2 {
        animation-delay: 1s; }
      .init-container .loading_container .delayed-show.dot-3 {
        animation-delay: 1.5s; }

@keyframes delayedShow {
  from {
    visibility: hidden; }
  to {
    visibility: visible; } }
  .init-container .setup-container,
  .init-container .welcome-screen {
    align-items: center;
    background-color: #0808df;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column; }
  .init-container .welcome-screen .footer {
    color: #c4c4c4;
    font-size: 10px;
    text-align: center;
    animation-name: content-animate;
    animation-duration: 0.1s;
    position: absolute;
    bottom: 10px; }
    .init-container .welcome-screen .footer a {
      text-decoration-line: underline;
      font-weight: bold; }
  .init-container .welcome-screen .content {
    color: #c4c4c4;
    text-align: center;
    width: 70rem; }
    .init-container .welcome-screen .content .logo {
      height: 4rem;
      width: 4rem;
      margin: auto;
      position: relative;
      animation-name: image-animate;
      animation-duration: 2s;
      z-index: 2; }
    .init-container .welcome-screen .content .logo-box {
      width: 6rem;
      height: 6rem; }

@keyframes image-animate {
  0% {
    background-color: #c4c4c4;
    top: 0rem;
    width: 50rem;
    height: 50rem; }
  25% {
    background-color: #c4c4c4;
    top: 0rem;
    width: 4rem;
    height: 4rem; }
  50% {
    background-color: #c4c4c4;
    top: 0rem; }
  75% {
    background-color: #c4c4c4;
    top: 10rem; }
  100% {
    background-color: #c4c4c4;
    top: 0rem; } }
    .init-container .welcome-screen .content .body-content {
      animation-name: content-animate;
      animation-duration: 2s;
      overflow-y: auto; }

@keyframes content-animate {
  0% {
    height: 0rem; }
  25% {
    height: 0rem; }
  50% {
    height: 0rem; }
  75% {
    height: 0rem; }
  100% {
    height: 25rem; } }
    .init-container .welcome-screen .content .ant-btn {
      width: 22rem; }
  .init-container .setup-container .info-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .init-container .setup-container .email-icon {
    width: 10rem; }
  .init-container .setup-container .map-icon {
    width: 15rem;
    opacity: 0.6; }
  .init-container .setup-container .wrapper {
    align-items: center;
    color: #c4c4c4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 70rem; }
  .init-container .setup-container .setup-field-wrapper {
    margin: 3rem 0rem 1rem 0rem; }
  .init-container .setup-container .setup-field-content {
    text-align: left;
    width: 22rem; }
    .init-container .setup-container .setup-field-content .input_field label {
      font-size: 15px;
      color: #ffffff; }
    .init-container .setup-container .setup-field-content .ant-input-affix-wrapper,
    .init-container .setup-container .setup-field-content .ant-input,
    .init-container .setup-container .setup-field-content .ant-input-number {
      background-color: #0000ff;
      color: #c4c4c4; }
      .init-container .setup-container .setup-field-content .ant-input-affix-wrapper:hover, .init-container .setup-container .setup-field-content .ant-input-affix-wrapper:focus,
      .init-container .setup-container .setup-field-content .ant-input:hover,
      .init-container .setup-container .setup-field-content .ant-input:focus,
      .init-container .setup-container .setup-field-content .ant-input-number:hover,
      .init-container .setup-container .setup-field-content .ant-input-number:focus {
        border-color: #c4c4c4;
        box-shadow: #c4c4c4; }
      .init-container .setup-container .setup-field-content .ant-input-affix-wrapper .ant-input-number-handler-wrap,
      .init-container .setup-container .setup-field-content .ant-input .ant-input-number-handler-wrap,
      .init-container .setup-container .setup-field-content .ant-input-number .ant-input-number-handler-wrap {
        display: none; }
    .init-container .setup-container .setup-field-content .ant-input-password-icon {
      color: #c4c4c4; }
  .init-container .setup-container .setup-field-label {
    font-size: 15px;
    color: #ffffff;
    margin-bottom: 0.25rem; }
  .init-container .setup-container .btn-wrapper {
    display: flex; }
    .init-container .setup-container .btn-wrapper .separator {
      margin: 0.5rem; }
    .init-container .setup-container .btn-wrapper .ant-btn {
      flex: 1; }

@media screen and (max-width: 1096px) and (min-width: 768px) {
  .init-container .setup-container .setup-field-wrapper .captcha-wrapper {
    height: 5.5rem; } }

@media screen and (max-width: 334px) and (min-width: 280px) {
  .init-container .setup-container .setup-field-wrapper .captcha-wrapper {
    height: 7rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    transform-origin: 0 0;
    -webkit-transform-origin: 0% 0%; } }

@media screen and (max-width: 460px) and (min-width: 335px) {
  .init-container .setup-container .setup-field-wrapper .captcha-wrapper {
    height: 7rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.62);
    -webkit-transform: scale(0.62);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

.confirm-change-pwd-wrapper .auth_wrapper .mdc-button--raised:not(:disabled):not(.mdc-ripple-upgraded),
.confirm-change-pwd-wrapper .auth_wrapper .mdc-button--unelevated:not(:disabled):not(.mdc-ripple-upgraded) {
  margin-top: 2rem !important; }

.confirm-change-pwd-wrapper .auth_wrapper .button-margin {
  margin-top: 3rem !important; }

.stake-btn.ant-btn-primary {
  color: var(--specials_buttons-links-and-highlights);
  border-color: var(--specials_buttons-links-and-highlights); }
  .stake-btn.ant-btn-primary:focus {
    color: var(--specials_buttons-links-and-highlights);
    border-color: var(--specials_buttons-links-and-highlights); }
  .stake-btn.ant-btn-primary:hover, .stake-btn.ant-btn-primary:active {
    color: var(--labels_important-active-labels-text-graphics);
    border-color: var(--labels_secondary-inactive-label-text-graphics); }

.stake-btn.ant-btn-dangerous {
  color: var(--specials_pending-waiting-caution);
  border-color: var(--specials_pending-waiting-caution); }
  .stake-btn.ant-btn-dangerous:focus {
    color: var(--specials_pending-waiting-caution);
    border-color: var(--specials_pending-waiting-caution); }
  .stake-btn.ant-btn-dangerous:hover, .stake-btn.ant-btn-dangerous:active {
    color: var(--labels_important-active-labels-text-graphics);
    border-color: var(--labels_secondary-inactive-label-text-graphics); }

.stake-btn.ant-btn-primary[disabled] {
  color: var(--calculated_notifications-blue-disabled);
  border-color: var(--calculated_notifications-blue-disabled); }
  .stake-btn.ant-btn-primary[disabled]:hover, .stake-btn.ant-btn-primary[disabled]:active, .stake-btn.ant-btn-primary[disabled]:focus {
    color: var(--calculated_notifications-blue-disabled);
    border-color: var(--calculated_notifications-blue-disabled); }

.stake-btn.ant-btn-dangerous[disabled] {
  color: var(--calculated_notification-pending-disabled);
  border-color: var(--calculated_notification-pending-disabled); }
  .stake-btn.ant-btn-dangerous[disabled]:hover, .stake-btn.ant-btn-dangerous[disabled]:active, .stake-btn.ant-btn-dangerous[disabled]:focus {
    color: var(--calculated_notification-pending-disabled);
    border-color: var(--calculated_notification-pending-disabled); }

.stake-amount-input.ant-input, .stake-amount-input.ant-input-affix-wrapper {
  background-color: var(--labels_fields);
  border: 1px solid var(--calculated_secondary-border);
  color: var(--labels_important-active-labels-text-graphics); }

.stake-amount-input.ant-input-affix-wrapper .ant-input {
  background-color: var(--labels_fields);
  color: var(--labels_important-active-labels-text-graphics); }

.stake-period-group.ant-radio-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly; }

.stake-period-button.ant-radio-button-wrapper {
  margin: 1rem;
  padding: 0.2rem;
  color: var(--labels_important-active-labels-text-graphics);
  background-color: transparent;
  background-size: 100%;
  background-repeat: repeat-y;
  height: 9rem;
  width: 9rem;
  border: 1px solid var(--labels_secondary-inactive-label-text-graphics);
  font-size: 0.9rem;
  line-height: 1.2rem;
  text-align: center;
  opacity: 0.5; }
  .stake-period-button.ant-radio-button-wrapper .stake-period-text {
    width: 9rem;
    margin: auto; }
  .stake-period-button.ant-radio-button-wrapper .period-x {
    font: italic normal 900 52px/5px Neue Haas Grotesk Display Pro;
    line-height: 5rem;
    letter-spacing: 0;
    color: var(--labels_important-active-labels-text-graphics); }
  .stake-period-button.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent; }

.stake-period-button.ant-radio-button-wrapper-checked {
  height: 10rem;
  width: 10rem;
  border: 1px solid var(--labels_important-active-labels-text-graphics);
  opacity: 1; }
  .stake-period-button.ant-radio-button-wrapper-checked .period-x {
    font: italic normal 900 52px/5px Neue Haas Grotesk Display Pro;
    line-height: 6rem;
    letter-spacing: 0; }

.stake-period-button.ant-radio-button-wrapper:hover, .stake-period-button.ant-radio-button-wrapper:focus {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.staking-account__container {
  padding: 0 0.5rem;
  background-color: var(--base_wallet-sidebar-and-popup);
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  font-weight: bold;
  height: 4rem; }

.staking-account__balance {
  padding: 1rem; }

.staking-account__address {
  background-color: var(--base_background);
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0.5rem; }

.staking-account__connect {
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: var(--specials_buttons-links-and-highlights);
  background-color: var(--calculated_specials_highlight-box);
  border-radius: 0.5rem;
  margin: 1rem 0.5rem;
  font-weight: bold; }
  .staking-account__connect:hover {
    opacity: 0.8; }

.chart_mine {
  fill-opacity: 0.7;
  fill: var(--specials_buttons-links-and-highlights);
  stroke: var(--specials_buttons-links-and-highlights); }

.chart_others {
  fill-opacity: 0.7;
  fill: var(--specials_pending-waiting-caution);
  stroke: var(--specials_pending-waiting-caution); }

.stake-chart-legend {
  width: 2rem !important;
  height: 2rem !important;
  margin-right: 0.5rem;
  border-radius: 50%; }
  .stake-chart-legend.mine {
    background-color: var(--specials_buttons-links-and-highlights); }
  .stake-chart-legend.others {
    background-color: var(--specials_pending-waiting-caution); }

.stake_popup__title {
  font-size: 2.4rem !important;
  font-weight: bold; }

.stake-variable {
  width: 1.5rem;
  padding-top: 0.2rem; }

.stake-unlock-title svg, .stake-unlock-title.icon_title-svg {
  width: 2.2rem !important;
  height: 2.2rem !important; }

.stake-amount {
  font-size: 2rem;
  line-height: 2rem; }
  .stake-amount .small {
    font-size: 1.2rem; }

.staking-loader {
  font-size: 7rem;
  color: var(--calculated_specials_highlight-box); }

.stake-details-table .table-wrapper {
  margin: 0.2rem 0 2rem; }

.stake-details-table .table_header-row {
  color: var(--labels_important-active-labels-text-graphics); }

.stake-panel-bg {
  background-color: var(--base_wallet-sidebar-and-popup);
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .stake-panel-bg .wallet-assets_block {
    background-color: transparent;
    z-index: 1; }
  .stake-panel-bg:before {
    content: '';
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.05; }
  .stake-panel-bg .wallet-assets_block {
    width: 100%;
    height: 100%; }

.table-row.hoverable:hover {
  background-color: var(--calculated_stake-table_highlight);
  color: var(--labels_important-active-labels-text-graphics); }

.area-disabled {
  opacity: 0.5; }

.add-app-button {
  border-radius: 24px;
  height: 24px !important;
  min-width: 70px !important;
  width: auto !important;
  text-transform: none !important; }

.apps-form .ant-input {
  color: var(--labels_important-active-labels-text-graphics); }

.apps-form .ant-input-clear-icon {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.action_notification-wrapper.remove-action {
  position: relative !important; }

.apps-table-check {
  width: 2rem !important; }

.trade-details-wrapper {
  width: 100%;
  padding: 40px 65px 30px 55px;
  font-family: "Open Sans";
  background-color: var(--base_background); }
  .trade-details-wrapper .trade-details-content {
    height: 450px; }
    .trade-details-wrapper .trade-details-content .pb-24 {
      padding-bottom: 24px; }
    .trade-details-wrapper .trade-details-content .pb-30 {
      padding-bottom: 30px; }
    .trade-details-wrapper .trade-details-content .pb-35 {
      padding-bottom: 35px; }
    .trade-details-wrapper .trade-details-content .pl-6 {
      padding-left: 6rem; }
    .trade-details-wrapper .trade-details-content .chart {
      height: 100px;
      margin: 10px 0; }
      .trade-details-wrapper .trade-details-content .chart div:last-child {
        padding-top: 1rem; }
      .trade-details-wrapper .trade-details-content .chart .fade-area {
        position: relative; }
      .trade-details-wrapper .trade-details-content .chart .fade-area::before {
        content: '';
        position: absolute;
        height: 100px;
        width: 100%;
        background-image: linear-gradient(to right, var(--base_background), rgba(0, 0, 0, 0));
        z-index: -1; }
    .trade-details-wrapper .trade-details-content .trade_tabs-container {
      margin: 0 !important;
      width: 100% !important; }
    .trade-details-wrapper .trade-details-content .main-coin-wrapper {
      opacity: 0.3; }
  .trade-details-wrapper .f-size-16 {
    font-size: 16px;
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-weight: bold; }
  .trade-details-wrapper .f-size-22 {
    font-size: 22px;
    font-weight: bold; }
  .trade-details-wrapper .pairs {
    color: var(--specials_buttons-links-and-highlights);
    font-size: 16px; }
  .trade-details-wrapper .underline {
    text-decoration: underline; }
  .trade-details-wrapper .fullname {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 11px;
    white-space: nowrap; }
  .trade-details-wrapper .sub-title {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font: normal normal 300 11px/15px "Open Sans";
    letter-spacing: 2.2px;
    opacity: 1;
    white-space: nowrap; }
  .trade-details-wrapper .white-txt {
    color: var(--labels_important-active-labels-text-graphics); }
  .trade-details-wrapper .day-change-block {
    text-align: end;
    margin-bottom: 1.5rem; }
    .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper {
      background: transparent;
      color: var(--labels_secondary-inactive-label-text-graphics);
      border-color: var(--labels_secondary-inactive-label-text-graphics);
      height: 28px;
      padding: 0 12px;
      line-height: 24px; }
      .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper:first-child {
        border-radius: 0.5rem 0 0 0.5rem; }
      .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper:last-child {
        border-radius: 0 0.5rem 0.5rem 0; }
      .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper::before {
        background: #fff; }
    .trade-details-wrapper .day-change-block .ant-radio-group .ant-radio-button-wrapper-checked {
      border-color: #fff;
      color: #fff; }

.layout-mobile .trade-details-wrapper {
  width: 100%; }
  .layout-mobile .trade-details-wrapper .trade-details-content {
    width: 100%;
    height: 100%; }

@media (max-width: 1020px) {
  .trade-details-wrapper {
    width: 100%;
    padding: 15px; }
    .trade-details-wrapper .trade-details-content {
      width: 100%;
      height: 100%; } }

.hollaex-token-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; }
  .hollaex-token-wrapper .trade_tabs-link {
    color: var(--specials_buttons-links-and-highlights);
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    text-transform: capitalize; }
    .hollaex-token-wrapper .trade_tabs-link:hover {
      opacity: 0.5; }
  .hollaex-token-wrapper .link-separator {
    border-right: 1px solid var(--calculated_secondary-border); }
  .hollaex-token-wrapper .mt-8 {
    margin-top: 8rem; }
  .hollaex-token-wrapper .pb-6 {
    padding-bottom: 6rem; }
  .hollaex-token-wrapper .icon_title-wrapper {
    margin-top: 0 !important; }
  .hollaex-token-wrapper .sub-header-icon {
    height: 20px;
    width: 20px;
    margin: 1px 3px; }
  .hollaex-token-wrapper .token-wrapper {
    width: 65%;
    height: 100%; }
    .hollaex-token-wrapper .token-wrapper .link {
      color: var(--specials_buttons-links-and-highlights);
      text-decoration: underline;
      cursor: pointer; }
    .hollaex-token-wrapper .token-wrapper .header-container {
      width: 100%;
      font-size: 16px; }
      .hollaex-token-wrapper .token-wrapper .header-container .title {
        font-size: 24px;
        color: var(--labels_secondary-inactive-label-text-graphics); }
        .hollaex-token-wrapper .token-wrapper .header-container .title .favourite-text {
          font-size: 12px;
          color: #707070;
          padding-right: 10px;
          opacity: 0; }
        .hollaex-token-wrapper .token-wrapper .header-container .title .star-icon:hover .favourite-text {
          opacity: 1; }
        .hollaex-token-wrapper .token-wrapper .header-container .title .favourite-text-2 {
          font-size: 12px;
          color: var(--labels_secondary-inactive-label-text-graphics);
          padding-right: 10px; }
    .hollaex-token-wrapper .token-wrapper .balance-wrapper .icon_title-wrapper .icon_title-svg svg {
      width: 5rem !important;
      height: 2rem !important;
      margin-top: 0rem !important; }
    .hollaex-token-wrapper .token-wrapper .balance-wrapper .hollaex-token-wrapper .icon_title-wrapper .icon_title-svg svg {
      margin-top: 0rem !important; }
    .hollaex-token-wrapper .token-wrapper .balance-wrapper .icon_title-wrapper .icon_title-svg {
      height: 0rem !important; }
    .hollaex-token-wrapper .token-wrapper .balance-wrapper .image-Wrapper .action_notification-svg {
      margin-right: 1rem; }
    .hollaex-token-wrapper .token-wrapper .ant-btn {
      background: var(--base_background);
      color: var(--labels_secondary-inactive-label-text-graphics); }
    .hollaex-token-wrapper .token-wrapper .hollaex-container {
      border: 1px solid var(--calculated_super-pale_label-text-graphics);
      display: flex;
      justify-content: space-between; }
      .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container {
        width: 65%;
        background-color: var(--calculated_quick_trade-bg);
        padding: 4rem;
        text-align: justify; }
        .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container .header-text {
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1px; }
        .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container .sub-text {
          font-size: 14px;
          width: 100%;
          margin-top: 1rem;
          color: var(--labels_secondary-inactive-label-text-graphics); }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container .sub-text a {
            text-decoration: underline;
            color: var(--specials_buttons-links-and-highlights);
            cursor: pointer; }
        .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container .button-container {
          margin-top: 3rem; }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container .button-container .edit-wrapper__container {
            width: 100%; }
            .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container .button-container .edit-wrapper__container div {
              width: 100%; }
            .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container .button-container .edit-wrapper__container .edit-wrapper__icons-container {
              width: 5%; }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container .button-container .button-2 {
            margin: 0 10px 0 10px; }
      .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper {
        padding: 40px 65px 30px 55px;
        font-family: "Open Sans";
        background-color: var(--base_background); }
        .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .coin-bg {
          opacity: 0.2; }
        .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content {
          min-height: 450px; }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .header-text {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px; }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .sub-text {
            font-size: 14px;
            width: 100%;
            margin-top: 1rem;
            color: var(--labels_secondary-inactive-label-text-graphics); }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .pb-30 {
            padding-bottom: 30px; }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .pb-35 {
            padding-bottom: 35px; }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .pl-6 {
            padding-left: 6rem; }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .chart {
            height: 100px;
            margin: 10px 0; }
            .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .chart div:last-child {
              padding-top: 1rem; }
            .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .chart .fade-area {
              position: relative; }
            .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .chart .fade-area::before {
              content: '';
              position: absolute;
              height: 100px;
              width: 100%;
              background-image: linear-gradient(to right, var(--base_background), rgba(0, 0, 0, 0));
              z-index: -1; }
          .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .trade_tabs-container {
            margin: 0 !important;
            width: 100% !important; }
        .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .f-size-16 {
          font-size: 16px;
          color: var(--labels_secondary-inactive-label-text-graphics);
          font-weight: bold; }
        .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .f-size-22 {
          font-size: 22px;
          font-weight: bold; }
        .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .fullname {
          color: var(--labels_secondary-inactive-label-text-graphics);
          font-size: 0.65rem;
          white-space: nowrap; }
        .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .sub-title {
          color: var(--labels_secondary-inactive-label-text-graphics);
          font: normal normal 300 11px/15px "Open Sans";
          letter-spacing: 2.2px;
          opacity: 1;
          white-space: nowrap; }
  .hollaex-token-wrapper .paircoins-color {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .hollaex-token-wrapper .white-text {
    color: var(--labels_important-active-labels-text-graphics); }

.trade_tabs-container .trade-tab-price_diff_up:before {
  margin-bottom: 0.5rem; }

.info-text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px; }

.layout-mobile .hollaex-token-wrapper .token-wrapper .hollaex-container {
  display: block; }
  .layout-mobile .hollaex-token-wrapper .token-wrapper .hollaex-container .info-container {
    width: 100%; }

.layout-mobile .hollaex-token-wrapper .token-wrapper .header-container .title .title-child-container {
  display: block !important; }
  .layout-mobile .hollaex-token-wrapper .token-wrapper .header-container .title .title-child-container .favourite-content {
    padding-left: 0px !important; }

.layout-mobile .hollaex-token-wrapper .token-wrapper .header-container .balance-wrapper {
  display: block !important; }
  .layout-mobile .hollaex-token-wrapper .token-wrapper .header-container .balance-wrapper .link {
    text-align: left; }

@media (max-width: 1024px) {
  .token-wrapper {
    width: 100% !important; } }

@media (max-width: 1400px) {
  .hollaex-token-wrapper .token-wrapper {
    width: 80%; } }

@media (max-width: 1200px) {
  .hollaex-token-wrapper .token-wrapper {
    width: 100%; }
    .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper {
      padding: 40px 45px 30px 45px; }
      .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .trade_tabs-container {
        font-size: 2rem; } }

@media (max-width: 768px) {
  .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper {
    padding: 40px 35px 30px 35px; }
    .hollaex-token-wrapper .token-wrapper .hollaex-container .trade-details-wrapper .trade-details-content .trade_tabs-container {
      font-size: 1.5rem !important; } }

.sidebar-row {
  min-height: 3rem;
  min-width: 10rem; }
  .sidebar-row .cell-wrapper {
    padding-left: 1rem;
    padding-right: 1rem; }

.app_bar .sidebar-row {
  min-width: 3rem; }

.sidebar-bottom-wrapper {
  min-height: 6rem;
  max-height: 6rem;
  background-color: var(--base_top-bar-navigation);
  color: var(--calculated_base_top-bar-navigation_text);
  width: 100%;
  padding: 0.5rem 0.5rem 0.8rem 0.5rem;
  justify-content: space-evenly; }
  .sidebar-bottom-wrapper .sidebar-bottom-button {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 20%; }
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .post-icon,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .chat-0,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .account-svg-1,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .quick-trade-tab-active {
      fill: var(--calculated_base_top-bar-navigation_text-inactive); }
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .tab-wallet0,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .trade-active-2,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .chat-0,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .quick-trade-tab-active {
      stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .tab-wallet0,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .quick-trade-tab-active {
      fill: transparent; }
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .post-icon,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .chat-0,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .account-svg-1 {
      fill: var(--calculated_base_top-bar-navigation_text); }
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .tab-wallet0,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .trade-active-2,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .chat-0,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .quick-trade-tab-active {
      stroke: var(--calculated_base_top-bar-navigation_text); }
    .sidebar-bottom-wrapper .sidebar-bottom-button .bottom-bar-text {
      font-size: 10px;
      text-align: center;
      margin-top: -17px;
      font-weight: lighter;
      color: var(--calculated_base_top-bar-navigation_text-inactive);
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
    .sidebar-bottom-wrapper .sidebar-bottom-button .bottom-text-acttive {
      color: var(--calculated_base_top-bar-navigation_text) !important;
      font-weight: bolder; }
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon {
      width: 3rem;
      height: 3rem; }
      .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon > div {
        display: flex;
        justify-content: center; }
      .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg {
        width: 3rem;
        height: 3rem;
        stroke: var(--labels_secondary-inactive-label-text-graphics);
        fill: transparent; }
    .sidebar-bottom-wrapper .sidebar-bottom-button img.sidebar-bottom-icon {
      width: 3rem;
      height: 3rem; }

.direction_ltr .sidebar-row .cell-wrapper:not(:last-child) {
  border-right: 1px solid var(--calculated_secondary-border); }

.direction_rtl .sidebar-row .cell-wrapper:not(:last-child) {
  border-left: 1px solid var(--calculated_secondary-border); }

@media screen and (max-height: 600px) {
  .sidebar-bottom-icon svg {
    stroke: none !important; } }

.tab_controller-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .tab_controller-title .tab_controller-title-icon {
    width: 6rem;
    height: 6rem; }
  .tab_controller-title .tab_controller-title-text {
    font-size: 4rem; }

.layout-mobile .tab_controller-tabs,
.layout-mobile .tab_item {
  transition: 0.3s;
  font-size: 1.5rem !important; }

.tab_controller-tabs {
  width: 100%;
  display: flex;
  align-content: center;
  border-bottom: 2px solid var(--calculated_secondary-border);
  margin-bottom: 1rem; }
  .tab_controller-tabs .tab_item {
    border-bottom: var(--labels_important-active-labels-text-graphics) solid transparent;
    padding: 0 8px 8px;
    display: flex;
    align-items: center;
    min-width: 8rem;
    justify-content: center;
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 14px; }
    .tab_controller-tabs .tab_item svg path {
      opacity: 0.7; }
  .tab_controller-tabs .tab_item-active {
    border-bottom: 4px solid var(--specials_buttons-links-and-highlights);
    color: var(--labels_important-active-labels-text-graphics); }
    .tab_controller-tabs .tab_item-active svg path {
      opacity: 1; }
      .tab_controller-tabs .tab_item-active svg path .st0,
      .tab_controller-tabs .tab_item-active svg path .g1_4,
      .tab_controller-tabs .tab_item-active svg path .g1_2 {
        fill: var(--labels_important-active-labels-text-graphics) !important; }
      .tab_controller-tabs .tab_item-active svg path .st0 {
        stroke: var(--labels_important-active-labels-text-graphics) !important; }

.layout-mobile .transaction-history-wrapper {
  padding-top: 0 !important; }

.modal-market-menu {
  background-color: var(--base_background);
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .modal-market-menu .field-wrapper {
    margin: 0; }
    .modal-market-menu .field-wrapper .field-content-outline:before, .modal-market-menu .field-wrapper .field-content-outline:after {
      height: 0.5px;
      opacity: 0.7; }
  .modal-market-menu .app-bar-search-field {
    flex-direction: row; }
  .modal-market-menu .app-bar-add-tab-search {
    height: 4rem; }
  .modal-market-menu .app-bar-tab-menu-list {
    background-color: var(--base_top-bar-navigation);
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 0.9rem;
    height: 2.5rem;
    padding: 0.5rem 0.8rem;
    width: 3.2rem;
    max-width: 3.2rem;
    /* (22 -6) / 5 */ }
  .modal-market-menu .active-tab {
    background-color: var(--base_background);
    color: var(--labels_important-active-labels-text-graphics);
    font-weight: bold; }
  .modal-market-menu .app-bar-add-tab-content {
    font-size: 0.9rem;
    overflow-y: scroll;
    padding: 0.3rem 0; }
    .modal-market-menu .app-bar-add-tab-content .scroll-view {
      overflow-y: auto;
      height: calc( 100vh - 19rem - 8rem); }
  .modal-market-menu .app-bar-tab-overflow-content {
    background-color: var(--base_background);
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 0.9rem;
    overflow-y: scroll;
    padding: 0.3rem 0; }
  .modal-market-menu .active-market {
    background-color: var(--base_secondary-navigation-bar);
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .modal-market-menu .app-bar-add-tab-content-list {
    color: var(--labels_secondary-inactive-label-text-graphics);
    height: 2.2rem; }
    .modal-market-menu .app-bar-add-tab-content-list:hover {
      background-color: var(--base_secondary-navigation-bar);
      color: var(--labels_secondary-inactive-label-text-graphics); }
      .modal-market-menu .app-bar-add-tab-content-list:hover svg .st0 {
        fill: var(--labels_secondary-inactive-label-text-graphics) !important;
        stroke-width: 0rem; }
  .modal-market-menu .app-bar-tab-setting {
    margin: 0.3rem 0.4rem 0 0.4rem;
    width: 1rem; }
  .modal-market-menu .app-price-diff-down {
    color: var(--trading_selling-related-elements); }
  .modal-market-menu .app-price-diff-up {
    color: var(--trading_buying-related-elements); }

@media (min-width: 1920px) {
  .modal-market-menu {
    width: 24rem; }
    .modal-market-menu.narrow {
      width: 14rem; }
    .modal-market-menu .app-bar-tab-menu-list {
      width: 3.6rem;
      max-width: 3.6rem;
      /* (24 -6) / 5 */ }
    .modal-market-menu .app-bar-add-tab-content,
    .modal-market-menu .app-bar-tab-menu-list,
    .modal-market-menu .app-bar-tab-overflow-content {
      font-size: 1rem; } }

@media screen and (max-width: 1096px) {
  .modal-market-menu .app-bar-add-tab-content,
  .modal-market-menu .app-bar-tab-menu-list,
  .modal-market-menu .app-bar-tab-overflow-content {
    font-size: 0.9rem; } }

.layout-mobile .modal-market-menu .app-bar-add-tab-content,
.layout-mobile .modal-market-menu .app-bar-tab-menu-list,
.layout-mobile .modal-market-menu .app-bar-tab-overflow-content {
  font-size: 1.3rem; }

.layout-mobile .modal-market-menu .app-bar-tab-menu-list {
  height: 3.5rem;
  width: 6rem;
  max-width: 6rem;
  /* (40 -6) / 5 */ }

.layout-mobile .modal-market-menu .app-bar-add-tab-content-list {
  height: 4rem; }

.app_bar {
  position: relative;
  width: 100%;
  min-height: 36px;
  height: 36px;
  font-size: 1rem;
  font-weight: bold;
  background-color: var(--base_top-bar-navigation);
  border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .app_bar.no-borders {
    border-bottom: none; }
    .app_bar.no-borders > div:not(:first-child) {
      border-left: none; }
  .app_bar > div {
    display: flex;
    align-items: center;
    padding: 0; }
  .app_bar .app_bar-icon-logo {
    height: 4rem;
    width: 5rem;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat; }
  .app_bar .app_bar-icon {
    flex: 0;
    margin: 0 0.5rem;
    padding-right: 8px;
    width: 100%; }
  .app_bar .app_bar-main {
    flex-grow: 1; }
  .app_bar .app_bar-controllers-splash {
    padding: 0; }
    .app_bar .app_bar-controllers-splash .action_button {
      min-width: 7.5rem;
      height: 100%;
      padding: 0 0.5rem; }
  .app_bar .bar-logout {
    max-height: 36px;
    min-height: 36px; }
  .app_bar .app_bar-quicktrade-container {
    margin: 0 0.25rem; }
    .app_bar .app_bar-quicktrade-container .toggle_button-wrapper .toggle-content .toggle-action_button.left {
      opacity: 1 !important; }
  .app_bar .app_bar-quicktrade {
    border: 1px solid var(--calculated_base_top-bar-navigation_text-inactive);
    border-radius: 0.35rem;
    color: var(--calculated_base_top-bar-navigation_text-inactive);
    font-weight: 400;
    margin: 0 6px;
    padding: 0 0.4rem;
    text-transform: capitalize;
    white-space: nowrap; }
    .app_bar .app_bar-quicktrade svg .trade-active-2,
    .app_bar .app_bar-quicktrade svg .st0 {
      fill: var(--labels_secondary-inactive-label-text-graphics) !important;
      stroke: var(--calculated_base_top-bar-navigation_text-inactive) !important;
      stroke-width: 3 !important; }
    .app_bar .app_bar-quicktrade svg .admin-dash {
      fill: var(--calculated_base_top-bar-navigation_text-inactive) !important; }
  .app_bar .overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .app_bar .quick_trade-active {
    border: 1px solid var(--calculated_base_top-bar-navigation_text);
    color: var(--calculated_base_top-bar-navigation_text); }
    .app_bar .quick_trade-active svg .trade-active-2,
    .app_bar .quick_trade-active svg .st0 {
      fill: var(--calculated_base_top-bar-navigation_text) !important;
      stroke: var(--calculated_base_top-bar-navigation_text) !important;
      stroke-width: 3 !important; }
  .app_bar .quicktrade_icon {
    margin-top: 0.2rem;
    width: 1rem; }
  .app_bar .app-bar-account {
    color: var(--calculated_base_top-bar-navigation_text);
    cursor: pointer;
    margin-right: 0.8rem; }
    .app_bar .app-bar-account svg .account-inactive {
      fill: var(--calculated_base_top-bar-navigation_text-inactive);
      stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app_bar .app_bar-pair-overflow {
    align-items: center;
    color: var(--calculated_base_top-bar-navigation_text-inactive);
    cursor: pointer;
    display: flex;
    font-size: 1.1rem;
    font-weight: 400;
    height: 100%;
    padding: 0 0.5rem;
    position: relative; }
  .app_bar .active-tab-overflow {
    background-color: var(--base_background);
    border-left: 1px solid var(--base_background);
    color: var(--labels_secondary-inactive-label-text-graphics);
    top: 1px; }
    .app_bar .active-tab-overflow svg .double-arrow {
      stroke: var(--labels_important-active-labels-text-graphics); }
  .app_bar .app-bar-currency-icon {
    margin: 0.5rem 0.3rem 0 0.3rem;
    width: 1.5rem; }
  .app_bar .app-bar-account-icon {
    width: 1.5rem; }
  .app_bar .app-bar-account-moon-icon {
    margin: 0.2rem 0.3rem 0 0.3rem;
    width: 1rem; }
    .app_bar .app-bar-account-moon-icon svg .moon-icon,
    .app_bar .app-bar-account-moon-icon svg .sun-icon {
      fill: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app_bar .app-bar-account-moon-content {
    border-radius: 5px; }
  .app_bar .toggle_button-wrapper .toggle-content {
    color: var(--calculated_base_top-bar-navigation_text);
    border-radius: 0.35rem;
    padding: 0px 0.1rem; }
    .app_bar .toggle_button-wrapper .toggle-content .dark .moon-icon {
      fill: var(--calculated_base_top-bar-navigation_text); }
    .app_bar .toggle_button-wrapper .toggle-content .white .sun-icon {
      fill: var(--calculated_base_top-bar-navigation_text); }
    .app_bar .toggle_button-wrapper .toggle-content .toggle-action_button {
      border-color: var(--calculated_base_top-bar-navigation_text);
      width: 34px;
      height: 75.5%;
      margin: 0;
      cursor: pointer; }
      .app_bar .toggle_button-wrapper .toggle-content .toggle-action_button.left .toggle-action_button-display {
        right: calc(100% - 14px); }
      .app_bar .toggle_button-wrapper .toggle-content .toggle-action_button.right .toggle-action_button-display {
        right: -1px; }
      .app_bar .toggle_button-wrapper .toggle-content .toggle-action_button .toggle-action_button-display {
        background: var(--calculated_base_top-bar-navigation_text);
        height: 9px;
        width: 9px;
        margin: 1.2px 3px 1px 0 !important; }
  .app_bar .app_bar-pair-content .tab-close,
  .app_bar .app_bar-pair-content .plus,
  .app_bar .app-bar-tab-close .tab-close,
  .app_bar .app-bar-tab-close .plus,
  .app_bar .app-bar-currency-icon .tab-close,
  .app_bar .app-bar-currency-icon .plus,
  .app_bar .app-bar-account-icon svg .tab-close,
  .app_bar .app-bar-account-icon svg .plus {
    stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app_bar .app_bar-pair-content .st0,
  .app_bar .app-bar-tab-close .st0,
  .app_bar .app-bar-currency-icon .st0,
  .app_bar .app-bar-account-icon svg .st0 {
    fill: var(--calculated_base_top-bar-navigation_text) !important;
    stroke-width: 0rem; }
  .app_bar .account-inactive .app-bar-account-icon svg .st0 {
    fill: var(--calculated_base_top-bar-navigation_text-inactive) !important; }
  .app_bar .app_bar-currency-txt {
    font-weight: 600; }
  .app_bar .app-price-diff-down {
    color: var(--trading_selling-related-elements); }
  .app_bar .app-price-diff-up {
    color: var(--trading_buying-related-elements); }
  .app_bar .app-bar-price_diff_up {
    margin-left: 0.5rem; }
  .app_bar .app-bar-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .app_bar .app-bar-price_diff_down {
    margin-left: 0.5rem; }
  .app_bar .app-bar-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .app_bar .app-bar-account-content {
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app_bar .app-announcement-list {
    height: 335px;
    width: 280px; }
  .app_bar .app-bar-account-notification {
    position: absolute;
    left: 15px;
    bottom: 12px;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 1.2rem;
    background-color: var(--specials_notifications-alerts-warnings);
    color: var(--calculated_specials_notifications-alerts-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1;
    font-weight: bold; }
  .app_bar .opacity-1 {
    opacity: 1 !important; }
  .app_bar .app-bar-account-menu {
    transition: 0.9s;
    opacity: 0;
    display: block;
    position: absolute;
    top: 36px;
    min-width: 11rem;
    min-height: 8rem;
    background-color: var(--base_background);
    z-index: 1052;
    padding: 1rem;
    border-top: 2px solid var(--specials_buttons-links-and-highlights);
    -webkit-box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4); }
    .app_bar .app-bar-account-menu .app-bar-account-menu-list {
      color: var(--labels_secondary-inactive-label-text-graphics);
      cursor: pointer;
      padding: 0.2rem 0; }
    .app_bar .app-bar-account-menu .app-bar-account-list-icon {
      margin-right: 0.4rem;
      width: 1rem; }
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .trade-active-2 {
        stroke: var(--labels_secondary-inactive-label-text-graphics); }
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .trade-active-2,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .quick-trade-tab-active,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .tab-api0 {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
    .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .account-active-1 {
      fill: var(--calculated_base_top-bar-navigation_text-inactive); }
    .app_bar .app-bar-account-menu .menu-notification-active {
      color: var(--specials_notifications-alerts-warnings); }
      .app_bar .app-bar-account-menu .menu-notification-active svg .tab-api0,
      .app_bar .app-bar-account-menu .menu-notification-active svg .fill_secondary-color {
        fill: var(--specials_notifications-alerts-warnings); }
    .app_bar .app-bar-account-menu .menu-active {
      color: var(--labels_important-active-labels-text-graphics); }
      .app_bar .app-bar-account-menu .menu-active svg .trade-active-2 {
        stroke: var(--labels_important-active-labels-text-graphics); }
      .app_bar .app-bar-account-menu .menu-active svg .trade-active-2,
      .app_bar .app-bar-account-menu .menu-active svg .quick-trade-tab-active,
      .app_bar .app-bar-account-menu .menu-active svg .tab-api0,
      .app_bar .app-bar-account-menu .menu-active svg .account-active-1,
      .app_bar .app-bar-account-menu .menu-active svg .fill_secondary-color {
        fill: var(--labels_important-active-labels-text-graphics); }
    .app_bar .app-bar-account-menu .notification-content {
      width: 1.2rem; }
    .app_bar .app-bar-account-menu .notification {
      color: var(--specials_notifications-alerts-warnings); }
      .app_bar .app-bar-account-menu .notification svg .tab-api0,
      .app_bar .app-bar-account-menu .notification svg .fill_secondary-color {
        fill: var(--specials_notifications-alerts-warnings); }
    .app_bar .app-bar-account-menu .security_notification,
    .app_bar .app-bar-account-menu .verification_notification,
    .app_bar .app-bar-account-menu .wallet_notification {
      color: var(--calculated_specials_notifications-alerts-inactive); }
      .app_bar .app-bar-account-menu .security_notification svg .tab-api0,
      .app_bar .app-bar-account-menu .security_notification svg .fill_secondary-color,
      .app_bar .app-bar-account-menu .verification_notification svg .tab-api0,
      .app_bar .app-bar-account-menu .verification_notification svg .fill_secondary-color,
      .app_bar .app-bar-account-menu .wallet_notification svg .tab-api0,
      .app_bar .app-bar-account-menu .wallet_notification svg .fill_secondary-color {
        fill: var(--calculated_specials_notifications-alerts-inactive); }
    .app_bar .app-bar-account-menu .app-bar-account-menu-notification {
      position: absolute;
      right: -10px;
      top: -10px;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 1.2rem;
      background-color: var(--specials_notifications-alerts-warnings);
      color: var(--calculated_specials_notifications-alerts-text);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      line-height: 1;
      font-weight: bold; }
    .app_bar .app-bar-account-menu .security_selected,
    .app_bar .app-bar-account-menu .verification_selected,
    .app_bar .app-bar-account-menu .wallet_selected {
      background-color: var(--specials_notifications-alerts-warnings) !important; }
    .app_bar .app-bar-account-menu .security_selected_inactive,
    .app_bar .app-bar-account-menu .verification_selected_inactive,
    .app_bar .app-bar-account-menu .wallet_selected_inactive {
      background-color: var(--calculated_specials_notifications-alerts-inactive) !important; }
    .app_bar .app-bar-account-menu .app-bar-account-list-notification {
      position: relative;
      right: 0;
      bottom: 8px;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 1.2rem;
      background-color: var(--specials_notifications-alerts-warnings);
      color: var(--calculated_specials_notifications-alerts-text);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      line-height: 1;
      font-weight: bold; }
  .app_bar .login-container {
    background-color: var(--specials_buttons-links-and-highlights);
    padding: 2px 20px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 11px; }

.capitalize {
  text-transform: capitalize; }

.home_app_bar {
  position: relative;
  width: 100%;
  height: 45px;
  min-height: 45px;
  color: var(--calculated_base_top-bar-navigation_text);
  background-color: var(--base_top-bar-navigation);
  border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .home_app_bar .app_bar-icon-logo {
    height: 4rem;
    width: 5rem;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat; }
  .home_app_bar .app_bar-icon {
    flex: 0;
    margin: 0 0.5rem;
    padding-right: 8px;
    width: 100%; }
  .home_app_bar .main-section_button_invert {
    color: var(--specials_buttons-links-and-highlights) !important;
    background-color: var(--labels_important-active-labels-text-graphics) !important; }
  .home_app_bar .main-section_button {
    color: var(--labels_important-active-labels-text-graphics) !important;
    background-color: var(--specials_buttons-links-and-highlights) !important; }

.select-option-wrapper .ant-select-item,
.select-option-wrapper .ant-select-item-option {
  font-size: 12px; }

.app-bar-tab-menu {
  background-color: var(--base_top-bar-navigation) !important; }

.app_bar-mobile {
  padding: 1rem 0; }
  .app_bar-mobile .homeicon-svg {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 4rem;
    width: 7rem; }
  .app_bar-mobile .app_bar-controllers-splash .action_button {
    min-width: 4rem;
    margin: 0 0.75rem; }
  .app_bar-mobile .app-bar-account {
    color: var(--calculated_base_top-bar-navigation_text);
    cursor: pointer;
    margin-right: 0.5rem; }
  .app_bar-mobile .app-bar-account-content {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative; }
  .app_bar-mobile .app-bar-currency-icon {
    margin: 0.5rem 0.3rem 0 0.3rem;
    width: 1.5rem; }
    .app_bar-mobile .app-bar-currency-icon .st0 {
      fill: var(--calculated_base_top-bar-navigation_text) !important;
      stroke-width: 0rem; }
  .app_bar-mobile .app-bar-account-notification {
    position: absolute;
    right: -6px;
    top: 0;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 1.2rem;
    background-color: var(--calculated_specials_notifications-alerts-inactive);
    color: var(--calculated_specials_notifications-alerts-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1;
    font-weight: bold; }

.app-menu-bar {
  display: flex;
  font-size: 1rem;
  height: 100%;
  position: relative;
  max-width: calc(100vw - 40rem); }
  .app-menu-bar .app-menu-bar-content {
    transition: 0.3s;
    color: var(--calculated_base_top-bar-navigation_text-inactive);
    cursor: pointer;
    margin: 0 15px;
    height: 100%;
    padding: 8px 0;
    opacity: 0.8;
    display: flex;
    align-items: center;
    border-top: 3px solid transparent; }
  .app-menu-bar .app-menu-bar-content-item {
    margin: auto;
    position: relative; }
    .app-menu-bar .app-menu-bar-content-item:hover {
      opacity: 0.5; }
  .app-menu-bar .app-menu-bar-icon {
    margin-right: 0.4rem;
    width: 1rem; }
    .app-menu-bar .app-menu-bar-icon svg .trade-active-2 {
      stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
    .app-menu-bar .app-menu-bar-icon svg .trade-active-2,
    .app-menu-bar .app-menu-bar-icon svg .quick-trade-tab-active,
    .app-menu-bar .app-menu-bar-icon svg .tab-api0,
    .app-menu-bar .app-menu-bar-icon svg .fill_secondary-color {
      fill: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app-menu-bar .active-menu {
    color: var(--calculated_base_top-bar-navigation_text);
    border-top: 4px solid var(--specials_buttons-links-and-highlights);
    height: 100%;
    opacity: 1; }
    .app-menu-bar .active-menu svg .trade-active-2 {
      stroke: var(--calculated_base_top-bar-navigation_text); }
    .app-menu-bar .active-menu svg .trade-active-2,
    .app-menu-bar .active-menu svg .quick-trade-tab-active,
    .app-menu-bar .active-menu svg .tab-api0,
    .app-menu-bar .active-menu svg .fill_secondary-color {
      fill: var(--calculated_base_top-bar-navigation_text); }
  .app-menu-bar .menu-notification-active {
    background-color: var(--calculated_base_top-bar-navigation_text);
    height: 2.5rem;
    position: relative;
    color: var(--specials_notifications-alerts-warnings); }
    .app-menu-bar .menu-notification-active svg .tab-api0,
    .app-menu-bar .menu-notification-active svg .fill_secondary-color {
      fill: var(--specials_notifications-alerts-warnings); }
  .app-menu-bar .active-menu.notification {
    color: var(--specials_notifications-alerts-warnings); }
    .app-menu-bar .active-menu.notification svg .tab-api0,
    .app-menu-bar .active-menu.notification svg .fill_secondary-color {
      fill: var(--specials_notifications-alerts-warnings); }
  .app-menu-bar .active-menu .app-menu-bar-icon-notification {
    background-color: var(--specials_notifications-alerts-warnings); }
  .app-menu-bar .notification {
    color: var(--calculated_specials_notifications-alerts-inactive); }
    .app-menu-bar .notification svg .tab-api0,
    .app-menu-bar .notification svg .fill_secondary-color {
      fill: var(--calculated_specials_notifications-alerts-inactive); }
  .app-menu-bar .security_notification,
  .app-menu-bar .verification_notification,
  .app-menu-bar .wallet_notification {
    color: var(--calculated_specials_notifications-alerts-inactive); }
    .app-menu-bar .security_notification svg .tab-api0,
    .app-menu-bar .security_notification svg .fill_secondary-color,
    .app-menu-bar .verification_notification svg .tab-api0,
    .app-menu-bar .verification_notification svg .fill_secondary-color,
    .app-menu-bar .wallet_notification svg .tab-api0,
    .app-menu-bar .wallet_notification svg .fill_secondary-color {
      fill: var(--calculated_specials_notifications-alerts-inactive); }
  .app-menu-bar .app-menu-bar-icon-notification {
    position: absolute;
    left: -14px;
    top: -5px;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 1.2rem;
    background-color: var(--calculated_specials_notifications-alerts-inactive);
    color: var(--calculated_specials_notifications-alerts-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 1;
    font-weight: bold; }

.favourite-list {
  padding-left: 0.5rem;
  border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics);
  overflow-y: auto; }
  .favourite-list.border-left {
    border-left: 1px solid var(--calculated_super-pale_label-text-graphics); }

.tools-button {
  border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .tools-button.border-left {
    border-left: 1px solid var(--calculated_super-pale_label-text-graphics); }

.market-bar {
  background: var(--base_secondary-navigation-bar);
  display: flex;
  font-size: 1rem;
  height: 36px;
  min-height: 36px;
  position: relative;
  width: 100%; }
  .market-bar svg .plus {
    stroke: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .market-bar .selector-trigger .active-tab-pair {
    background-color: var(--base_background); }
  .market-bar .selector-trigger .app_bar-pair-tab {
    min-width: 17rem;
    max-width: 17rem; }
  .market-bar .selector-trigger:hover {
    background-color: var(--base_secondary-navigation-bar);
    border-radius: 0.2rem; }
  .market-bar .selector-trigger.narrow {
    min-width: 14rem;
    max-width: 14rem; }
  .market-bar .app_bar-currency-txt {
    font-weight: 600; }
  .market-bar .app-price-diff-down {
    color: var(--trading_selling-related-elements); }
  .market-bar .app-price-diff-up {
    color: var(--trading_buying-related-elements); }
  .market-bar .app-bar-price_diff_up {
    margin-left: 0.5rem; }
  .market-bar .app-bar-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .market-bar .app-bar-price_diff_down {
    margin-left: 0.5rem; }
  .market-bar .app-bar-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .market-bar .app_bar-pair-content .tab-close,
  .market-bar .app_bar-pair-content .plus,
  .market-bar .app-bar-tab-close .tab-close,
  .market-bar .app-bar-tab-close .plus,
  .market-bar .app-bar-currency-icon .tab-close,
  .market-bar .app-bar-currency-icon .plus,
  .market-bar .app-bar-account-icon svg .tab-close,
  .market-bar .app-bar-account-icon svg .plus {
    stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
  .market-bar .app_bar-pair-content .st0,
  .market-bar .app-bar-tab-close .st0,
  .market-bar .app-bar-currency-icon .st0,
  .market-bar .app-bar-account-icon svg .st0 {
    fill: var(--calculated_base_top-bar-navigation_text) !important;
    stroke-width: 0rem; }
  .market-bar .app_bar-pair-font {
    font-size: 0.9rem; }
  .market-bar .app-bar-tab-close {
    margin: 0.2rem 0.4rem 0 0.4rem;
    width: 0.9rem; }
  .market-bar .app_bar-pair-content {
    align-items: center;
    color: var(--labels_secondary-inactive-label-text-graphics);
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    font-weight: 400;
    height: 100%;
    position: relative; }
  .market-bar .app_bar-pair-tab {
    padding: 0 0.5rem;
    min-width: 14rem;
    max-width: 14rem; }
    .market-bar .app_bar-pair-tab.selector-trigger {
      min-width: 17rem;
      max-width: 17rem; }
      .market-bar .app_bar-pair-tab.selector-trigger.narrow {
        min-width: 14rem;
        max-width: 14rem; }
  .market-bar .transition_open {
    left: -3.5rem;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards; }
  .market-bar .transition_close {
    left: 0;
    -webkit-animation: slideRev 0.5s backwards;
    animation: slideRev 0.5s backwards;
    z-index: 99; }

@-webkit-keyframes slide {
  100% {
    left: 0; } }

@keyframes slide {
  100% {
    left: 0; } }

@-webkit-keyframes slideRev {
  100% {
    left: -4rem; } }

@keyframes slideRev {
  100% {
    left: -4rem; } }
  .market-bar .favourite-tab {
    padding: 0.2rem 0;
    border-radius: 0.7rem; }
    .market-bar .favourite-tab:hover {
      background-color: var(--base_background); }
  .market-bar .active-tab-pair {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .market-bar .active-tab-pair svg .tab-close,
    .market-bar .active-tab-pair svg .plus,
    .market-bar .active-tab-pair svg .double-arrow {
      stroke: var(--labels_important-active-labels-text-graphics); }
    .market-bar .active-tab-pair svg .st0 {
      fill: var(--labels_important-active-labels-text-graphics);
      stroke-width: 0rem; }

.market-bar-side {
  min-width: 20rem;
  max-width: 20rem; }

.app-bar-add-tab-search {
  color: var(--labels_secondary-inactive-label-text-graphics);
  height: 2.2rem;
  margin-bottom: 0.5rem; }

.search-field {
  color: var(--labels_secondary-inactive-label-text-graphics);
  background-color: var(--base_background); }
  .search-field ::placeholder {
    color: var(--labels_secondary-inactive-label-text-graphics); }

.app-bar-search-field {
  flex-direction: row-reverse;
  margin: 0;
  padding-right: 0.5rem; }

.app-bar-search-field-outline {
  height: 0.5px; }

.app-bar-add-tab-menu {
  background-color: var(--base_background);
  border: 1px solid var(--calculated_secondary-border);
  color: var(--labels_secondary-inactive-label-text-graphics);
  width: 22rem;
  min-height: 8rem;
  z-index: 20;
  -webkit-box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4); }
  .app-bar-add-tab-menu.narrow {
    width: 14rem; }
  .app-bar-add-tab-menu .field-wrapper {
    margin: 0; }
    .app-bar-add-tab-menu .field-wrapper .field-content-outline:before, .app-bar-add-tab-menu .field-wrapper .field-content-outline:after {
      height: 0.5px;
      opacity: 0.7; }
  .app-bar-add-tab-menu .ant-checkbox-wrapper span {
    color: var(--labels_important-active-labels-text-graphics) !important; }
  .app-bar-add-tab-menu .ant-btn-background-ghost {
    border-color: var(--labels_secondary-inactive-label-text-graphics); }
  .app-bar-add-tab-menu .ant-btn:active, .app-bar-add-tab-menu .ant-btn:hover, .app-bar-add-tab-menu .ant-btn:focus {
    color: var(--labels_important-active-labels-text-graphics);
    border-color: var(--labels_important-active-labels-text-graphics); }
  .app-bar-add-tab-menu .app-bar-tab-menu-list {
    background-color: var(--base_top-bar-navigation);
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 0.9rem;
    height: 2.5rem;
    padding: 0.5rem 0.8rem;
    width: 3.2rem;
    max-width: 3.2rem;
    /* (22 -6) / 5 */ }
  .app-bar-add-tab-menu .active-tab {
    background-color: var(--base_background);
    color: var(--labels_important-active-labels-text-graphics);
    font-weight: bold; }
  .app-bar-add-tab-menu .app-bar-add-tab-content {
    font-size: 0.9rem;
    overflow-y: scroll;
    padding: 0.3rem 0; }
    .app-bar-add-tab-menu .app-bar-add-tab-content .scroll-view {
      overflow-y: auto;
      height: 22rem; }
  .app-bar-add-tab-menu .app-bar-tab-overflow-content {
    background-color: var(--base_background);
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 0.9rem;
    overflow-y: scroll;
    padding: 0.3rem 0; }
  .app-bar-add-tab-menu .app-bar-add-tab-content-list {
    color: var(--labels_secondary-inactive-label-text-graphics);
    height: 2.2rem; }
    .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover {
      background-color: var(--base_secondary-navigation-bar);
      color: var(--labels_secondary-inactive-label-text-graphics); }
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .st0 {
        fill: var(--labels_secondary-inactive-label-text-graphics) !important;
        stroke-width: 0rem; }
  .app-bar-add-tab-menu .app-bar-tab-setting {
    margin: 0.3rem 0.4rem 0 0.4rem;
    width: 1rem; }
  .app-bar-add-tab-menu .app-price-diff-down {
    color: var(--trading_selling-related-elements); }
  .app-bar-add-tab-menu .app-price-diff-up {
    color: var(--trading_buying-related-elements); }

.app_bar-link {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  text-transform: uppercase; }

.app-bar_add-tab-price {
  color: var(--labels_important-active-labels-text-graphics); }

@media (min-width: 1920px) {
  .app_bar {
    font-size: 1rem;
    height: 45px;
    min-height: 45px; }
    .app_bar .app_bar-pair-font {
      font-size: 1rem; }
    .app_bar .app_bar-icon-logo {
      height: 3rem;
      width: 5rem; }
    .app_bar .app-bar-tab-close {
      width: 1.1rem; }
    .app_bar .app_bar-icon {
      margin: 1rem 0 1rem; }
  .market-bar {
    font-size: 1rem; }
    .market-bar .app_bar-pair-font {
      font-size: 1rem; }
    .market-bar .tools-button .app_bar-pair-tab {
      min-width: 14rem !important;
      max-width: 14rem !important; }
  .app-menu-bar {
    font-size: 1rem !important;
    height: 45px;
    min-height: 45px; }
    .app-menu-bar .app-menu-bar-content {
      width: 12rem; }
  .app-bar-add-tab-menu {
    width: 24rem; }
    .app-bar-add-tab-menu.narrow {
      width: 15rem; }
    .app-bar-add-tab-menu .app-bar-tab-menu-list {
      width: 3.6rem;
      max-width: 3.6rem;
      /* (24 -6) / 5 */ }
    .app-bar-add-tab-menu .app-bar-add-tab-content,
    .app-bar-add-tab-menu .app-bar-tab-menu-list,
    .app-bar-add-tab-menu .app-bar-tab-overflow-content {
      font-size: 1rem; } }

@media screen and (max-width: 1096px) {
  .app_bar {
    font-size: 0.9rem; }
    .app_bar .app_bar-pair-font {
      font-size: 0.9rem; }
  .market-bar {
    font-size: 0.9rem; }
    .market-bar .app_bar-pair-font {
      font-size: 0.9rem; }
  .app-menu-bar {
    font-size: 0.9rem !important; }
  .app-bar-add-tab-menu .app-bar-add-tab-content,
  .app-bar-add-tab-menu .app-bar-tab-menu-list,
  .app-bar-add-tab-menu .app-bar-tab-overflow-content {
    font-size: 0.9rem; } }

.layout-mobile .st0 {
  fill: #ffffff;
  stroke-miterlimit: 10; }

.layout-mobile .app-bar-add-tab-menu {
  width: 36rem;
  position: absolute;
  top: 7.8rem;
  left: 1.2rem; }
  .layout-mobile .app-bar-add-tab-menu .app-bar-add-tab-content,
  .layout-mobile .app-bar-add-tab-menu .app-bar-tab-menu-list,
  .layout-mobile .app-bar-add-tab-menu .app-bar-tab-overflow-content {
    font-size: 1.3rem; }
  .layout-mobile .app-bar-add-tab-menu .app-bar-tab-menu-list {
    height: 3.5rem;
    width: 6rem;
    max-width: 6rem;
    /* (40 -6) / 5 */ }
  .layout-mobile .app-bar-add-tab-menu .app-bar-add-tab-content-list {
    height: 4rem; }

.direction_rtl .app-menu-bar .app-menu-bar-icon {
  margin-left: 0.4rem;
  margin-right: 0; }

.direction_rtl .app-menu-bar .app-menu-bar-icon-notification {
  right: -14px;
  top: -5px;
  left: unset; }

.direction_rtl .app_bar .app-bar-account-menu .app-bar-account-list-icon {
  margin-right: 0;
  margin-left: 0.4rem; }

.market-selector-dropdown.ant-dropdown-trigger {
  height: 100% !important;
  width: 100% !important;
  box-sizing: content-box; }

.app-menu-bar-wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  align-items: flex-start !important;
  height: 100%;
  max-width: calc(100vw - 40rem); }

.stared-market svg {
  fill: var(--specials_my-username-in-chat); }

.active-market-trigger {
  color: var(--labels_important-active-labels-text-graphics) !important;
  font-weight: bold; }

.market-trigger {
  background-color: var(--base_background); }

.landing-page-bar {
  background: linear-gradient(to bottom, var(--calculated_landing_background), var(--calculated_landing_background)) !important; }

.wide-logo {
  width: 10rem !important; }

.buttons-section-header {
  margin: 0 0.5rem; }

.home_header_button {
  height: 28px;
  font-weight: bold; }

.layout-mobile .home_header_button {
  height: 24px; }

@media (max-width: 768px) {
  .select-option-wrapper .ant-select-item,
  .select-option-wrapper .ant-select-item-option {
    font-size: 0.9rem !important; } }

@media (min-width: 1920px) {
  .home_app_bar .app_bar-icon-logo {
    height: 3rem;
    width: 5rem; }
  .home_app_bar .app_bar-icon {
    margin: 1rem 0 1rem; } }

@media (max-width: 767px) {
  .buttons-section-header .holla-button {
    font-size: 1.2rem !important; } }

.tools-button .selector-trigger.app_bar-pair-tab.tools {
  min-width: 10rem !important;
  max-width: 10rem !important; }

.check_title-container {
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .check_title-container .check_title-label {
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding: 0.25rem 0; }
  .check_title-container .custom_title-label {
    font-size: 1.3rem;
    line-height: 1.3rem;
    padding: 0.25rem 0 1.5rem 0;
    text-align: center; }
  .check_title-container .check_title-icon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; }
  .check_title-container .check_title-img {
    height: 4rem;
    width: 4rem; }
  .check_title-container .check_title-svg {
    height: 4rem;
    width: 4rem; }
    .check_title-container .check_title-svg svg {
      max-width: 100%;
      height: 4rem;
      width: 4rem; }
      .check_title-container .check_title-svg svg .deposit-history-1,
      .check_title-container .check_title-svg svg .withdrawals-history-1 {
        fill: var(--labels_important-active-labels-text-graphics); }
      .check_title-container .check_title-svg svg .deposit-history-3,
      .check_title-container .check_title-svg svg .withdrawals-history-3 {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
    .check_title-container .check_title-svg .st0,
    .check_title-container .check_title-svg .g1_4,
    .check_title-container .check_title-svg .g1_2 {
      stroke: var(--labels_secondary-inactive-label-text-graphics); }
    .check_title-container .check_title-svg .st0 {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .check_title-container .custom_title-img {
    height: 6rem;
    width: 6rem; }
  .check_title-container .custom_title-svg {
    height: 6rem;
    width: 6rem; }
    .check_title-container .custom_title-svg svg {
      max-width: 100%;
      height: 6rem;
      width: 6rem; }
      .check_title-container .custom_title-svg svg .verification-email,
      .check_title-container .custom_title-svg svg .verification-id,
      .check_title-container .custom_title-svg svg .verification-document,
      .check_title-container .custom_title-svg svg .verification-phone,
      .check_title-container .custom_title-svg svg .verification-bank,
      .check_title-container .custom_title-svg svg .notification-setting,
      .check_title-container .custom_title-svg svg .language-setting,
      .check_title-container .custom_title-svg svg .interface-setting,
      .check_title-container .custom_title-svg svg .chat-setting,
      .check_title-container .custom_title-svg svg .audio-setting {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
    .check_title-container .custom_title-svg .st0,
    .check_title-container .custom_title-svg .g1_4,
    .check_title-container .custom_title-svg .g1_2 {
      stroke: var(--labels_secondary-inactive-label-text-graphics); }
    .check_title-container .custom_title-svg .st0 {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .check_title-container .check_title-notification {
    position: absolute;
    right: 0;
    top: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1.5rem;
    background-color: var(--specials_notifications-alerts-warnings);
    color: var(--calculated_specials_notifications-alerts-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold; }

.panel-information-row {
  background-color: var(--labels_fields);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  flex: 1; }

.panel-information-row-disable {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.verification-status-container {
  position: relative;
  top: 15px; }

.verification-stauts {
  width: 1rem; }
  .verification-stauts.incomplete svg circle, .verification-stauts.pending svg circle {
    fill: var(--specials_pending-waiting-caution); }
  .verification-stauts.incomplete svg path,
  .verification-stauts.incomplete svg polygon,
  .verification-stauts.incomplete svg polyline, .verification-stauts.pending svg path,
  .verification-stauts.pending svg polygon,
  .verification-stauts.pending svg polyline {
    fill: var(--calculated_specials_notifications-pending-text); }
  .verification-stauts.rejected svg circle {
    fill: var(--specials_notifications-alerts-warnings); }
  .verification-stauts.rejected svg path,
  .verification-stauts.rejected svg polygon,
  .verification-stauts.rejected svg polyline {
    fill: var(--calculated_specials_notifications-alerts-text); }
  .verification-stauts.verified svg circle {
    fill: var(--specials_checks-okay-done); }
  .verification-stauts.verified svg path,
  .verification-stauts.verified svg polygon,
  .verification-stauts.verified svg polyline {
    fill: var(--calculated_specials_notifications-success-text); }

.empty-notification {
  height: 1.5rem; }

.header_title-wrapper {
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  position: relative; }
  .header_title-wrapper .header_title-children {
    font-size: 1.1rem;
    color: var(--labels_secondary-inactive-label-text-graphics);
    padding: 0.5rem 0; }
  .header_title-wrapper .header_title-icon {
    width: 4rem; }
    .header_title-wrapper .header_title-icon svg .verification-email,
    .header_title-wrapper .header_title-icon svg .verification-id,
    .header_title-wrapper .header_title-icon svg .verification-document,
    .header_title-wrapper .header_title-icon svg .verification-phone,
    .header_title-wrapper .header_title-icon svg .verification-bank {
      fill: var(--labels_important-active-labels-text-graphics); }

.tab_item-deactive:hover {
  opacity: 0.5; }

.custom-tab-wrapper .tab_item {
  color: var(--labels_secondary-inactive-label-text-graphics);
  height: 11.5rem;
  margin: 1rem;
  max-width: 10rem;
  min-width: 10rem; }

.custom-tab-wrapper .tab_item-active {
  transition: 0.3s;
  border: 1px solid var(--calculated_important-border);
  color: var(--labels_important-active-labels-text-graphics) !important;
  font-weight: bold; }
  .custom-tab-wrapper .tab_item-active svg path {
    opacity: 1; }
    .custom-tab-wrapper .tab_item-active svg path .st0,
    .custom-tab-wrapper .tab_item-active svg path .g1_4,
    .custom-tab-wrapper .tab_item-active svg path .g1_2 {
      fill: var(--labels_important-active-labels-text-graphics) !important; }
    .custom-tab-wrapper .tab_item-active svg path .st0 {
      stroke: var(--labels_important-active-labels-text-graphics) !important; }
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-email,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-id,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-document,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-phone,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-bank,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .notification-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .language-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .interface-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .chat-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .audio-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .fill_secondary-color {
    fill: var(--labels_important-active-labels-text-graphics); }

.mobile_tab-wrapper {
  border-top: 1px solid var(--calculated_secondary-border);
  color: var(--labels_secondary-inactive-label-text-graphics);
  padding: 0.5rem;
  font-size: 1.2rem; }
  .mobile_tab-wrapper .custom_tab_icon-mobile {
    width: 4rem !important; }
    .mobile_tab-wrapper .custom_tab_icon-mobile svg {
      width: 4rem !important;
      height: 4rem !important; }
  .mobile_tab-wrapper .custom_tab_status-icon {
    width: 1.7rem; }
  .mobile_tab-wrapper .mobile-tab-title {
    font-size: 2rem;
    font-weight: bold; }
  .mobile_tab-wrapper .verified {
    color: var(--specials_checks-okay-done); }
  .mobile_tab-wrapper .pending {
    color: var(--specials_pending-waiting-caution); }
  .mobile_tab-wrapper .incompleted {
    color: var(--specials_pending-waiting-caution); }
  .mobile_tab-wrapper .rejected {
    color: var(--specials_notifications-alerts-warnings); }

.mobile_tab_last {
  border-bottom: 1px solid var(--calculated_secondary-border); }

.mobile_tab_last-active {
  border-bottom: 2px solid var(--calculated_secondary-border) !important; }

.active_mobile_tab {
  color: var(--labels_secondary-inactive-label-text-graphics);
  border-top: 2px solid var(--calculated_secondary-border);
  border-bottom: 1px solid var(--calculated_secondary-border); }
  .active_mobile_tab svg .verification-email,
  .active_mobile_tab svg .verification-id,
  .active_mobile_tab svg .verification-document,
  .active_mobile_tab svg .verification-phone,
  .active_mobile_tab svg .verification-bank,
  .active_mobile_tab svg .notification-setting,
  .active_mobile_tab svg .language-setting,
  .active_mobile_tab svg .interface-setting,
  .active_mobile_tab svg .chat-setting,
  .active_mobile_tab svg .audio-setting,
  .active_mobile_tab svg .fill_secondary-color {
    fill: var(--labels_important-active-labels-text-graphics); }

.direction_ltr .check_title-container {
  font-family: "Raleway"; }

.layout-mobile .mobile_tab-wrapper .status_txt {
  font-size: 13px; }

.wallet_section-wrapper {
  font-size: 1rem;
  padding: 0.25rem 0; }
  .wallet_section-wrapper .wallet_section-content > * {
    margin-bottom: 0.5rem; }
    .wallet_section-wrapper .wallet_section-content > *:last-child {
      margin-bottom: 0; }
  .wallet_section-wrapper.wallet_section-total_asset > * {
    margin: 0.5rem 0; }

@keyframes outer-round-anim {
  0% {
    transform: scale(0.5, 0.5);
    background-color: var(--calculated_secondary-border);
    opacity: 0.5; }
  10% {
    background-color: var(--calculated_secondary-border);
    opacity: 0.3; }
  30% {
    background-color: var(--calculated_secondary-border);
    opacity: 0.2; }
  50% {
    transform: scale(1.5, 1.5);
    background-color: var(--calculated_secondary-border); }
  70% {
    background-color: var(--calculated_secondary-border);
    opacity: 0; }
  100% {
    transform: scale(1, 1);
    background-color: var(--calculated_secondary-border);
    opacity: 0; } }

@keyframes mymove {
  10% {
    background-color: var(--base_wallet-sidebar-and-popup);
    opacity: 0.5; }
  30% {
    background-color: var(--base_wallet-sidebar-and-popup);
    opacity: 0.3; }
  50% {
    background-color: var(--calculated_secondary-border);
    opacity: 0.5; }
  70% {
    background-color: var(--calculated_secondary-border);
    opacity: 0.3; }
  100% {
    background-color: var(--base_wallet-sidebar-and-popup);
    opacity: 0.2; } }

.wallet-wrapper .icon_title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.wallet-wrapper .donut-container {
  height: 18rem; }
  .wallet-wrapper .donut-container .donut-label-pair {
    font-size: 0.8rem; }
  .wallet-wrapper .donut-container .rounded-loading {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border: 1px solid #808080;
    background-color: var(--calculated_secondary-border) !important;
    animation: outer-round-anim 3s ease infinite; }
  .wallet-wrapper .donut-container .inner-round {
    background-color: var(--base_wallet-sidebar-and-popup);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto;
    border: 1px solid #808080; }

.wallet_section-title {
  text-transform: uppercase;
  color: var(--labels_secondary-inactive-label-text-graphics);
  font-size: 1rem; }
  .wallet_section-title > * {
    margin: 0.5rem; }

.wallet_section-title-amount {
  color: var(--labels_secondary-inactive-label-text-graphics);
  font-size: 1rem; }

.wallet_section-total_asset {
  font-size: 1rem;
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .wallet_section-total_asset > span {
    font-size: 1.8rem;
    line-height: 1; }
  .wallet_section-total_asset:first-child {
    margin-bottom: 0; }
  .wallet_section-total_asset:last-child {
    margin-top: 0; }

.wallet_link {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  text-transform: uppercase; }

.direction_ltr .sidebar_section-container .wallet-wrapper .accordion_section_title .wallet_section-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-right: none !important;
  border-top: 0.3rem solid transparent !important;
  border-bottom: 0.3rem solid transparent !important;
  border-left: 0.3rem solid var(--labels_secondary-inactive-label-text-graphics);
  margin-right: -0.3rem;
  right: -0.3rem;
  top: calc(50% - 0.3rem); }

.direction_ltr .sidebar_section-container .wallet-wrapper .accordion_section--open .accordion_section_title .wallet_section-title {
  color: var(--labels_important-active-labels-text-graphics); }
  .direction_ltr .sidebar_section-container .wallet-wrapper .accordion_section--open .accordion_section_title .wallet_section-title:after {
    border-left-color: var(--labels_important-active-labels-text-graphics); }

.wallet-wrapper .icon-title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.wallet-wrapper .loading-txt {
  color: var(--labels_secondary-inactive-label-text-graphics) !important;
  margin-bottom: 1rem; }

.wallet-wrapper .loading-row-anime {
  height: 20px;
  border-radius: 7px;
  width: 100%;
  margin-bottom: 1rem;
  animation: mymove 3s infinite;
  animation-duration: 2s; }

.direction_rtl .sidebar_section-container .wallet-wrapper .accordion_section_title .wallet_section-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: none !important;
  border-top: 0.3rem solid transparent !important;
  border-bottom: 0.3rem solid transparent !important;
  border-right: 0.3rem solid var(--labels_secondary-inactive-label-text-graphics);
  margin-left: -0.3rem;
  left: -0.3rem;
  top: calc(50% - 0.3rem); }

.direction_rtl .sidebar_section-container .wallet-wrapper .accordion_section--open .accordion_section_title .wallet_section-title {
  color: var(--labels_important-active-labels-text-graphics); }
  .direction_rtl .sidebar_section-container .wallet-wrapper .accordion_section--open .accordion_section_title .wallet_section-title:after {
    border-right-color: var(--labels_important-active-labels-text-graphics); }

@media (min-width: 1920px) {
  .wallet-wrapper .donut-container-empty {
    height: 14rem !important; }
  .wallet-wrapper .donut-container {
    height: 20rem; }
    .wallet-wrapper .donut-container .donut-label-pair {
      font-size: 1rem; } }

.sidebar_hub-wrapper {
  overflow-y: scroll;
  border-bottom: 4px solid var(--calculated_super-pale_label-text-graphics); }
  .sidebar_hub-wrapper .currency-selector .tab-action {
    height: 36px; }
    .sidebar_hub-wrapper .currency-selector .tab-action .tab-colors {
      height: 6px; }
    .sidebar_hub-wrapper .currency-selector .tab-action.not-active {
      background-color: var(--labels_inactive-button);
      position: relative;
      color: var(--labels_secondary-inactive-label-text-graphics); }
      .sidebar_hub-wrapper .currency-selector .tab-action.not-active:after {
        content: '';
        position: absolute;
        height: 1px;
        bottom: 0;
        right: -1px;
        left: -1px;
        border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics);
        background-color: var(--calculated_super-pale_label-text-graphics); }
    .sidebar_hub-wrapper .currency-selector .tab-action.tab-active {
      font-weight: bold;
      position: relative;
      background-color: var(--base_background); }
      .sidebar_hub-wrapper .currency-selector .tab-action.tab-active:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-right: 1px solid var(--calculated_super-pale_label-text-graphics);
        border-left: 1px solid var(--calculated_super-pale_label-text-graphics); }
      .sidebar_hub-wrapper .currency-selector .tab-action.tab-active:first-child:after {
        border-left: none; }
      .sidebar_hub-wrapper .currency-selector .tab-action.tab-active:last-child:after {
        border-right: none; }
  .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section {
    border-top: 1px solid var(--calculated_super-pale_label-text-graphics); }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section:not(:last-child) {
      margin-bottom: 1.5rem; }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section > div:nth-child(n + 2) {
      margin-top: 1rem; }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-header {
      padding: 0.5rem 0.25rem; }
      .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-header .sidebar_hub-section-icon {
        width: 2rem;
        height: 2rem; }
        .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-header .sidebar_hub-section-icon svg path {
          fill: transparent;
          stroke: var(--labels_secondary-inactive-label-text-graphics); }
      .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-header .sidebar_hub-section-title {
        font-size: 1.3rem;
        margin: 0 0.5rem;
        color: var(--labels_secondary-inactive-label-text-graphics); }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-content .accordion_wrapper .accordion_section {
      border-color: var(--labels_secondary-inactive-label-text-graphics); }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section.section-active {
      border-top-width: 2px;
      border-color: var(--labels_important-active-labels-text-graphics); }
      .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section.section-active .sidebar_hub-section-header .sidebar_hub-section-icon svg path {
        fill: var(--labels_important-active-labels-text-graphics);
        stroke: var(--labels_important-active-labels-text-graphics); }
      .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section.section-active .sidebar_hub-section-header .sidebar_hub-section-title {
        color: var(--labels_important-active-labels-text-graphics);
        font-weight: bold; }
  .sidebar_hub-wrapper .sign_up-btn,
  .sidebar_hub-wrapper .log_in-btn {
    background: var(--specials_buttons-links-and-highlights) !important;
    color: var(--labels_important-active-labels-text-graphics) !important;
    border: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .sidebar_hub-wrapper .sidebar_hub-trade .sidebar_hub-button.not-active {
    background: var(--base_background) !important;
    color: var(--labels_important-active-labels-text-graphics) !important;
    border: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .sidebar_hub-wrapper .sidebar_hub-trade .sidebar_hub-button.active {
    background: var(--labels_important-active-labels-text-graphics) !important;
    border: 1px solid var(--labels_important-active-labels-text-graphics); }

.active-wallet .wallet_section-total_asset,
.active-wallet .wallet_section-title,
.active-trade .wallet_section-total_asset,
.active-trade .wallet_section-title,
.active-quick-trade .wallet_section-total_asset,
.active-quick-trade .wallet_section-title {
  color: var(--labels_important-active-labels-text-graphics); }

.direction_ltr .sidebar_hub-wrapper .currency-selector {
  flex-direction: row; }

.direction_rtl .sidebar_hub-wrapper .currency-selector {
  flex-direction: row-reverse; }

@media only screen and (max-width: 991px) {
  .sidebar_hub-wrapper .sidebar_hub-trade {
    flex-direction: column; }
    .sidebar_hub-wrapper .sidebar_hub-trade .separator {
      height: 1rem;
      width: 100%; } }

.notification-wrapper {
  min-width: 30rem;
  max-width: 40rem; }
  .notification-wrapper .notification-title-wrapper {
    height: 15rem;
    padding: 0.5rem;
    border-bottom: 1px solid var(--calculated_important-border); }
    .notification-wrapper .notification-title-wrapper .notification-title-icon {
      max-height: 8rem;
      margin: 2rem 0; }
      .notification-wrapper .notification-title-wrapper .notification-title-icon svg {
        width: 8rem;
        height: 8rem; }
        .notification-wrapper .notification-title-wrapper .notification-title-icon svg path {
          fill: var(--labels_important-active-labels-text-graphics); }
        .notification-wrapper .notification-title-wrapper .notification-title-icon svg polyline {
          fill: transparent; }
    .notification-wrapper .notification-title-wrapper .notification-title-text {
      flex: 0;
      text-align: center;
      font-size: 1.3rem;
      color: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .notification-content-wrapper {
    position: relative;
    font-size: 1.1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .notification-wrapper .notification-content-wrapper .notification-content-title.with-border-bottom {
      border-bottom: 1px solid var(--calculated_important-border); }
    .notification-wrapper .notification-content-wrapper.notification_verification {
      text-align: center; }
      .notification-wrapper .notification-content-wrapper.notification_verification .notification-content-title {
        text-transform: capitalize; }
      .notification-wrapper .notification-content-wrapper.notification_verification .notification-content-information {
        padding: 1rem; }
    .notification-wrapper .notification-content-wrapper.logout-notification, .notification-wrapper .notification-content-wrapper.contact-form-notification {
      text-align: center; }
      .notification-wrapper .notification-content-wrapper.logout-notification .notification-content-information, .notification-wrapper .notification-content-wrapper.contact-form-notification .notification-content-information {
        margin: 3rem auto; }
    .notification-wrapper .notification-content-wrapper.logout-notification .notification-content-icon {
      width: 7rem;
      margin: 4rem auto; }
    .notification-wrapper .notification-content-wrapper.contact-form-notification .notification-content-title {
      font-size: 3.5rem;
      text-transform: uppercase;
      font-weight: normal; }
    .notification-wrapper .notification-content-wrapper .notification-content-icon {
      width: 10rem;
      max-height: 10rem;
      margin: 1rem auto; }
    .notification-wrapper .notification-content-wrapper .notification-content-information {
      width: 100%;
      margin: 1rem 0;
      font-weight: bold; }
      .notification-wrapper .notification-content-wrapper .notification-content-information .text_disabled {
        color: var(--labels_secondary-inactive-label-text-graphics); }
    .notification-wrapper .notification-content-wrapper .notification-content-header {
      position: relative;
      font-size: 1.3rem;
      color: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .notification-information {
    padding: 1rem 0;
    font-size: 1rem; }
  .notification-wrapper .trade-notification,
  .notification-wrapper .new-order-notification,
  .notification-wrapper .order-notification {
    margin: 1rem; }
    .notification-wrapper .trade-notification .notification-content-icon,
    .notification-wrapper .new-order-notification .notification-content-icon,
    .notification-wrapper .order-notification .notification-content-icon {
      margin-top: 0;
      margin-bottom: 2.5rem; }
    .notification-wrapper .trade-notification .notification-content-title,
    .notification-wrapper .new-order-notification .notification-content-title,
    .notification-wrapper .order-notification .notification-content-title {
      margin: 1rem 0; }
  .notification-wrapper .notification-buttons-wrapper .holla-button {
    font-size: 1.1rem;
    font-weight: bold; }
  .notification-wrapper .with_price-block_amount {
    margin: 3rem 0 4rem;
    line-height: 3.5rem; }
  .notification-wrapper .invite_friends_wrapper {
    width: 36rem; }
    .notification-wrapper .invite_friends_wrapper .user_refer_info {
      background-color: var(--calculated_specials_highlight-box);
      height: 5.5rem; }
    .notification-wrapper .invite_friends_wrapper .icon_title-wrapper .icon_title-svg {
      height: 8rem;
      width: 8rem; }
    .notification-wrapper .invite_friends_wrapper .icon_title-wrapper .icon_title-svg svg {
      height: 8rem;
      width: 8rem; }
    .notification-wrapper .invite_friends_wrapper .icon_title-wrapper .icon_title-text.title {
      font-size: 2rem; }
  .notification-wrapper svg .check-order-1,
  .notification-wrapper svg .check-order-2,
  .notification-wrapper svg .coin-btc-4,
  .notification-wrapper svg .coin-base-4 {
    stroke: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper svg .market-buy,
  .notification-wrapper svg .check-order-2,
  .notification-wrapper svg .coin-btc-1,
  .notification-wrapper svg .coin-btc-5,
  .notification-wrapper svg .coin-base-1,
  .notification-wrapper svg .coin-base-5,
  .notification-wrapper svg .email_sent,
  .notification-wrapper svg .limit-sell-03,
  .notification-wrapper svg .limit-sell-04,
  .notification-wrapper svg .limit-buy-03,
  .notification-wrapper svg .limit-buy-04,
  .notification-wrapper svg .refer-icon,
  .notification-wrapper svg .stake_icon {
    fill: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper svg .limit-sell-01,
  .notification-wrapper svg .limit-buy-01,
  .notification-wrapper svg .limit-buy-02,
  .notification-wrapper svg .limit-sell-02,
  .notification-wrapper svg .wallet-selected-2 {
    stroke: var(--labels_important-active-labels-text-graphics);
    fill: transparent; }
  .notification-wrapper svg g .wallet-selected-2 {
    stroke: transparent;
    fill: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .partial-fill {
    fill: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .limit-trade-tick {
    fill: var(--labels_secondary-inactive-label-text-graphics); }
  .notification-wrapper .new-order-notification svg {
    fill: var(--labels_important-active-labels-text-graphics); }
    .notification-wrapper .new-order-notification svg .generate_api {
      fill: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .notification-wrapper {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .notification-wrapper .notification-wrapper .notification-title-wrapper {
      border-bottom-color: var(--calculated_important-border); }
      .notification-wrapper .notification-wrapper .notification-title-wrapper .notification-title-icon svg .deposit-02,
      .notification-wrapper .notification-wrapper .notification-title-wrapper .notification-title-icon svg .deposit-complete-01,
      .notification-wrapper .notification-wrapper .notification-title-wrapper .notification-title-icon svg .deposit-complete-btc-01 {
        fill: var(--labels_important-active-labels-text-graphics); }
      .notification-wrapper .notification-wrapper .notification-title-wrapper .notification-title-text {
        color: var(--labels_important-active-labels-text-graphics); }
    .notification-wrapper .notification-wrapper .notification-content-information .text_disabled {
      color: var(--labels_secondary-inactive-label-text-graphics); }

.notification-wrapper .notification-content-wrapper.notification-withdrawal .notification-content-icon {
  width: 80% !important; }

.notification-wrapper .notification-content-wrapper.notification-withdrawal .notification-link-wrapper {
  font-size: 1rem; }

.direction_ltr .notification-title-text,
.direction_ltr .notification-withdrawal .notification-content-title {
  font-family: "Raleway Medium"; }

.layout-mobile .notification-wrapper {
  flex: 1;
  margin: auto; }
  .layout-mobile .notification-wrapper .notification-withdrawal {
    justify-content: space-evenly; }
  .layout-mobile .notification-wrapper .trade-notification,
  .layout-mobile .notification-wrapper .order-notification {
    margin: 0.75rem;
    flex-direction: row;
    color: var(--labels_important-active-labels-text-graphics); }
    .layout-mobile .notification-wrapper .trade-notification .notification-content-icon,
    .layout-mobile .notification-wrapper .order-notification .notification-content-icon {
      margin: 0;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      .layout-mobile .notification-wrapper .trade-notification .notification-content-icon svg,
      .layout-mobile .notification-wrapper .order-notification .notification-content-icon svg {
        width: 6rem;
        height: 6rem; }
    .layout-mobile .notification-wrapper .trade-notification .notification-content-title,
    .layout-mobile .notification-wrapper .order-notification .notification-content-title {
      margin: 0; }

.layout-mobile .trade_active_orders-wrapper {
  max-height: 35vh !important; }

.trade_post_icon {
  width: 1.5rem; }
  .trade_post_icon svg .wave-icon {
    fill: var(--labels_secondary-inactive-label-text-graphics); }

.post_header {
  font-weight: bold; }

.trade_announcement {
  font-size: 1.5rem; }

.announcement-notification-list-item {
  color: var(--labels_important-active-labels-text-graphics);
  border-bottom: 1px solid var(--calculated_secondary-border); }

.post-content .notifications_list-item-text a {
  color: var(--specials_buttons-links-and-highlights); }

.post-content .notifications_list-item-text span,
.post-content .notifications_list-item-text p {
  background-color: var(--base_wallet-sidebar-and-popup) !important; }

.post-content .notifications_list-item-timestamp {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.dropdown-content .dropdown-option {
  padding: 0; }

.dropdown-content.disabled {
  cursor: not-allowed;
  color: var(--labels_secondary-inactive-label-text-graphics); }

.dropdown-option-open {
  margin: 0px !important; }
  .dropdown-option-open img {
    height: 1rem;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem; }

.disabled .field-children {
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .disabled .field-children .dropdown-triangle:after {
    border: none; }

.dropdown-options-wrapper {
  position: absolute;
  top: 100%;
  width: 100%;
  background: var(--base_background);
  z-index: 10000;
  max-height: 10rem;
  overflow-y: auto;
  border: 1px solid var(--calculated_secondary-border);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top: 0; }
  .dropdown-options-wrapper.dropdown-date {
    max-height: 15rem; }

.dropdown-option {
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center; }
  .dropdown-option > .icon {
    max-height: 1rem; }
  .dropdown-option .option-icon {
    width: 0.8rem; }

.direction_ltr .dropdown-options-wrapper {
  left: 0; }

.direction_ltr .dropdown-option > .icon {
  margin-right: 0.25rem; }

.direction_rtl .dropdown-options-wrapper {
  right: 0; }

.direction_rtl .dropdown-option > .icon {
  margin-left: 0.25rem; }

.layout-mobile .dropdown-option > .icon {
  max-height: 1.8rem; }

.layout-mobile .dropdown-option .option-icon {
  width: 1.8rem; }

.field-wrapper {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin: 1rem 0; }
  .field-wrapper.error {
    color: var(--specials_notifications-alerts-warnings);
    margin-bottom: 15px; }
  .field-wrapper.inline {
    display: flex;
    align-items: flex-end; }
    .field-wrapper.inline > div {
      flex: 1; }
      .field-wrapper.inline > div.field-error-content {
        padding-bottom: 0.5rem; }
  .field-wrapper .checkfield-input-wrapper {
    align-items: center; }
  .field-wrapper .multiple-actions-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 1.25rem; }
    .field-wrapper .multiple-actions-wrapper .action_notification-wrapper {
      position: static !important; }
    .field-wrapper .multiple-actions-wrapper .action_notification-wrapper:not(:last-child):after {
      content: '|';
      padding: 0 0.5rem;
      color: var(--calculated_important-border); }

.field-label-wrapper {
  display: flex;
  align-items: center; }
  .field-label-wrapper > div {
    flex: 1; }
  .field-label-wrapper .field_warning_wrapper {
    width: 80%; }

.field-label {
  color: var(--labels_important-active-labels-text-graphics);
  font-size: 14px; }

.field-content {
  position: relative;
  display: flex;
  flex-direction: column; }

.field-children {
  padding: 0.4rem 1rem 0.4rem;
  position: relative; }
  .field-children.custom {
    padding: 0; }
  .field-children .field-valid {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    bottom: 0.25rem; }
    .field-children .field-valid svg {
      width: 1.25rem;
      height: 1.25rem; }
  .field-children .clear-field {
    width: 1.25rem !important;
    height: 1.25rem !important;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    right: 0px;
    bottom: 0.5rem; }
    .field-children .clear-field svg {
      width: 1.25rem;
      height: 1.25rem; }

.input-box-field {
  width: 100%;
  height: 38px;
  padding: 10px 10px;
  border-radius: 2px;
  border: 1px solid var(--calculated_important-border);
  background-color: var(--base_wallet-sidebar-and-popup); }
  .input-box-field .input_field-input::placeholder {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 14px; }

.field-content-outline:before {
  content: '';
  height: 1.5px;
  width: 100%;
  bottom: 0;
  position: absolute;
  transition: 0.3s ease all;
  background: var(--labels_secondary-inactive-label-text-graphics); }

.field-content-outline:after {
  content: '';
  height: 1.5px;
  width: 0;
  bottom: 0;
  position: absolute;
  transition: 0.3s ease all;
  background: var(--labels_secondary-inactive-label-text-graphics); }

.field-content-outline.focused:after {
  width: 100%;
  background: var(--labels_important-active-labels-text-graphics); }

.field-wrapper.error .field-content-outline:before {
  background: var(--specials_notifications-alerts-warnings); }

.field-error-content {
  position: relative;
  display: flex;
  align-items: baseline;
  min-height: 1.25rem; }
  .field-error-content.field-error-hidden {
    visibility: hidden; }

.field-error-icon {
  height: 1.25rem;
  width: 1.25rem; }

.dumb-field-wrapper {
  color: var(--specials_buttons-links-and-highlights); }
  .dumb-field-wrapper > .field-content > .field-children {
    padding-left: 0.25rem; }
  .dumb-field-wrapper .field-children {
    padding-bottom: 0; }
  .dumb-field-wrapper .address-line {
    word-break: break-all; }
    .dumb-field-wrapper .address-line.single-action {
      padding-right: 4rem; }
    .dumb-field-wrapper .address-line.multi-action {
      padding-right: 6rem; }
  .dumb-field-wrapper .copy-wrapper {
    top: 0;
    position: unset; }

.field-wrapper .action_notification-wrapper .action_notification-svg svg {
  width: 1rem;
  height: 1rem; }

.field_warning_wrapper {
  font-size: 1rem;
  color: var(--specials_notifications-alerts-warnings); }

.direction_ltr .field-error-text {
  padding-left: 0.25rem; }

.direction_ltr .field_warning_text {
  padding-left: 0.25rem; }

.direction_ltr .field-children:not(.padding_zero).valid > *:not(:last-child) {
  padding-right: 1.25rem; }

.direction_ltr .field-children:not(.padding_zero) .field-valid {
  right: 0; }

.direction_ltr .field-wrapper.inline .field-error-content {
  margin: 0.5rem 0 0 0; }

.direction_ltr .field-wrapper.inline input {
  padding-right: 0; }

.direction_ltr .field-wrapper.inline .field-valid {
  right: -1.75rem; }

.direction_ltr .field-wrapper .action_notification-wrapper.with-tick-icon {
  right: 1.25rem; }

.direction_rtl .field-error-text {
  padding-right: 0.25rem; }

.direction_rtl .field_warning_text {
  padding-right: 0.25rem; }

.direction_rtl .field-children:not(.padding_zero).valid > *:not(:last-child) {
  padding-left: 1.25rem; }

.direction_rtl .field-children:not(.padding_zero) .field-valid {
  left: 0; }

.direction_rtl .field-wrapper.inline .field-error-content {
  margin-right: 0.5rem; }

.direction_rtl .field-wrapper.inline input {
  padding-left: 0; }

.direction_rtl .field-wrapper.inline .field-valid {
  left: -1.75rem; }

.direction_rtl .field-wrapper .action_notification-wrapper.with-tick-icon {
  left: 1.25rem; }

.direction_rtl .field-wrapper .multiple-actions-wrapper {
  right: auto;
  left: 1.25rem; }

.layout-mobile.direction_ltr .field-wrapper.field-valid.with-notification .action_notification-wrapper {
  right: 1.5rem; }

.layout-mobile.direction_rtl .field-wrapper.field-valid.with-notification .action_notification-wrapper {
  left: 1.5rem; }

.layout-mobile .field-label-wrapper > div {
  flex: 1;
  width: 100%; }

.layout-mobile .field-label-wrapper .field_warning_wrapper {
  width: 100%; }

.layout-mobile .field_warning_wrapper {
  padding-bottom: 1rem; }

.mainContainer {
  display: flex;
  width: fit-content;
  margin: auto;
  position: relative;
  justify-content: center;
  align-items: center; }
  .mainContainer input {
    width: 30px;
    height: 30px;
    border-radius: 0.5rem;
    border: 1px solid var(--calculated_secondary-border);
    background: var(--base_background);
    text-align: center;
    font-size: large; }
  .mainContainer .masterInput {
    position: absolute;
    width: 100%;
    top: 8px;
    opacity: 0 !important; }
  .mainContainer .seperator {
    width: 2rem;
    border: 1px solid var(--labels_secondary-inactive-label-text-graphics);
    background: var(--labels_secondary-inactive-label-text-graphics); }
  .mainContainer .active {
    border: 1px solid var(--labels_secondary-inactive-label-text-graphics) !important; }
  .mainContainer .error {
    border: 1px solid var(--specials_notifications-alerts-warnings) !important; }
  .mainContainer .group {
    padding: 1rem; }
    .mainContainer .group input {
      margin: 0 0.25rem; }

.input_icon {
  margin-right: 0.25rem;
  width: 1.2rem; }

.custom-field-width {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%; }

.input_field-input {
  width: 100%;
  position: relative;
  background: transparent;
  border: none !important;
  line-height: 1rem; }
  .input_field-input:focus {
    outline: none; }
  .input_field-input::placeholder {
    color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .input_field-input:disabled {
    cursor: not-allowed;
    opacity: 0.5; }

.dropdown-triangle:after {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 0.25rem solid var(--labels_important-active-labels-text-graphics);
  border-top: 0.25rem solid transparent;
  position: absolute;
  bottom: 0.35rem; }

.datefield-wrapper {
  position: relative; }
  .datefield-wrapper .field-children:last-child .year .field-content,
  .datefield-wrapper .field-children:last-child .month .field-content,
  .datefield-wrapper .field-children:last-child .day .field-content {
    border: 1px solid var(--calculated_important-border);
    background-color: var(--base_wallet-sidebar-and-popup);
    padding: 6px 0; }
  .datefield-wrapper .datefield-values-wrapper {
    display: flex; }
    .datefield-wrapper .datefield-values-wrapper > * {
      flex: 1; }
    .datefield-wrapper .datefield-values-wrapper .datefield-toggle.fa {
      min-width: 10rem; }
    .datefield-wrapper .datefield-values-wrapper .datefield-toggle.en {
      flex: 0;
      min-width: 4rem; }
  .datefield-wrapper .SingleDatePicker {
    position: absolute; }
  .datefield-wrapper .SingleDatePickerInput {
    display: none; }
  .datefield-wrapper .datefield-farsi_picker #farsiDatePicker {
    display: none; }

.direction_ltr .datefield-wrapper .datefield-values-wrapper {
  padding-right: 0.25rem !important; }
  .direction_ltr .datefield-wrapper .datefield-values-wrapper > *:not(:last-child) {
    margin-right: 1rem; }
  .direction_ltr .datefield-wrapper .datefield-values-wrapper .dropdown-triangle::after {
    bottom: 0 !important; }

.direction_rtl .datefield-wrapper .datefield-values-wrapper {
  padding-left: 0.25rem !important; }
  .direction_rtl .datefield-wrapper .datefield-values-wrapper > *:not(:last-child) {
    margin-left: 1rem; }

.placeholder {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--labels_secondary-inactive-label-text-graphics); }

.no_bottom {
  bottom: 0; }

.file_wrapper,
.multiple_file_wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 0 !important; }
  .file_wrapper > .action_notification-wrapper,
  .multiple_file_wrapper > .action_notification-wrapper {
    position: relative;
    top: 0 !important; }
  .file_wrapper .paper-clip-icon .action_notification-svg,
  .multiple_file_wrapper .paper-clip-icon .action_notification-svg {
    background-color: var(--specials_buttons-links-and-highlights);
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: flex;
    margin-left: 5px; }
    .file_wrapper .paper-clip-icon .action_notification-svg svg,
    .multiple_file_wrapper .paper-clip-icon .action_notification-svg svg {
      width: 0.8rem !important;
      height: 0.8rem !important; }

.multiple_file_wrapper {
  display: unset; }

.checkfield-wrapper {
  margin: 1rem 0; }
  .checkfield-wrapper .checkfield-label {
    padding: 0 0.25rem;
    margin-bottom: 0;
    margin-left: 0.25rem;
    vertical-align: middle;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .checkfield-wrapper .checkfield-input {
    margin-top: 2px; }

.toggle_button-wrapper .toggle-content {
  padding: 0 1rem;
  text-transform: uppercase;
  padding: 0 0.5rem;
  font-size: 1rem;
  color: var(--labels_secondary-inactive-label-text-graphics);
  height: 22px;
  font-family: "Raleway Medium"; }
  .toggle_button-wrapper .toggle-content .selected {
    font-family: "Raleway Extrabold";
    font-weight: bold;
    color: var(--labels_important-active-labels-text-graphics); }
  .toggle_button-wrapper .toggle-content .toggle-action_button {
    border: 2px solid var(--calculated_important-border);
    height: 100%;
    width: 56px;
    border-radius: 14px;
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer; }
    .toggle_button-wrapper .toggle-content .toggle-action_button.left {
      opacity: 0.3; }
      .toggle_button-wrapper .toggle-content .toggle-action_button.left .option-text {
        position: relative;
        left: 23px; }
      .toggle_button-wrapper .toggle-content .toggle-action_button.left .toggle-action_button-display {
        right: calc(100% - 18px); }
    .toggle_button-wrapper .toggle-content .toggle-action_button.right .option-text {
      position: relative;
      left: 3px;
      color: var(--labels_important-active-labels-text-graphics); }
    .toggle_button-wrapper .toggle-content .toggle-action_button.right .toggle-action_button-display {
      right: 0; }
    .toggle_button-wrapper .toggle-content .toggle-action_button .toggle-action_button-display {
      position: absolute;
      transition: 0.5s;
      background: var(--labels_important-active-labels-text-graphics);
      height: 14px;
      width: 14px;
      border-radius: 50%;
      margin: 2px;
      top: 0; }

.toggle_button-wrapper .toggle-side_line {
  position: relative; }
  .toggle_button-wrapper .toggle-side_line::after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    background-color: var(--calculated_important-border);
    left: 0;
    right: 0;
    top: 50%; }

.direction_ltr .dropdown-triangle:after {
  border-right: 0.25rem solid var(--labels_important-active-labels-text-graphics);
  border-left: 0.25rem solid transparent;
  right: 3px; }

.direction_ltr .placeholder {
  padding-right: 0.5rem; }

.direction_rtl .dropdown-triangle:after {
  border-right: 0.25rem solid transparent;
  border-left: 0.25rem solid var(--labels_important-active-labels-text-graphics);
  left: 0px; }

.direction_rtl .placeholder {
  padding-left: 0.5rem; }

.direction_rtl .toggle_button-wrapper .toggle-content {
  font-family: inherit; }
  .direction_rtl .toggle_button-wrapper .toggle-content .selected {
    font-family: inherit; }

.toggle-disabled {
  cursor: not-allowed;
  opacity: 0.35; }

.layout-mobile .toggle_button-wrapper .toggle-content .toggle-action_button {
  width: 42px; }
  .layout-mobile .toggle_button-wrapper .toggle-content .toggle-action_button.left .option-text {
    left: 20px; }

.trade_input-wrapper {
  display: flex;
  flex-direction: column; }
  .trade_input-wrapper > * {
    flex: 1;
    position: relative; }
  .trade_input-wrapper .trade_input-label {
    padding-bottom: 0.25rem; }
  .trade_input-wrapper .trade_input-input-wrapper {
    position: relative; }
    .trade_input-wrapper .trade_input-input-wrapper > input {
      padding: 0.25rem;
      position: relative;
      width: 100%;
      height: 2.5rem;
      background: var(--labels_fields);
      border: 1px solid var(--calculated_secondary-border);
      border-radius: 2px;
      color: var(--labels_important-active-labels-text-graphics); }
      .trade_input-wrapper .trade_input-input-wrapper > input:focus {
        outline: none;
        border: 1.5px solid var(--specials_buttons-links-and-highlights); }
  .trade_input-wrapper .trade_input-input-currency {
    height: 2.5rem;
    position: absolute;
    top: 0;
    font-weight: bold;
    color: var(--calculated_important-border); }
  .trade_input-wrapper .initialize-animation {
    /* Safari 4.0 - 8.0 */
    /* Standard syntax */ }
    .trade_input-wrapper .initialize-animation > input {
      -webkit-animation-name: bounce;
      /* Safari 4.0 - 8.0 */
      -webkit-animation-duration: 3s;
      /* Safari 4.0 - 8.0 */
      animation-name: bounce;
      animation-duration: 3s;
      border: 1px solid var(--calculated_important-border); }

@-webkit-keyframes bounce {
  15% {
    border-color: var(--specials_buttons-links-and-highlights); }
  75% {
    border-color: var(--calculated_important-border); } }

@keyframes bounce {
  15% {
    border-color: var(--specials_buttons-links-and-highlights); }
  75% {
    border-color: var(--calculated_important-border); } }

.direction_ltr .trade_input-input-wrapper > input {
  padding-right: 2.5rem; }

.direction_ltr .trade_input-input-currency {
  right: 0; }

.direction_rtl .trade_input-input-wrapper > input {
  padding-left: 2.5rem; }

.direction_rtl .trade_input-input-currency {
  left: 0; }

.size-slider .ant-slider * {
  transition: 0.1s; }

.size-slider .ant-slider-with-marks {
  margin-bottom: 10px; }

.size-slider .ant-slider-rail,
.size-slider .ant-slider-dot {
  background-color: var(--labels_secondary-inactive-label-text-graphics);
  border-color: var(--labels_secondary-inactive-label-text-graphics); }

.size-slider .ant-slider-dot-active,
.size-slider .ant-slider-track,
.size-slider .ant-slider-handle {
  background-color: var(--specials_buttons-links-and-highlights);
  border-color: var(--specials_buttons-links-and-highlights); }

.trade-form-select {
  padding-bottom: 1.5rem;
  font-size: 11px !important; }

.ant-slider-mark-text {
  color: var(--labels_secondary-inactive-label-text-graphics);
  font-family: 'Open Sans' !important;
  font-size: 0.9rem !important; }

.ant-slider-mark-text-active {
  color: var(--labels_important-active-labels-text-graphics);
  font-weight: bold; }

.holla-button {
  width: 100%;
  font-size: 1rem;
  padding: 0 4px;
  background-color: var(--specials_buttons-links-and-highlights) !important; }
  .holla-button.button-success {
    background-color: var(--specials_checks-okay-done) !important; }
  .holla-button.button-fail {
    background-color: var(--specials_notifications-alerts-warnings) !important; }
  .holla-button.disabled {
    background-color: var(--labels_inactive-button) !important; }
    .holla-button.disabled .button-icon svg {
      fill: rgba(0, 0, 0, 0.38) !important; }
  .holla-button:active, .holla-button:focus {
    outline: none;
    border: none; }
  .holla-button .button-icon {
    position: relative;
    margin-right: 0.5rem; }
    .holla-button .button-icon svg {
      fill: #fff;
      margin-top: 0.5rem; }
  .holla-button .reverse-direction {
    flex-direction: row-reverse; }
    .holla-button .reverse-direction .button-icon {
      margin-right: 0;
      margin-left: 0.5rem; }

.checkbutton-wrapper {
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .checkbutton-wrapper .checkbutton-input-wrapper {
    width: 300px;
    padding: 10px;
    margin-top: 35px;
    justify-content: center;
    background-color: var(--specials_buttons-links-and-highlights); }
    .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--label {
      margin: 0;
      padding-left: 0.5rem; }
    .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--image,
    .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--loader {
      width: 2rem; }
    .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--image svg {
      width: 2rem;
      height: 2rem;
      stroke: var(--calculated_base_footer_text); }
  .checkbutton-wrapper .checkbutton-content-wrapper {
    margin-left: 2rem; }

.buttons-row > *:not(:last-child) {
  margin-right: 1rem; }

.direction_ltr .holla-button-font {
  font-family: "Raleway"; }

.direction_rtl .holla-button-font {
  font-family: inherit !important; }

.currency-wallet-btn {
  line-height: 1rem !important; }

.layout-mobile .currency-wallet-btn {
  line-height: 2.25rem !important; }

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100 !important;
  background-color: var(--calculated_base_modal-overlay) !important; }

.background .ReactModal__Content {
  padding: 0 !important;
  max-width: 100% !important;
  max-height: 100% !important; }
  .background .ReactModal__Content .action_notification-wrapper.close-button {
    margin: 0 !important; }

.background .icon_title-wrapper {
  margin-top: 0 !important; }

.menu .ReactModal__Content {
  padding: 0 !important;
  background-color: var(--base_background) !important; }

.dialog-content {
  padding-right: 2.5rem;
  padding-left: 2.5rem; }
  .dialog-content.background {
    background-size: 100% 100%;
    background-repeat: no-repeat; }
  .dialog-content.bottom {
    padding-bottom: 2.5rem; }

.ReactModal__Content {
  padding: 2.5rem !important;
  top: auto !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  max-width: calc(100% - 2.5rem);
  max-height: calc(100% - 2.5rem);
  overflow: auto;
  border: none !important;
  background-color: var(--base_wallet-sidebar-and-popup) !important; }
  .ReactModal__Content .action_notification-wrapper.close-button {
    margin-top: 1.5rem; }

.close-button.action_notification-wrapper .action_notification-image {
  width: 2rem;
  height: 2rem;
  fill: var(--labels_important-active-labels-text-graphics); }

.success_display-wrapper {
  min-width: 25rem;
  min-height: 30rem; }
  .success_display-wrapper > * {
    flex: 0; }

.success_display-content {
  height: auto;
  flex: 1; }

.success_display-content-image {
  width: 7.5rem;
  height: 7.5rem; }
  .success_display-content-image svg {
    width: 7.5rem;
    height: 7.5rem; }

.success_display-content-text {
  margin: 1rem 0; }

.direction_ltr .ReactModal__Content .action_notification-wrapper.close-button {
  margin-right: 1.5rem; }

.direction_rtl .ReactModal__Content .action_notification-wrapper.close-button {
  margin-left: 1.5rem; }

.app-dialog .ReactModal__Overlay {
  z-index: 110 !important; }

.app-dialog.full .ReactModal__Overlay {
  background-color: var(--base_wallet-sidebar-and-popup) !important; }

.app-dialog-flex .ReactModal__Overlay {
  z-index: 10000 !important; }

.layout-mobile .ReactModal__Overlay {
  background-color: transparent !important; }

.layout-mobile .success_display-wrapper {
  margin: auto; }

.layout-mobile .ReactModal__Content {
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  min-width: 100vw;
  max-width: 100vw;
  min-height: calc( 100vh - 4rem);
  max-height: calc( 100vh - 4rem);
  border-radius: 0 !important;
  padding: 0 !important; }
  .layout-mobile .ReactModal__Content .dialog-mobile-content {
    padding: 2.5rem !important;
    min-height: calc( 100vh - 8rem);
    max-height: calc( 100vh - 8rem);
    display: flex; }

.layout-mobile.LogoutModal .ReactModal__Content {
  top: 0 !important; }

.layout-mobile.dialog_full-screen .ReactModal__Content {
  top: 0 !important;
  min-height: 100vh;
  max-height: 100vh; }
  .layout-mobile.dialog_full-screen .ReactModal__Content .dialog-mobile-content {
    min-height: 100vh;
    max-height: 100vh; }

.layout-mobile.dialog_full-screen.login-dialog .dialog-mobile-content {
  align-items: center; }

.layout-mobile.compressed .ReactModal__Content {
  top: 0 !important;
  min-height: inherit;
  max-height: inherit;
  background: transparent !important; }
  .layout-mobile.compressed .ReactModal__Content .dialog-mobile-content {
    min-height: inherit;
    max-height: inherit; }

.layout-mobile.menu .ReactModal__Content {
  padding: 0 !important;
  background-color: var(--base_background) !important; }
  .layout-mobile.menu .ReactModal__Content .dialog-mobile-content {
    padding: 0 !important;
    display: block; }

.dialog-compressed-wrapper {
  background-color: var(--labels_important-active-labels-text-graphics);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0; }

.accordion_section {
  position: relative; }

.layout-mobile .holla-button-font {
  font-size: 1.2rem; }

.layout-mobile .accordion_wrapper .accordion_section .accordion_section_title {
  font-size: 1.75rem; }

.layout-mobile .accordion_wrapper .accordion_section .accordion_section_content {
  font-size: 13px !important; }

.layout-mobile .accordion_wrapper .action_notification-svg svg {
  width: 1.75rem !important;
  height: 1.75rem !important; }

.layout-mobile .accordion_wrapper .accordion_section_content {
  overflow-x: auto;
  font-size: 1.2rem; }
  .layout-mobile .accordion_wrapper .accordion_section_content .information_section {
    font-size: 1.2rem; }
  .layout-mobile .accordion_wrapper .accordion_section_content .field-wrapper {
    font-size: 1.2rem; }
  .layout-mobile .accordion_wrapper .accordion_section_content table tbody .table-row {
    font-size: 1.25rem; }
    .layout-mobile .accordion_wrapper .accordion_section_content table tbody .table-row .td-levelball .td-levelball-content {
      width: 2.5rem;
      height: 2.5rem; }
  .layout-mobile .accordion_wrapper .accordion_section_content table tbody td:not(:first-child) {
    padding: 1.5rem 0.25rem; }

.layout-mobile .direction_ltr .accordion_wrapper .accordion_section .accordion_section_content_text.with_arrow:after {
  border: solid var(--labels_secondary-inactive-label-text-graphics) !important;
  border-width: 1px 0 0 1px !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(131deg) !important;
  content: '';
  margin-right: -0.6rem;
  position: absolute;
  right: -0.7rem;
  top: calc(50% - 0.3rem); }

.layout-mobile .direction_ltr .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text:after {
  transform: rotate(-135deg) !important;
  right: -1.1rem;
  top: calc(50% - 0.38rem); }

.layout-mobile .direction_ltr .accordion_wrapper .accordion_section.disabled .accordion_section_content_text:after {
  border-left-color: var(--labels_secondary-inactive-label-text-graphics); }

.accordion_wrapper .accordion_section {
  border-top: 1px solid var(--calculated_important-border);
  font-size: 1.3rem;
  padding: 0; }
  .accordion_wrapper .accordion_section .action_notification-svg svg {
    width: 1rem;
    height: 1rem; }
  .accordion_wrapper .accordion_section .accordion_section_title {
    padding: 0.5rem 0;
    position: relative; }
    .accordion_wrapper .accordion_section .accordion_section_title .sidebar_hub-section-icon {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.5rem; }
      .accordion_wrapper .accordion_section .accordion_section_title .sidebar_hub-section-icon svg path {
        fill: transparent;
        stroke: var(--labels_secondary-inactive-label-text-graphics); }
    .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content {
      margin-bottom: 1.5rem; }
    .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text {
      color: var(--labels_important-active-labels-text-graphics); }
      .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text.with_arrow:after {
        border-left-color: var(--labels_important-active-labels-text-graphics) !important;
        border-top-color: var(--labels_important-active-labels-text-graphics) !important; }
    .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .sidebar_hub-section-icon svg path {
      fill: var(--labels_important-active-labels-text-graphics);
      stroke: var(--labels_important-active-labels-text-graphics); }
    .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .wallet_section-title-amount {
      color: var(--labels_important-active-labels-text-graphics) !important; }
  .accordion_wrapper .accordion_section .accordion_section_content_text {
    position: relative;
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .accordion_wrapper .accordion_section .accordion_section_content_text-subtitle {
    color: var(--labels_inactive-button); }
  .accordion_wrapper .accordion_section.disabled {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .accordion_wrapper .accordion_section:last-child {
    border-bottom: 1px solid var(--calculated_important-border); }
  .accordion_wrapper .accordion_section .accordion_section_content {
    font-size: 1.1rem;
    padding: 0; }
  .accordion_wrapper .accordion_section .action_notification-wrapper {
    top: calc(50% - 0.65rem);
    margin: 0 0.5rem; }

.direction_ltr .accordion_wrapper .accordion_section .accordion_section_content_text.with_arrow:after {
  border: solid var(--labels_secondary-inactive-label-text-graphics) !important;
  border-width: 2px 0 0 2px !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(131deg) !important;
  content: '';
  margin-right: -0.6rem;
  position: absolute;
  right: -0.7rem;
  top: calc(50% - 0.3rem); }

.direction_ltr .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text:after {
  transform: rotate(-135deg) !important; }

.direction_ltr .accordion_wrapper .accordion_section.disabled .accordion_section_content_text:after {
  border-left-color: var(--labels_secondary-inactive-label-text-graphics); }

.direction_rtl .accordion_wrapper .accordion_section .accordion_section_content_text.with_arrow:after {
  border: solid var(--labels_secondary-inactive-label-text-graphics);
  border-width: 2px 0 0 2px !important;
  display: inline-block !important;
  padding: 3px !important;
  transform: rotate(-50deg) !important;
  content: '';
  left: -0.7rem;
  top: calc(50% - 0.3rem); }

.direction_rtl .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text:after {
  transform: rotate(-135deg) !important;
  top: calc(50% - 0.4rem); }

.direction_rtl .accordion_wrapper .accordion_section .accordion_section_title .sidebar_hub-section-icon {
  margin-left: 0.5rem; }

.direction_rtl .accordion_wrapper .accordion_section.disabled .accordion_section_content_text:after {
  border-top-color: var(--calculated_secondary-border); }

.header-wrapper {
  display: flex;
  padding: 0.75rem 0 0.25rem 0; }

.sub-header {
  color: var(--specials_buttons-links-and-highlights);
  cursor: pointer;
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  margin-left: auto !important;
  font-size: 1rem; }

.link-separator {
  border-right: 1px solid var(--calculated_secondary-border);
  height: 15px; }

.sub-header:hover,
.img-content {
  opacity: 0.8; }

.header-title {
  font-size: 1.3rem; }

.action_notification-wrapper,
.trade-notify-wrapper {
  display: flex;
  align-items: center; }
  .action_notification-wrapper:hover,
  .trade-notify-wrapper:hover {
    opacity: 0.75; }
  .action_notification-wrapper .notification-info,
  .trade-notify-wrapper .notification-info {
    color: var(--specials_buttons-links-and-highlights); }
  .action_notification-wrapper .notification-warning,
  .trade-notify-wrapper .notification-warning {
    color: var(--specials_notifications-alerts-warnings); }
  .action_notification-wrapper .notification-success,
  .trade-notify-wrapper .notification-success {
    color: var(--specials_checks-okay-done); }
  .action_notification-wrapper .notification-disabled,
  .trade-notify-wrapper .notification-disabled {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .action_notification-wrapper .notification-loading,
  .trade-notify-wrapper .notification-loading {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .action_notification-wrapper.disabled,
  .trade-notify-wrapper.disabled {
    opacity: 0.3 !important; }
  .action_notification-wrapper .relative,
  .trade-notify-wrapper .relative {
    position: relative; }
  .action_notification-wrapper .action_notification-text,
  .trade-notify-wrapper .action_notification-text {
    font-size: 1rem;
    line-height: 1rem;
    text-transform: uppercase; }
  .action_notification-wrapper .action_notification-image,
  .trade-notify-wrapper .action_notification-image {
    width: 1rem;
    height: 1rem; }
  .action_notification-wrapper.icon_on-right,
  .trade-notify-wrapper.icon_on-right {
    flex-direction: row; }
  .action_notification-wrapper.icon_on-left,
  .trade-notify-wrapper.icon_on-left {
    flex-direction: row-reverse; }
  .action_notification-wrapper .action_notification-svg > div,
  .trade-notify-wrapper .action_notification-svg > div {
    display: flex;
    align-items: center; }
  .action_notification-wrapper .action_notification-svg svg,
  .trade-notify-wrapper .action_notification-svg svg {
    width: 1rem;
    height: 1rem; }
  .action_notification-wrapper .cancel-cross-2,
  .trade-notify-wrapper .cancel-cross-2 {
    fill: none;
    stroke: var(--specials_buttons-links-and-highlights); }
  .action_notification-wrapper .icon-clip-1,
  .action_notification-wrapper .icon-plus-1,
  .action_notification-wrapper .icon-edit-1,
  .action_notification-wrapper .icon-help-1,
  .action_notification-wrapper .icon-copy-1,
  .action_notification-wrapper .icon-tamer-01,
  .action_notification-wrapper .arrow-1,
  .action_notification-wrapper .trans-history,
  .action_notification-wrapper .cancel-cross-1,
  .trade-notify-wrapper .icon-clip-1,
  .trade-notify-wrapper .icon-plus-1,
  .trade-notify-wrapper .icon-edit-1,
  .trade-notify-wrapper .icon-help-1,
  .trade-notify-wrapper .icon-copy-1,
  .trade-notify-wrapper .icon-tamer-01,
  .trade-notify-wrapper .arrow-1,
  .trade-notify-wrapper .trans-history,
  .trade-notify-wrapper .cancel-cross-1 {
    fill: var(--specials_buttons-links-and-highlights); }
  .action_notification-wrapper .cancel-cross-3,
  .trade-notify-wrapper .cancel-cross-3 {
    stroke: var(--specials_buttons-links-and-highlights); }

.direction_ltr .action_notification-wrapper {
  font-family: "Raleway Medium" !important; }
  .direction_ltr .action_notification-wrapper.left {
    left: 0; }
  .direction_ltr .action_notification-wrapper.right {
    right: 0; }
  .direction_ltr .action_notification-wrapper .action_notification-image.rotate, .direction_ltr .action_notification-wrapper .action_notification-image.reverse, .direction_ltr .action_notification-wrapper .action_notification-image.rotate_ltr,
  .direction_ltr .action_notification-wrapper .action_notification-svg svg.rotate,
  .direction_ltr .action_notification-wrapper .action_notification-svg svg.reverse,
  .direction_ltr .action_notification-wrapper .action_notification-svg svg.rotate_ltr {
    transform: rotate(180deg); }
  .direction_ltr .action_notification-wrapper.icon_on-right .action_notification-image {
    margin-left: 0.25rem; }
  .direction_ltr .action_notification-wrapper.icon_on-left .action_notification-image {
    margin-right: 0.25rem; }
  .direction_ltr .action_notification-wrapper.icon_on-left .action_notification-svg {
    padding-right: 0.25rem; }
  .direction_ltr .action_notification-wrapper.icon_on-right .action_notification-svg {
    padding-left: 0.25rem; }

.direction_rtl .action_notification-wrapper.left {
  right: 0; }

.direction_rtl .action_notification-wrapper.right {
  left: 0; }

.direction_rtl .action_notification-wrapper .action_notification-image.rotate, .direction_rtl .action_notification-wrapper .action_notification-image.reverse, .direction_rtl .action_notification-wrapper .action_notification-image.rotate_rtl,
.direction_rtl .action_notification-wrapper .action_notification-svg svg.rotate,
.direction_rtl .action_notification-wrapper .action_notification-svg svg.reverse,
.direction_rtl .action_notification-wrapper .action_notification-svg svg.rotate_rtl {
  transform: rotate(180deg); }

.direction_rtl .action_notification-wrapper.icon_on-right .action_notification-image {
  margin-right: 0.25rem; }

.direction_rtl .action_notification-wrapper.icon_on-left .action_notification-image {
  margin-left: 0.25rem; }

.direction_rtl .action_notification-wrapper.icon_on-left .action_notification-svg {
  padding-left: 0.25rem; }

.direction_rtl .action_notification-wrapper.icon_on-right .action_notification-svg {
  padding-right: 0.25rem; }

.layout-mobile .action_notification-text {
  color: var(--specials_buttons-links-and-highlights); }

.icon_title-wrapper {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }
  .icon_title-wrapper.underline:after {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--calculated_important-border);
    position: absolute;
    bottom: 0;
    left: 0; }
  .icon_title-wrapper .icon_title-image,
  .icon_title-wrapper .icon_title-svg {
    width: 5rem;
    height: 5rem; }
    .icon_title-wrapper .icon_title-image svg .risk-order-warning,
    .icon_title-wrapper .icon_title-image svg .otp-key,
    .icon_title-wrapper .icon_title-image svg .refer-icon,
    .icon_title-wrapper .icon_title-image svg .send-request,
    .icon_title-wrapper .icon_title-image svg .stake_icon,
    .icon_title-wrapper .icon_title-image svg .otp-code,
    .icon_title-wrapper .icon_title-image svg .st0,
    .icon_title-wrapper .icon_title-image svg .st9,
    .icon_title-wrapper .icon_title-image svg .st10,
    .icon_title-wrapper .icon_title-image svg .contact-us-icon,
    .icon_title-wrapper .icon_title-image svg .xht-coin-stack,
    .icon_title-wrapper .icon_title-image svg .account-recovery-1,
    .icon_title-wrapper .icon_title-image svg .account-recovery-success-1,
    .icon_title-wrapper .icon_title-image svg .password-reset-1,
    .icon_title-wrapper .icon_title-image svg .success-black-1,
    .icon_title-wrapper .icon_title-image svg .check-st,
    .icon_title-wrapper .icon_title-image svg .level_icon,
    .icon_title-wrapper .icon_title-image svg .warning-icon,
    .icon_title-wrapper .icon_title-image svg .transaction-history-2,
    .icon_title-wrapper .icon_title-image svg .transaction-history-3,
    .icon_title-wrapper .icon_title-image svg .resend-email-st,
    .icon_title-wrapper .icon_title-image svg .email-confirmation,
    .icon_title-wrapper .icon_title-image svg .assets-icon,
    .icon_title-wrapper .icon_title-svg svg .risk-order-warning,
    .icon_title-wrapper .icon_title-svg svg .otp-key,
    .icon_title-wrapper .icon_title-svg svg .refer-icon,
    .icon_title-wrapper .icon_title-svg svg .send-request,
    .icon_title-wrapper .icon_title-svg svg .stake_icon,
    .icon_title-wrapper .icon_title-svg svg .otp-code,
    .icon_title-wrapper .icon_title-svg svg .st0,
    .icon_title-wrapper .icon_title-svg svg .st9,
    .icon_title-wrapper .icon_title-svg svg .st10,
    .icon_title-wrapper .icon_title-svg svg .contact-us-icon,
    .icon_title-wrapper .icon_title-svg svg .xht-coin-stack,
    .icon_title-wrapper .icon_title-svg svg .account-recovery-1,
    .icon_title-wrapper .icon_title-svg svg .account-recovery-success-1,
    .icon_title-wrapper .icon_title-svg svg .password-reset-1,
    .icon_title-wrapper .icon_title-svg svg .success-black-1,
    .icon_title-wrapper .icon_title-svg svg .check-st,
    .icon_title-wrapper .icon_title-svg svg .level_icon,
    .icon_title-wrapper .icon_title-svg svg .warning-icon,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-2,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-3,
    .icon_title-wrapper .icon_title-svg svg .resend-email-st,
    .icon_title-wrapper .icon_title-svg svg .email-confirmation,
    .icon_title-wrapper .icon_title-svg svg .assets-icon {
      fill: var(--labels_important-active-labels-text-graphics); }
    .icon_title-wrapper .icon_title-image svg .st1,
    .icon_title-wrapper .icon_title-image svg .account-recovery-success-2,
    .icon_title-wrapper .icon_title-image svg .password-reset-2,
    .icon_title-wrapper .icon_title-image svg .transaction-history-1,
    .icon_title-wrapper .icon_title-image svg .transaction-history-4,
    .icon_title-wrapper .icon_title-image svg .st11,
    .icon_title-wrapper .icon_title-image svg .wallet-coins-2,
    .icon_title-wrapper .icon_title-svg svg .st1,
    .icon_title-wrapper .icon_title-svg svg .account-recovery-success-2,
    .icon_title-wrapper .icon_title-svg svg .password-reset-2,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-1,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-4,
    .icon_title-wrapper .icon_title-svg svg .st11,
    .icon_title-wrapper .icon_title-svg svg .wallet-coins-2 {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
    .icon_title-wrapper .icon_title-image svg .wallet-coins-1,
    .icon_title-wrapper .icon_title-svg svg .wallet-coins-1 {
      stroke: var(--labels_important-active-labels-text-graphics); }
    .icon_title-wrapper .icon_title-image svg .transaction-history-5,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-5 {
      fill: none; }
  .icon_title-wrapper .icon_title-svg svg {
    width: 5rem;
    height: 5rem; }
  .icon_title-wrapper .icon_title-text {
    font-size: 1.3rem;
    margin: 1rem; }
    .icon_title-wrapper .icon_title-text.title {
      font-size: 2.5rem !important;
      text-transform: capitalize;
      padding-top: 0.25rem !important; }
  .icon_title-wrapper .auth_logo-wrapper {
    height: 6rem !important;
    width: 10rem !important;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }

.direction_ltr .icon_title-wrapper {
  font-family: "Raleway"; }

.layout-mobile .icon_title-wrapper .auth_logo-wrapper svg {
  height: 8rem !important;
  width: 15rem !important; }

.layout-mobile .auth-container .action_notification-text,
.layout-mobile .auth-container .font-small {
  font-size: 1rem !important;
  line-height: 1rem !important; }

.otp_form-wrapper {
  width: 38rem; }

.otp_form-title-wrapper {
  border-top: 1px solid var(--calculated_secondary-border);
  margin: 1rem 0;
  position: relative; }

.otp_form-info-text {
  font-weight: bold;
  font-size: 1.1rem;
  width: 100%;
  text-align: center !important; }

.otp_form-subnote-text {
  font-weight: 400;
  font-size: 1.1rem;
  opacity: 0.7;
  width: 100%;
  margin-top: 5rem;
  text-align: center !important; }

.otp_form-fields {
  margin-bottom: 1rem; }

.layout-mobile .otp_form-wrapper {
  flex: 1;
  overflow-y: scroll; }

.loader_wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 99999; }
  .loader_wrapper .loader_background {
    height: 100vh; }

.loader_wrapper_relative {
  position: relative;
  width: 100%;
  min-width: 5rem;
  height: 5rem;
  max-width: 100%;
  max-height: 100%; }

.loader {
  width: 5rem;
  height: 5rem;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

.loader_background {
  background-color: var(--labels_inactive-button);
  filter: opacity(0.75);
  width: 100%;
  height: 100%; }

.currency_ball-wrapper {
  font-family: "Raleway Medium" !important;
  text-transform: uppercase;
  border-radius: 50%;
  font-weight: bold;
  color: var(--base_background); }
  .currency_ball-wrapper.default-coin {
    border: 1px solid var(--calculated_secondary-border);
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .currency_ball-wrapper.s {
    font-size: 0.6rem;
    width: 1.8rem;
    height: 1.8rem; }
  .currency_ball-wrapper.m {
    font-size: 0.9rem;
    width: 2.7rem;
    height: 2.7rem; }
  .currency_ball-wrapper.l {
    font-size: 1.35rem;
    width: 4.05rem;
    height: 4.05rem; }

.with_price-block_amount {
  margin: 2rem 0;
  font-size: 4rem;
  line-height: 4rem; }
  .with_price-block_amount .with_price-block_amount-value {
    margin-left: 0.5rem; }

.direction_ltr .with_price-block_amount {
  justify-content: flex-start; }

.direction_rtl .with_price-block_amount {
  justify-content: flex-end; }

.table-wrapper {
  width: 100%;
  margin: 2rem 0 2rem; }
  .table-wrapper tr {
    height: 2rem; }
  .table-wrapper tr:hover {
    background-color: var(--base_secondary-navigation-bar); }
  .table-wrapper .sub-tr-bg-white {
    background-color: #e4e3e3;
    border-bottom: 1px solid #c3c3c3 !important;
    opacity: 0.75; }
  .table-wrapper .anchor {
    color: #5d63ff;
    text-decoration: underline;
    cursor: pointer; }
  .table-wrapper .new-sub-tr-bg {
    background: var(--calculated_secondary-border-1) !important;
    opacity: 0.75;
    border-bottom: 1px solid var(--calculated_secondary-border-1) !important; }
  .table-wrapper .sub-tr {
    height: 7rem;
    border-bottom: 1px solid #4b4d51; }
    .table-wrapper .sub-tr td {
      padding: 1rem;
      vertical-align: top; }
  .table-wrapper .time-wrapper {
    display: flex; }
    .table-wrapper .time-wrapper .type-color {
      color: #9edec9; }
    .table-wrapper .time-wrapper p {
      margin-bottom: 0px;
      margin-right: 0.5rem; }
  .table-wrapper th,
  .table-wrapper td {
    padding: 0.25rem; }
  .table-wrapper th {
    font-weight: bold; }
  .table-wrapper .border-bottom {
    border-bottom: 1px solid var(--calculated_secondary-border); }
  .table-wrapper .table_header-wrapper {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .table-wrapper .table_header-wrapper .action_notification-text {
      white-space: nowrap; }
  .table-wrapper .table_body-wrapper {
    font-size: 1rem; }
    .table-wrapper .table_body-wrapper.with_icon tr td:first-child {
      border: none !important; }
    .table-wrapper .table_body-wrapper tr.table_body-row {
      border-top: 1px solid var(--calculated_secondary-border);
      border-bottom: 1px solid var(--calculated_secondary-border); }
    .table-wrapper .table_body-wrapper .cancel-animate, .table-wrapper .table_body-wrapper .cancel-row-color, .table-wrapper .table_body-wrapper .cancel-row-color .cell_box-type .sell,
    .table-wrapper .table_body-wrapper .cancel-row-color .cell_box-type .buy, .table-wrapper .table_body-wrapper .cancel-row-color .action_notification-wrapper .notification-info, .table-wrapper .table_body-wrapper .cancel-row-color .cell-wrapper, .table-wrapper .table_body-wrapper .cancel-row-color .cell-wrapper .cell_value-wrapper, .table-wrapper .table_body-wrapper .cancel-row-color .cell-wrapper .cell_value-wrapper .cell_value-bar {
      -webkit-animation: cancelRow 1.4s;
      -moz-animation: cancelRow 1.4s;
      -o-animation: cancelRow 1.4s;
      animation: cancelRow 1.4s; }

@-webkit-keyframes cancelRow {
  from { }
  to {
    color: var(--base_background);
    background-color: var(--base_background);
    border-color: var(--base_background); } }

@-moz-keyframes cancelRow {
  from { }
  to {
    color: var(--base_background);
    background-color: var(--base_background);
    border-color: var(--base_background); } }

@keyframes cancelRow {
  from { }
  to {
    color: var(--base_background);
    background-color: var(--base_background);
    border-color: var(--base_background); } }
  .table-wrapper .cell_box-type {
    color: var(--calculated_trading_buying-related-text);
    text-transform: capitalize; }
    .table-wrapper .cell_box-type > div {
      padding: 0.15rem 0.25rem; }

.layout-mobile .table_controllers-wrapper {
  font-size: 1.2rem; }
  .layout-mobile .table_controllers-wrapper .action_notification-wrapper .action_notification-svg svg {
    height: 2rem;
    width: 2rem; }

.table_controllers-wrapper {
  margin: 2rem 0;
  color: var(--labels_important-active-labels-text-graphics);
  border-top: 2px solid var(--calculated_secondary-border);
  position: relative; }
  .table_controllers-wrapper .disabled {
    cursor: default;
    pointer-events: none; }
    .table_controllers-wrapper .disabled .action_notification-wrapper .notification-info {
      color: var(--labels_inactive-button); }
    .table_controllers-wrapper .disabled .action_notification-wrapper .action_notification-svg .arrow-1 {
      fill: var(--labels_inactive-button); }

.direction_ltr .cell_box-type > div {
  margin-right: 0.5rem; }

.direction_ltr .table_header-wrapper {
  font-family: "Raleway Extrabold"; }

.direction_rtl .cell_box-type > div {
  margin-left: 0.5rem; }

.layout-mobile .display_table-wrapper {
  font-size: 1.2rem; }

.display_table-wrapper {
  position: relative;
  height: 100%; }
  .display_table-wrapper .display_table-header {
    font-weight: bold;
    border-bottom: 1px solid var(--calculated_secondary-border); }
  .display_table-wrapper .display_table-body {
    overflow-y: auto; }
  .display_table-wrapper .display_table-cell {
    min-height: 1.5rem;
    padding: 0.1rem; }
  .display_table-wrapper .ant-select-arrow {
    z-index: 1; }

.f-1 {
  flex: 1; }

.donut-label-percentage {
  font-size: 0.8rem;
  font-weight: bold;
  fill: var(--labels_secondary-inactive-label-text-graphics); }

.donut-label-link {
  font-size: 0.7rem;
  font-weight: bold;
  fill: var(--specials_buttons-links-and-highlights); }

.donut-label-no-price {
  font-size: 9px;
  font-weight: bold;
  fill: var(--labels_secondary-inactive-label-text-graphics);
  font-family: "Raleway"; }

.donut-label-pair {
  font-size: 1rem;
  font-weight: bold;
  fill: var(--labels_secondary-inactive-label-text-graphics); }

.deposit-asset {
  fill: var(--specials_buttons-links-and-highlights);
  font-weight: bold; }

.chart_slice {
  fill-opacity: 0.7; }

.slice_active {
  fill-opacity: 1; }

@media (min-width: 1920px) {
  .donut-label-pair {
    font-size: 1.2rem; }
  .donut-label-percentage {
    font-size: 1rem; } }

.layout-mobile .donut-label-pair {
  font-size: 1.4rem; }

.layout-mobile .donut-label-percentage {
  font-size: 1.2rem; }

.layout-mobile .donut-label-link {
  font-size: 1rem; }

@media (max-width: 768px) {
  .donut-label-no-price {
    font-size: 8px !important; } }

.quick-trade-select-wrapper .input-group__coin-icons-wrap {
  display: flex; }

.input-group__container {
  border-radius: 4px;
  border: var(--labels_secondary-inactive-label-text-graphics) solid 1.5px; }
  .input-group__container .ant-select-selection,
  .input-group__container .ant-select-selector {
    text-align: right;
    background-color: var(--base_wallet-sidebar-and-popup) !important;
    color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .input-group__container .ant-input {
    text-align: right;
    background-color: var(--base_wallet-sidebar-and-popup) !important;
    color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .input-group__container .ant-select-arrow svg {
    fill: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .input-group__container .input-group__coin-icons-wrap {
    width: 2.5rem;
    display: flex; }
  .input-group__container .input-group__coin-icons-wrap_mobile-view {
    width: 3.5rem;
    display: flex; }
  .input-group__container:focus-within {
    border-color: var(--specials_buttons-links-and-highlights) !important; }
    .input-group__container:focus-within .ant-input {
      color: var(--labels_important-active-labels-text-graphics) !important; }
  .input-group__container .input-group__select:hover {
    color: var(--labels_important-active-labels-text-graphics) !important; }
    .input-group__container .input-group__select:hover .ant-select-selection,
    .input-group__container .input-group__select:hover .ant-input,
    .input-group__container .input-group__select:hover .ant-select-arrow,
    .input-group__container .input-group__select:hover .ant-select-selector {
      color: var(--labels_important-active-labels-text-graphics) !important; }
  .input-group__container:hover {
    border-color: var(--labels_important-active-labels-text-graphics); }

@media (max-width: 1024px) {
  .quick-trade-dropdown .ant-select-arrow {
    top: 50% !important;
    right: 66% !important; } }

@media (max-width: 768px) {
  .quick-trade-dropdown .ant-select-arrow {
    top: 50% !important;
    right: 64% !important; } }

@media (max-width: 550px) {
  .quick-trade-dropdown .ant-select-arrow {
    top: 50% !important;
    right: 50% !important; } }

@media (max-width: 500px) {
  .quick-trade-dropdown .ant-select-arrow {
    top: 50% !important;
    right: 40% !important; } }

@media (max-width: 450px) {
  .quick-trade-dropdown .ant-select-arrow {
    top: 50% !important;
    right: 36% !important; } }

@media (max-width: 400px) {
  .quick-trade-dropdown .ant-select-arrow {
    top: 50% !important;
    right: 24% !important; } }

@media (max-width: 350px) {
  .quick-trade-dropdown .ant-select-arrow {
    top: 50% !important;
    right: 12% !important; } }

@media (max-width: 300px) {
  .quick-trade-dropdown .ant-select-arrow {
    top: 50% !important;
    right: 5% !important; } }

.quick_trade-wrapper .quote-expired-block-wrapper {
  margin-top: 1rem; }
  .quick_trade-wrapper .quote-expired-block-wrapper .quote-expired-text p.small-text {
    margin: 0;
    padding: 0;
    color: #fff; }
  .quick_trade-wrapper .quote-expired-block-wrapper .requote-button {
    width: 100px;
    margin-left: 13px;
    margin-top: 0;
    flex-direction: row-reverse; }

.language_rtl .quick_trade-wrapper {
  font-family: inherit; }
  .language_rtl .quick_trade-wrapper .input_block-title {
    font-family: inherit; }

.direction_ltr .quick_trade-wrapper {
  font-family: "Raleway"; }

.quick_trade-tab-icon {
  width: 10rem;
  height: 10rem;
  margin: 0 auto; }

.quick_trade-container {
  margin: 3rem 0 4rem 0; }
  .quick_trade-container .quick_trade-section_wrapper {
    width: 100%;
    position: relative; }
    .quick_trade-container .quick_trade-section_wrapper.fetching {
      color: var(--labels_secondary-inactive-label-text-graphics); }
  .quick_trade-container .quick_trade-bottom-padded {
    margin-bottom: 2rem; }
  .quick_trade-container .quick_trade-icon {
    width: 7rem;
    height: 8rem; }
  .quick_trade-container .title {
    font-size: 30px;
    font-family: "Raleway"; }
  .quick_trade-container .info-text {
    font-family: "Open Sans";
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .quick_trade-container .visit-asset-info {
    text-decoration: underline; }

.quick_trade-wrapper.width-none {
  min-width: unset !important;
  max-width: unset !important; }

.quick_trade-wrapper {
  min-width: 1000px;
  max-width: 1200px;
  background-color: var(--calculated_quick_trade-bg);
  height: fit-content;
  height: 550px;
  border: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .quick_trade-wrapper .review-block-wrapper {
    margin: 1rem 0;
    width: auto; }
  .quick_trade-wrapper .review-block-wrapper .currency-wrapper {
    margin: 0.5rem auto;
    font-size: 4rem;
    line-height: 4rem; }
    .quick_trade-wrapper .review-block-wrapper .currency-wrapper .currency_ball-wrapper {
      margin-top: 0.25rem;
      margin-right: 0.5rem; }
  .quick_trade-wrapper .review-block-wrapper .with_price-block_amount {
    margin: 0; }
  .quick_trade-wrapper .balance-wallet {
    padding-bottom: 0.5rem; }
  .quick_trade-wrapper .input-group__error-wrapper {
    color: red;
    float: right;
    min-height: auto; }
  .quick_trade-wrapper .input_block-title {
    font-size: 1.3rem;
    font-family: "Raleway Medium"; }
  .quick_trade-wrapper .trade-section {
    width: 100%;
    padding-top: 2rem;
    background: transparent linear-gradient(180deg, var(--specials_quick-trade-gradient) 0%, var(--calculated_quick_trade-gradient) 100%) 0% 0% no-repeat padding-box; }
    .quick_trade-wrapper .trade-section .inner-content {
      width: 400px;
      height: 390px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 55px 105px 0 95px; }
      .quick_trade-wrapper .trade-section .inner-content .btn-wrapper {
        padding: 40px 0 120px 0; }
      .quick_trade-wrapper .trade-section .inner-content .py-2 {
        display: flex;
        align-items: center; }
        .quick_trade-wrapper .trade-section .inner-content .py-2 .bold {
          padding-right: 10px; }
        .quick_trade-wrapper .trade-section .inner-content .py-2 .ant-input-group {
          width: 310px;
          background-color: var(--base_secondary-navigation-bar) !important; }
          .quick_trade-wrapper .trade-section .inner-content .py-2 .ant-input-group .ant-select-selector,
          .quick_trade-wrapper .trade-section .inner-content .py-2 .ant-input-group .input-group__input {
            background-color: var(--base_secondary-navigation-bar) !important; }
        .quick_trade-wrapper .trade-section .inner-content .py-2 .input-group__error-wrapper {
          width: 310px; }
      .quick_trade-wrapper .trade-section .inner-content .small-text {
        font-family: "Open Sans";
        font-size: 11px;
        text-transform: capitalize;
        color: var(--labels_secondary-inactive-label-text-graphics);
        display: flex; }
        .quick_trade-wrapper .trade-section .inner-content .small-text span {
          color: var(--specials_buttons-links-and-highlights); }
      .quick_trade-wrapper .trade-section .inner-content .footer-text {
        font-size: 11px;
        font-family: "Open Sans";
        color: var(--labels_secondary-inactive-label-text-graphics);
        opacity: 0.5;
        text-align: end; }

@keyframes linear_loading {
  0% {
    left: 0;
    width: 0; }
  15% {
    left: 0;
    width: 100px; }
  85% {
    width: 100px;
    left: calc(100% - 100px); }
  100% {
    width: 0;
    left: 100%; } }

.layout-mobile .quick_trade-section_wrapper .review-block-wrapper .input_block-title {
  font-size: 13px; }

.layout-mobile .quick_trade-container {
  height: 100%; }

.layout-mobile .quick_trade-wrapper .trade-details-wrapper {
  width: 100%; }
  .layout-mobile .quick_trade-wrapper .trade-details-wrapper .trade-details-content {
    width: 100%;
    height: 100%; }

.layout-mobile .quick_trade-wrapper .trade-section .inner-content {
  align-items: center !important; }
  .layout-mobile .quick_trade-wrapper .trade-section .inner-content .py-2 {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-size: 13px !important; }
    .layout-mobile .quick_trade-wrapper .trade-section .inner-content .py-2 .ant-input-group {
      width: 100% !important; }
    .layout-mobile .quick_trade-wrapper .trade-section .inner-content .py-2 .input-group__error-wrapper {
      width: 100% !important; }
  .layout-mobile .quick_trade-wrapper .trade-section .inner-content .btn-wrapper {
    align-items: center !important; }
  .layout-mobile .quick_trade-wrapper .trade-section .inner-content .footer-text,
  .layout-mobile .quick_trade-wrapper .trade-section .inner-content .small-text {
    font-size: 13px !important; }

.layout-mobile .swap-wrapper,
.layout-mobile .swap-wrapper-wrapper {
  width: 100% !important; }

.mobile_dropdown-section {
  border-top: solid; }

.review-block-wrapper {
  border-top: var(--calculated_secondary-border) solid 1px; }

.loading-border {
  position: absolute;
  border: 1px solid var(--specials_buttons-links-and-highlights); }

@media (max-width: 1020px) {
  .quick_trade-wrapper {
    min-width: 100% !important;
    max-width: 100% !important; }
    .quick_trade-wrapper .trade-details-wrapper {
      width: 100%;
      padding: 15px; }
      .quick_trade-wrapper .trade-details-wrapper .trade-details-content {
        width: 100%;
        height: 100%; }
    .quick_trade-wrapper .trade-section {
      width: 100%;
      padding: 40px 10px 10px 10px; }
      .quick_trade-wrapper .trade-section .inner-content {
        width: 100%;
        height: 100%;
        margin: 0; }
        .quick_trade-wrapper .trade-section .inner-content .py-2 .ant-input-group {
          width: 250px; }
        .quick_trade-wrapper .trade-section .inner-content .py-2 .input-group__error-wrapper {
          width: 250px; }
        .quick_trade-wrapper .trade-section .inner-content .input-group__container .quick-trade-select-wrapper .pl-1 {
          font-size: 10px; }
    .quick_trade-wrapper .swap-wrapper {
      width: 250px; } }

.swap-container {
  border-left: var(--labels_secondary-inactive-label-text-graphics) dotted 2px; }

.swap-wrapper {
  width: 310px;
  padding: 0 1rem; }

.legal-container {
  width: 100%; }
  .legal-container .legal-wrapper {
    margin: 0; }

.legal-wrapper {
  width: 100%;
  margin: 1.5rem; }

.legal-content-wrapper {
  max-width: 50rem; }
  .legal-content-wrapper .legal-logo-wrapper {
    margin: 1.5rem; }
    .legal-content-wrapper .legal-logo-wrapper .legal-logo {
      height: 3rem; }
  .legal-content-wrapper .legal-title {
    font-size: 4rem;
    line-height: 4rem;
    text-align: center; }
  .legal-content-wrapper .legal-logo-wrapper,
  .legal-content-wrapper .legal-title,
  .legal-content-wrapper .legal-subtitle,
  .legal-content-wrapper .legal-content {
    margin: 1.5rem auto;
    flex: 1; }
  .legal-content-wrapper .legal-subtitle {
    border-top: 2px solid var(--calculated_important-border);
    font-weight: bold;
    width: 100%; }
  .legal-content-wrapper .legal-subtitle,
  .legal-content-wrapper .legal-content {
    font-size: 1rem; }

@media screen and (max-width: 767px) {
  .legal-container .legal-content-wrapper {
    padding: 1rem; } }

.countdown-wrapper .countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem; }
  .countdown-wrapper .countdown-timer.invalid {
    color: var(--specials_notifications-alerts-warnings); }

.layout-mobile .countdown-wrapper {
  margin: 38% auto; }

.light-theme.holla-tooltip {
  opacity: 1 !important;
  background-color: transparent !important; }
  .light-theme.holla-tooltip .rc-tooltip-inner {
    color: var(--labels_important-active-labels-text-graphics) !important;
    background-color: var(--base_background);
    border-color: var(--labels_important-active-labels-text-graphics) !important; }
  .light-theme.holla-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow, .light-theme.holla-tooltip.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .light-theme.holla-tooltip.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: var(--labels_important-active-labels-text-graphics) !important; }

.mobile-bar {
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 4rem;
  max-height: 4rem;
  background-color: var(--base_top-bar-navigation);
  color: var(--calculated_base_top-bar-navigation_text-inactive); }

.mobile-bar-tab {
  font-size: 2rem;
  font-weight: bold; }
  .mobile-bar-tab.active {
    position: relative;
    color: var(--calculated_base_top-bar-navigation_text); }
    .mobile-bar-tab.active:after {
      content: '';
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-top: none !important;
      border-left: 1rem solid transparent !important;
      border-right: 1rem solid transparent !important;
      border-bottom: 1rem solid var(--base_background);
      margin-bottom: 0rem; }
  .mobile-bar-tab .bartab-text-wrapper {
    position: relative; }
    .mobile-bar-tab .bartab-text-wrapper .bartab-notification {
      position: absolute;
      right: -1.75rem;
      top: 0;
      height: 1.75rem;
      width: 1.75rem;
      border-radius: 1.75rem;
      background-color: red;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3125rem;
      line-height: 1;
      font-weight: bold; }

.close-dialog {
  transform: rotate(90deg);
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem; }
  .close-dialog svg {
    width: 2.5rem;
    height: 2.5rem; }
    .close-dialog svg .arrow-down {
      stroke: var(--calculated_base_top-bar-navigation_text); }

.dialog-compressed-close .close-dialog {
  margin-left: 0;
  width: 7.5rem;
  height: 7.5rem; }
  .dialog-compressed-close .close-dialog svg {
    width: 7.5rem;
    height: 7.5rem; }
    .dialog-compressed-close .close-dialog svg .cancel-cross-3 {
      stroke: white; }
    .dialog-compressed-close .close-dialog svg .cancel-cross-2 {
      stroke: transparent; }

.layout-mobile .chat-wrapper {
  position: relative;
  height: 100%;
  box-shadow: unset !important;
  right: 0;
  bottom: 0; }
  .layout-mobile .chat-wrapper .nondraggable {
    height: 100%; }
  .layout-mobile .chat-wrapper .timestamp {
    font-size: 11px; }
  .layout-mobile .chat-wrapper .chat-message-list {
    width: 100% !important; }
    .layout-mobile .chat-wrapper .chat-message-list .chat-message .message-content .username {
      font-size: 1.5rem;
      width: 100%; }
    .layout-mobile .chat-wrapper .chat-message-list .chat-message .message-content .message {
      font-size: 13px;
      line-height: unset !important; }
    .layout-mobile .chat-wrapper .chat-message-list .chat-message .item-options .item-options-wrapper {
      min-width: 5rem;
      font-size: 1rem;
      right: 1.5rem;
      top: -0.5rem; }
  .layout-mobile .chat-wrapper .chat-footer {
    padding: 0.5rem;
    background-color: var(--labels_fields); }
    .layout-mobile .chat-wrapper .chat-footer .chat-message-box {
      min-height: 4rem;
      font-size: 1.5rem;
      padding: 1rem 0.5rem; }
    .layout-mobile .chat-wrapper .chat-footer .emoji-container {
      width: 100% !important; }
      .layout-mobile .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close .cross-icon-back {
        width: 3rem; }
    .layout-mobile .chat-wrapper .chat-footer .chat-emoji-wrapper {
      background-color: var(--labels_fields); }
      .layout-mobile .chat-wrapper .chat-footer .chat-emoji-wrapper .chat-emoji-icon svg .emoji-face-icon {
        fill: var(--labels_secondary-inactive-label-text-graphics); }

.chat-wrapper {
  position: fixed;
  background-color: white;
  height: 40rem;
  right: 1rem;
  bottom: 0;
  color: #999999 !important;
  z-index: 100;
  -webkit-box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.29);
  box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.29);
  font-family: 'Open Sans' !important;
  border: 1px solid var(--calculated_important-border) !important;
  border-top: 2px solid var(--specials_buttons-links-and-highlights) !important; }
  .chat-wrapper .timestamp {
    color: #808080;
    font-size: 0.65rem;
    min-width: 3.2rem;
    text-align: right;
    margin-right: 0.3rem; }
  .chat-wrapper .chat-username-footer {
    border-top: 1px solid gray; }
  .chat-wrapper .username-to-chat {
    padding: 0.7rem !important;
    color: var(--specials_buttons-links-and-highlights) !important;
    font-size: 1rem !important;
    font-family: 'Open Sans' !important; }
  .chat-wrapper .nondraggable {
    height: calc(100% - 2.5rem); }
  .chat-wrapper.unread-messages {
    border-color: #b9b500;
    color: #b9b500; }
    .chat-wrapper.unread-messages:hover {
      border-color: var(--specials_my-username-in-chat);
      color: var(--specials_my-username-in-chat); }
      .chat-wrapper.unread-messages:hover::before {
        border-top-color: var(--specials_my-username-in-chat); }
    .chat-wrapper.unread-messages::before {
      border-top-color: #b9b500; }
  .chat-wrapper.minimized {
    height: 2.5rem; }
    .chat-wrapper.minimized .chat-footer,
    .chat-wrapper.minimized .chat-message-list {
      display: none !important; }
    .chat-wrapper.minimized .chat-header {
      border-top: 1px solid var(--calculated_secondary-border);
      cursor: pointer;
      width: 23rem; }
    .chat-wrapper.minimized .minimize-button {
      padding: 0.5rem; }
      .chat-wrapper.minimized .minimize-button:hover {
        background-color: white; }
  .chat-wrapper .minimize-button {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.2rem;
    margin-left: 1rem;
    cursor: pointer; }
    .chat-wrapper .minimize-button .minimize-button-content {
      width: 100%;
      height: 100%;
      border-bottom: 2px solid #999999; }
    .chat-wrapper .minimize-button .maximize-arrow {
      border: solid #999999;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg); }
    .chat-wrapper .minimize-button:hover {
      background-color: #dfdfdf; }
  .chat-wrapper .loader_wrapper {
    position: relative;
    height: 100%; }
    .chat-wrapper .loader_wrapper .loader_background {
      height: 100%;
      background: white; }
    .chat-wrapper .loader_wrapper .loader {
      width: 5rem;
      height: 5rem; }
  .chat-wrapper .chat-header {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background-color: var(--base_top-bar-navigation); }
    .chat-wrapper .chat-header .chat-header-icon {
      flex: 1;
      /* background-image: url("./assets/images/dotted-grip-chat-repeat-pattern.svg"); */
      background-repeat: repeat-x;
      background-size: 0.75rem;
      height: 1.2rem;
      cursor: -webkit-grab; }
  .chat-wrapper .chat-message-list {
    width: 23rem !important;
    background-color: var(--base_secondary-navigation-bar);
    flex: 1; }
    .chat-wrapper .chat-message-list > div {
      padding: 0.5rem 0; }
    .chat-wrapper .chat-message-list .view > div:nth-child(odd) {
      background-color: var(--base_secondary-navigation-bar); }
    .chat-wrapper .chat-message-list .view > div:nth-child(even) {
      background-color: var(--base_background); }
    .chat-wrapper .chat-message-list .chat-message {
      font-weight: bold;
      color: white;
      padding: 0.25rem 0;
      word-break: break-all; }
      .chat-wrapper .chat-message-list .chat-message .message-content {
        width: 100%; }
        .chat-wrapper .chat-message-list .chat-message .message-content .message {
          color: var(--specials_chat-messages);
          word-break: break-word;
          line-height: 1.5rem;
          font-size: 1rem;
          font-weight: normal; }
          .chat-wrapper .chat-message-list .chat-message .message-content .message line > span > span {
            display: inline; }
        .chat-wrapper .chat-message-list .chat-message .message-content .nonmobile .own-message {
          line-height: 1.2rem;
          word-break: keep-all;
          color: var(--specials_my-username-in-chat); }
      .chat-wrapper .chat-message-list .chat-message.user .own-message {
        color: var(--specials_my-username-in-chat);
        font-weight: bold; }
      .chat-wrapper .chat-message-list .chat-message .message-content span.toggle-content {
        position: relative;
        cursor: pointer;
        color: var(--specials_buttons-links-and-highlights); }
        .chat-wrapper .chat-message-list .chat-message .message-content span.toggle-content::before {
          display: inline-block;
          content: '\00bb';
          height: 1rem;
          margin: 0 0.25rem; }
        .chat-wrapper .chat-message-list .chat-message .message-content span.toggle-content::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0.2rem;
          border-bottom: 1px dotted var(--specials_buttons-links-and-highlights);
          width: 100%; }
      .chat-wrapper .chat-message-list .chat-message .message-content .show .toggle-content::before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg); }
      .chat-wrapper .chat-message-list .chat-message .message-content.image img.image {
        margin: 0.5rem 0;
        max-width: 100%; }
      .chat-wrapper .chat-message-list .chat-message .username {
        color: var(--labels_important-active-labels-text-graphics);
        font-weight: bold;
        margin-bottom: 0.5rem;
        width: 100%; }
      .chat-wrapper .chat-message-list .chat-message .item-options {
        position: relative;
        margin-left: 0.25rem;
        cursor: pointer; }
        .chat-wrapper .chat-message-list .chat-message .item-options .item-options-icon-wrapper {
          width: 1rem;
          height: 1rem; }
          .chat-wrapper .chat-message-list .chat-message .item-options .item-options-icon-wrapper .item-options-icon {
            width: 100%;
            height: 100%; }
            .chat-wrapper .chat-message-list .chat-message .item-options .item-options-icon-wrapper .item-options-icon > * {
              fill: #999999; }
        .chat-wrapper .chat-message-list .chat-message .item-options .item-options-wrapper {
          position: absolute;
          right: 0;
          top: 1rem;
          min-width: 4rem;
          font-size: 0.8rem; }
          .chat-wrapper .chat-message-list .chat-message .item-options .item-options-wrapper .item-option {
            width: 100%;
            padding: 0 0.25rem;
            background-color: white;
            border-bottom: 1px solid #464545;
            color: #999999; }
            .chat-wrapper .chat-message-list .chat-message .item-options .item-options-wrapper .item-option:hover {
              background-color: #535353;
              color: white; }
    .chat-wrapper .chat-message-list .user-icon {
      width: 2.5rem; }
      .chat-wrapper .chat-message-list .user-icon svg .level_icon {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
  .chat-wrapper .chat-footer {
    min-height: 3rem;
    width: 100%;
    padding: 0;
    background-color: var(--base_secondary-navigation-bar); }
    .chat-wrapper .chat-footer button {
      width: 100%; }
    .chat-wrapper .chat-footer .chat-message-box {
      outline: none !important;
      border: none;
      background-color: var(--labels_fields);
      width: 100%;
      height: 100%;
      min-height: 3rem;
      line-height: 2rem;
      padding: 0.5rem;
      color: #999999;
      font-size: 0.9rem;
      resize: none;
      overflow: hidden;
      padding-left: 10px; }
      .chat-wrapper .chat-footer .chat-message-box::placeholder {
        color: var(--labels_secondary-inactive-label-text-graphics); }
    .chat-wrapper .chat-footer .chat-emoji-wrapper {
      background-color: var(--labels_fields);
      cursor: pointer; }
      .chat-wrapper .chat-footer .chat-emoji-wrapper .chat-emoji-icon {
        width: 2rem; }
        .chat-wrapper .chat-footer .chat-emoji-wrapper .chat-emoji-icon svg .emoji-face-icon {
          fill: var(--labels_secondary-inactive-label-text-graphics); }
    .chat-wrapper .chat-footer .emoji-container {
      width: 23rem !important;
      height: 15rem;
      background-color: #cecece; }
      .chat-wrapper .chat-footer .emoji-container .chat-category {
        border-bottom: 1px solid grey;
        background-color: #dbdbdb;
        height: max-content;
        width: 100%; }
        .chat-wrapper .chat-footer .emoji-container .chat-category .top-background .emoji-mart-emoji {
          background-color: #dbdbdb; }
        .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close {
          cursor: pointer; }
          .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close .cross-icon-back {
            width: 2rem; }
            .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close .cross-icon-back svg .cancel-cross-2 {
              stroke: #dbdbdb; }
            .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close .cross-icon-back svg .cancel-cross-3 {
              stroke: grey;
              stroke-width: 2; }
          .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close:hover .cross-icon-back svg .cancel-cross-3 {
            stroke: var(--labels_secondary-inactive-label-text-graphics); }
        .chat-wrapper .chat-footer .emoji-container .chat-category .activeEmoji {
          border-bottom: 2px solid var(--specials_buttons-links-and-highlights); }
        .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-mart-emoji {
          background-color: #cecece;
          border: none; }
          .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-mart-emoji span {
            cursor: pointer; }
        .chat-wrapper .chat-footer .emoji-container .chat-category button:focus {
          outline: none !important; }
      .chat-wrapper .chat-footer .emoji-container .emoji-content {
        height: 13rem;
        overflow-y: scroll; }
      .chat-wrapper .chat-footer .emoji-container .emoji-mart-emoji {
        background-color: #cecece;
        border: none; }
        .chat-wrapper .chat-footer .emoji-container .emoji-mart-emoji span {
          cursor: pointer; }
          .chat-wrapper .chat-footer .emoji-container .emoji-mart-emoji span:hover {
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 20%; }
      .chat-wrapper .chat-footer .emoji-container button:focus {
        outline: none !important; }
    .chat-wrapper .chat-footer .separator {
      width: 1rem; }

.elapsed-timer-wrapper {
  font-size: 0.8rem;
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .elapsed-timer-wrapper .timer-time {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-weight: bold; }
  .elapsed-timer-wrapper .timer-svg {
    height: 1.2rem;
    width: 1.2rem; }
    .elapsed-timer-wrapper .timer-svg svg .pending-timer {
      stroke: var(--labels_secondary-inactive-label-text-graphics); }

.snack_notification-wrapper {
  background-color: var(--base_background);
  border-top: 0.3rem solid var(--calculated_secondary-border);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: "Raleway";
  height: 3.5rem;
  left: calc(50vw - 10rem);
  padding: 0.3rem 0.5rem !important;
  position: fixed;
  min-width: 20rem;
  max-width: 30rem;
  z-index: 9999;
  -webkit-box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.4); }
  .snack_notification-wrapper .notification-text {
    font-size: 1.2rem;
    font-weight: 400; }
  .snack_notification-wrapper .notification-icon {
    width: 1.5rem; }

.snack_open {
  top: -1rem;
  -webkit-animation: snackSlide 0.7s forwards;
  animation: snackSlide 0.7s forwards; }

.snack_close {
  top: 1rem;
  -webkit-animation: slideClose 1s forwards;
  animation: slideClose 1s forwards; }

@-webkit-keyframes snackSlide {
  0% {
    top: -2rem; }
  100% {
    top: 1rem; } }

@keyframes snackSlide {
  0% {
    top: -2rem; }
  100% {
    top: 1rem; } }

@-webkit-keyframes slideClose {
  0% {
    top: 1rem; }
  100% {
    top: -4rem; } }

@keyframes slideClose {
  0% {
    top: 1rem; }
  100% {
    top: -4rem; } }

.snack_dialog-wrapper {
  background-color: var(--labels_important-active-labels-text-graphics);
  font-family: "Raleway";
  padding: 0.3rem 0.5rem !important;
  position: absolute;
  width: 100%;
  z-index: 9998; }
  .snack_dialog-wrapper .notification-text {
    font-size: 1.2rem;
    font-weight: 400; }
  .snack_dialog-wrapper .notification-icon {
    width: 1.5rem; }
  .snack_dialog-wrapper .close-dialog {
    margin-left: 0;
    width: 7.5rem;
    height: 7.5rem;
    height: auto; }
    .snack_dialog-wrapper .close-dialog svg {
      width: 7.5rem;
      height: 7.5rem; }
      .snack_dialog-wrapper .close-dialog svg .cancel-cross-3 {
        stroke: white; }
      .snack_dialog-wrapper .close-dialog svg .cancel-cross-2 {
        stroke: transparent; }

.snack_dialog_open {
  bottom: 0rem;
  -webkit-animation: dialogSlide 1.5s backwards;
  animation: dialogSlide 1.5s backwards; }

.snack_dialog_close {
  bottom: 5.5rem;
  -webkit-animation: dialogClose 0.8s backwards;
  animation: dialogClose 0.8s backwards; }

@-webkit-keyframes dialogSlide {
  0% {
    bottom: 0rem; }
  100% {
    bottom: 7.5rem; } }

@keyframes dialogSlide {
  0% {
    bottom: 0rem; }
  100% {
    bottom: 7.5rem; } }

@-webkit-keyframes dialogClose {
  0% {
    bottom: 7.5rem; }
  100% {
    bottom: 5.5rem; } }

@keyframes dialogClose {
  0% {
    bottom: 7.5rem; }
  100% {
    bottom: 5.5rem; } }

.mobile-notification {
  border-top: 0;
  border-bottom: 0.5rem solid var(--calculated_secondary-border);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 22rem;
  left: calc(50vw - 11rem); }

.layout-mobile .snack_dialog-wrapper .notification-content-mobile-wrapper {
  font-size: 1rem !important; }

.layout-mobile .trade_active_orders-wrapper {
  max-height: 35vh !important; }

.trade_post_icon {
  width: 1.5rem; }
  .trade_post_icon svg .wave-icon {
    fill: var(--labels_secondary-inactive-label-text-graphics); }

.post_header {
  font-weight: bold; }

.trade_announcement {
  font-size: 1.5rem; }

.announcement-notification-list-item {
  color: var(--labels_important-active-labels-text-graphics);
  border-bottom: 1px solid var(--calculated_secondary-border); }

.post-content .notifications_list-item-text a {
  color: var(--specials_buttons-links-and-highlights); }

.post-content .notifications_list-item-text span,
.post-content .notifications_list-item-text p {
  background-color: var(--base_wallet-sidebar-and-popup) !important; }

.post-content .notifications_list-item-timestamp {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.app_footer-container {
  border-top: 1px solid var(--calculated_footer_border);
  font-size: 1rem;
  background-color: var(--base_footer);
  color: var(--calculated_base_footer_text); }
  .app_footer-container.deep-footer {
    padding-bottom: 136px; }
  .app_footer-container .footer-row-content {
    margin-bottom: 2rem;
    padding: 4rem 1rem 0;
    width: 100%; }
    .app_footer-container .footer-row-content .footer-links-section .footer-links-group {
      margin: 1rem;
      font-size: 1.1rem; }
      .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--title {
        font-weight: bold;
        margin-bottom: 0.25rem;
        text-transform: uppercase; }
      .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list {
        min-width: 8rem;
        text-transform: capitalize; }
        .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .social_icon {
          width: 1rem;
          margin-right: 0.25rem; }
        .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .link-section {
          margin-top: 0.25rem; }
          .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .link-section:hover {
            color: grey; }
    .app_footer-container .footer-row-content .footer_separter {
      border-left: 1px solid var(--calculated_footer_border);
      margin-left: 0.5rem; }
      .app_footer-container .footer-row-content .footer_separter .footer-txt {
        width: 23rem;
        margin-left: 2rem !important;
        margin-top: 0.5rem; }
      .app_footer-container .footer-row-content .footer_separter .footer-logo {
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 1.5rem;
        width: 7rem;
        height: 100% !important; }
      .app_footer-container .footer-row-content .footer_separter svg .hollaEX_logo_gray {
        fill: var(--calculated_base_footer_text); }
      .app_footer-container .footer-row-content .footer_separter .blue-link {
        color: #00aeef !important; }
      .app_footer-container .footer-row-content .footer_separter .footer-content .footer-img-wrapper {
        height: 4rem; }
    .app_footer-container .footer-row-content .footer-public-texts .footer-public-texts-text {
      line-height: 2rem;
      font-weight: bold; }
    .app_footer-container .footer-row-content .footer-public-links {
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem; }
      .app_footer-container .footer-row-content .footer-public-links .footer-public-links-icon {
        width: 2rem;
        height: 2rem;
        margin: 0 1.5rem; }
  .app_footer-container .footer-row-bottom {
    border-top: 1px solid var(--calculated_footer_border);
    text-align: left;
    padding-left: 1rem;
    padding-bottom: 1rem; }

.direction_ltr .app_footer-container .footer-row-content .footer-public-texts {
  text-align: right; }

.direction_ltr .app_footer-container .footer-row-content .footer-public-links:first-child .footer-public-links-icon {
  margin-left: 0; }

.direction_ltr .app_footer-container .footer-row-content .footer-public-links:last-child .footer-public-links-icon {
  margin-right: 0; }

.direction_rtl .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .social_icon {
  margin-right: 0;
  margin-left: 0.25rem; }

.direction_rtl .app_footer-container .footer-row-content .footer_separter {
  border-left: 0;
  border-right: 1px solid var(--calculated_footer_border); }
  .direction_rtl .app_footer-container .footer-row-content .footer_separter .footer-txt {
    margin-left: 0;
    margin-right: 1rem; }
  .direction_rtl .app_footer-container .footer-row-content .footer_separter .footer-logo {
    margin-left: 0;
    margin-right: 1rem; }

.direction_rtl .app_footer-container .footer-row-content .footer-public-texts {
  text-align: left; }

.direction_rtl .app_footer-container .footer-row-content .footer-public-links:first-child .footer-public-links-icon {
  margin-right: 0; }

.direction_rtl .app_footer-container .footer-row-content .footer-public-links:last-child .footer-public-links-icon {
  margin-left: 0; }

@media screen and (max-width: 991px) {
  .app_footer-container .footer-row-content {
    flex-direction: column;
    padding: 0 !important; }
    .app_footer-container .footer-row-content > div:not(:last-child) {
      margin-bottom: 2rem; }
    .app_footer-container .footer-row-content .footer-public-texts {
      text-align: center !important; }
    .app_footer-container .footer-row-content .footer-public-links {
      justify-content: center; }
      .app_footer-container .footer-row-content .footer-public-links .footer-public-links-icon {
        margin: 0 1.5rem !important; } }

@media screen and (max-width: 767px) {
  .app_footer-container .footer-row-content .footer_separter .footer-txt {
    width: 12rem; } }

.layout-mobile .app_footer-container .footer-row-content {
  margin-bottom: 1rem; }
  .layout-mobile .app_footer-container .footer-row-content .footer-links-section {
    width: 100%;
    margin-top: 1rem; }
    .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer-links-group {
      justify-content: center;
      align-items: center;
      margin-bottom: 3rem !important;
      font-size: 12px; }
      .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .link-section {
        justify-content: center;
        align-items: center;
        margin-top: 0.25rem; }
        .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .link-section:hover {
          color: grey; }
    .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter {
      border-left: none !important;
      border-top: 1px solid var(--calculated_footer_border) !important; }
      .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter .footer-content {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter .footer-txt {
        width: 80%;
        margin-top: 2rem !important;
        margin-left: 0 !important;
        display: flex;
        justify-content: center;
        text-align: center; }
      .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter .footer-logo {
        height: 3.5rem;
        margin-top: 11px;
        margin-bottom: 1rem;
        width: 6.5rem;
        margin-left: 7rem !important; }
      .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter svg .hollaEX_logo_gray {
        fill: var(--calculated_base_footer_text); }
      .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter .blue-link {
        color: #00aeef !important; }
  .layout-mobile .app_footer-container .footer-row-content .blue-link {
    color: #00aeef !important; }

.layout-mobile .app_footer-container .footer-links-group {
  margin: 10rem; }

.layout-mobile .app_footer-container .footer-row-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--calculated_footer_border);
  margin-bottom: 1rem; }

.carousel__container {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.carousel__alice-wrapper {
  width: calc(100% - 6rem); }

.carousel__button {
  color: var(--specials_buttons-links-and-highlights);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 3rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  overflow: hidden; }
  .carousel__button.disabled {
    color: var(--labels_secondary-inactive-label-text-graphics);
    cursor: default; }

.controlled-scrollbar__button {
  color: var(--specials_buttons-links-and-highlights);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .controlled-scrollbar__button.disabled {
    color: var(--labels_secondary-inactive-label-text-graphics);
    cursor: default; }

.controlled-scrollbar__arrow {
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  height: 1.1rem;
  width: 1.1rem; }
  .controlled-scrollbar__arrow.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg); }
  .controlled-scrollbar__arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.slider__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.slider__slides-wrapper {
  width: calc(100% - 6rem);
  max-width: calc(100% - 6rem); }

.slider__view {
  display: flex; }

.slider__button {
  color: var(--specials_buttons-links-and-highlights);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 3rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  overflow: hidden; }
  .slider__button.disabled {
    color: var(--labels_secondary-inactive-label-text-graphics);
    opacity: 0.5;
    cursor: default; }

.slider__arrow {
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  height: 1.5rem;
  width: 1.5rem; }
  .slider__arrow.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .slider__arrow.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  .slider__arrow.small {
    height: 1rem;
    width: 1rem; }

.edit-wrapper__container {
  display: inline-flex !important;
  justify-content: center;
  align-items: center; }
  .edit-wrapper__container.reverse {
    flex-direction: row-reverse !important; }

.edit-wrapper__icons-container {
  display: none !important; }

.edit-wrapper__icon-wrapper {
  display: none !important;
  position: relative;
  width: 19px !important;
  height: 19px !important;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  font-size: 10px !important;
  text-decoration: none !important;
  font-weight: normal !important; }
  .edit-wrapper__icon-wrapper.medium {
    width: 35px !important;
    height: 35px !important; }
  .edit-wrapper__icon-wrapper.large {
    width: 50px !important;
    height: 50px !important; }

.edit-wrapper__icon {
  position: relative;
  z-index: -1;
  height: 100% !important;
  width: 100% !important; }

.layout-edit .edit-wrapper__icon-wrapper {
  display: inline-block !important; }

.layout-edit .edit-wrapper__icons-container {
  display: inline-flex !important; }

.check-deposit-modal-wrapper h2 {
  font-size: 20px;
  color: var(--labels_important-active-labels-text-graphics); }

.check-deposit-modal-wrapper .search-icon {
  width: 70px; }

.check-deposit-modal-wrapper .inner-content {
  border-top: 1px solid var(--calculated_secondary-border);
  margin-top: 3rem;
  padding: 10px 0; }
  .check-deposit-modal-wrapper .inner-content .field-header {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .check-deposit-modal-wrapper .inner-content .success-msg {
    width: 320px;
    color: var(--specials_checks-okay-done);
    margin: 0 3px; }
  .check-deposit-modal-wrapper .inner-content .error-msg {
    width: 320px;
    color: var(--specials_pending-waiting-caution); }
  .check-deposit-modal-wrapper .inner-content .verification_icon {
    width: 15px; }

.app-menu-bar-side {
  min-width: 18rem;
  max-width: 18rem; }

.direction_rtl .apply_rtl .app-menu-bar-side .side-bar-txt {
  margin: 0 25px 0 0; }

.app-side-bar .app-menu-bar-side {
  background-color: var(--base_wallet-sidebar-and-popup);
  border-right: 1px solid var(--calculated_secondary-border);
  padding: 20px 16px 0 15px; }
  .app-side-bar .app-menu-bar-side .app-menu-bar-icon {
    width: 20px; }

.app-side-bar .app-menu-bar-side_list {
  transition: 0.3s;
  height: 60px;
  padding: 18px 17px;
  cursor: pointer;
  color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .app-side-bar .app-menu-bar-side_list .side-bar-txt {
    height: 24px;
    margin: 0 0 0 14px;
    font-size: 16px; }

.app-side-bar .list_active,
.app-side-bar .app-menu-bar-side_list:hover {
  border-radius: 4px;
  background-color: var(--calculated_secondary-border-1); }
  .app-side-bar .list_active .side-bar-txt,
  .app-side-bar .app-menu-bar-side_list:hover .side-bar-txt {
    color: var(--calculated_base_top-bar-navigation_text) !important; }
  .app-side-bar .list_active .fill_secondary-color,
  .app-side-bar .app-menu-bar-side_list:hover .fill_secondary-color {
    fill: var(--calculated_base_top-bar-navigation_text) !important; }

.app-side-bar .list_active {
  background-color: var(--calculated_secondary-border) !important; }

@media (max-width: 991px) {
  .app-menu-bar-side {
    padding: 20px 8px 0 8px !important;
    min-width: 6rem !important;
    max-width: 10rem !important; }
    .app-menu-bar-side .app-menu-bar-icon {
      width: 15px !important; }
  .app-menu-bar-side_list,
  .list_active,
  .app-menu-bar-side_list:hover {
    width: 100%;
    padding: 0px 24px !important;
    font-size: 12px !important; }
  .app-menu-bar-side_list .side-bar-txt,
  .app-menu-bar-side_list .side-bar-text-active,
  .list_active .side-bar-txt,
  .list_active .side-bar-text-active {
    display: none !important; } }

@media (min-width: 1920px) {
  .app-menu-bar-side {
    max-width: 26rem;
    min-width: 26rem; } }

@media (max-width: 1096px) {
  .app-menu-bar-side {
    min-width: 20rem;
    max-width: 20rem; } }

.layout-mobile .app-menu-bar-side {
  display: none !important; }

.progressbar {
  height: 1.1rem;
  width: 11rem;
  background-color: var(--labels_important-active-labels-text-graphics); }

.progress-fill {
  height: 100%;
  background-repeat: no-repeat;
  background-size: 0;
  background-position: left; }

.progress-fill-completed {
  background-image: linear-gradient(var(--specials_buttons-links-and-highlights), var(--specials_buttons-links-and-highlights)); }

.progress-fill-in-progress {
  background-image: linear-gradient(var(--specials_pending-waiting-caution), var(--specials_pending-waiting-caution)); }

.progressbar-icon.in-progress {
  color: var(--specials_pending-waiting-caution); }

.progressbar-icon.completed {
  color: var(--specials_buttons-links-and-highlights); }

.default-remote-component-loader {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  color: var(--calculated_specials_highlight-box);
  padding-top: 3rem;
  padding-bottom: 3rem; }

.default-remote-component-error {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: var(--specials_notifications-alerts-warnings);
  padding-top: 3rem;
  padding-bottom: 3rem; }

.price-change.large {
  font-size: 2rem;
  font-weight: 700; }

.price-change .price-diff {
  color: var(--labels_secondary-inactive-label-text-graphics);
  padding: 0 5px;
  border-radius: 2px;
  transition: all 0.2s ease-out 0s;
  margin: auto 0; }
  .price-change .price-diff:before {
    visibility: hidden;
    display: inline-block !important;
    margin-right: 0.5rem;
    position: relative !important;
    top: calc(50% - 0.6rem); }
  .price-change .price-diff.down {
    color: var(--trading_selling-related-elements); }
    .price-change .price-diff.down:before {
      visibility: visible;
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: none !important;
      border-left: 0.4rem solid transparent !important;
      border-right: 0.4rem solid transparent !important;
      border-top: 0.4rem solid var(--trading_selling-related-elements);
      margin-top: 0rem; }
  .price-change .price-diff.up {
    color: var(--trading_buying-related-elements); }
    .price-change .price-diff.up:before {
      visibility: visible;
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-top: none !important;
      border-left: 0.4rem solid transparent !important;
      border-right: 0.4rem solid transparent !important;
      border-bottom: 0.4rem solid var(--trading_buying-related-elements);
      margin-bottom: 0rem;
      margin-bottom: 0.2rem; }

.price-change .glance-down.entering, .price-change .glance-down.exiting {
  color: var(--calculated_trading_selling-related-text);
  background-color: var(--trading_selling-related-elements); }
  .price-change .glance-down.entering:before, .price-change .glance-down.exiting:before {
    border-color: var(--calculated_trading_selling-related-text); }

.price-change .glance-up.entering, .price-change .glance-up.exiting {
  color: var(--calculated_trading_buying-related-text);
  background-color: var(--trading_buying-related-elements); }
  .price-change .glance-up.entering:before, .price-change .glance-up.exiting:before {
    border-color: var(--calculated_trading_buying-related-text); }

.hollaex-token-wrapper {
  font-family: 'Open Sans' !important; }

.tooltip-mark {
  fill: var(--labels_important-active-labels-text-graphics); }

.tooltip_source__active {
  text-decoration: underline;
  opacity: 0.7; }

.tooltip_icon_wrapper {
  height: 0.85rem;
  width: 0.85rem;
  position: relative;
  top: -0.57rem;
  margin: 0 0.2rem; }

.help-tooltip .ant-tooltip-content {
  border: 1px solid var(--labels_important-active-labels-text-graphics);
  border-radius: 5px;
  opacity: 1; }

.help-tooltip .ant-tooltip-arrow {
  display: none !important; }

.help-tooltip .ant-tooltip-arrow-content {
  display: none !important; }

.help-tooltip .ant-tooltip-inner {
  background-color: var(--base_background);
  color: var(--labels_important-active-labels-text-graphics); }

.not-logged-in_view {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important; }
  .not-logged-in_view.show-background {
    background-color: var(--base_wallet-sidebar-and-popup);
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .not-logged-in_view .icon_title-wrapper {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important; }
    .not-logged-in_view .icon_title-wrapper .icon_title-text {
      margin: 0 !important; }

/*This is to keep changes backward compatible for Guardarian, Simplex and Strip plugins*/
.currency-ball-image-wrapper {
  width: 1.6rem !important;
  height: 1.6rem !important; }

.input-group__coin-icons {
  width: 1.6rem;
  height: 1.6rem; }
  .input-group__coin-icons svg {
    margin: 0 auto;
    display: block;
    width: 100% !important;
    height: 100% !important; }

.intensity-bar-container {
  height: 0.8rem !important; }

.single-intensity-bar {
  height: 100% !important;
  margin: 0 1px !important;
  border-right: transparent 3px solid; }

.low-intensity {
  border-color: var(--specials_chat-messages); }

.medium-intensity {
  border-color: var(--specials_my-username-in-chat); }

.high-intensity {
  border-color: var(--specials_pending-waiting-caution); }

.extreme-intensity {
  border-color: var(--specials_notifications-alerts-warnings); }

html {
  font-size: 12px !important;
  height: 100%; }

#root {
  height: 100%; }

body {
  height: 100%;
  background-color: var(--base_background) !important;
  color: var(--labels_important-active-labels-text-graphics);
  font-size: 1.1rem !important;
  font-family: "Open Sans" !important;
  transition: all 0.3s; }

.half-opacity {
  opacity: 0.5; }

.direction_rtl.apply_rtl {
  direction: rtl; }

.direction_rtl .apply_rtl {
  direction: rtl; }

.direction_ltr {
  direction: ltr; }

.warning_text {
  color: var(--specials_notifications-alerts-warnings); }

.ReactModal__Body--open #root {
  filter: blur(5px);
  background: var(--calculated_base_modal-overlay); }

.form {
  min-width: 25rem;
  max-width: 25rem; }

.error-icon {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2rem !important; }
  .error-icon:before {
    content: '';
    width: 2rem;
    height: 100%;
    position: absolute;
    background-color: red;
    left: 0;
    top: calc(50% - 0.75rem); }

input,
input:focus {
  outline: none;
  border: none; }

.onHoverOpacity:hover {
  opacity: 0.5; }

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

*:not(.show-scroll)::-webkit-scrollbar {
  display: none; }

a {
  text-decoration: none;
  color: inherit; }
  a:hover, a:visited, a:link, a:active {
    color: inherit;
    text-decoration: none; }

.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 0 !important; }

@media screen and (max-width: 767px) {
  html {
    font-size: 8px !important; } }

@media (max-width: 991px) {
  .ReactModal__Body--open #root {
    filter: none !important;
    background: transparent !important; } }

svg .tab-api0,
svg .deposit-history-3,
svg .withdrawals-history-3,
svg .verification-email,
svg .verification-id,
svg .verification-document,
svg .verification-phone,
svg .verification-bank,
svg .notification-setting,
svg .language-setting,
svg .interface-setting,
svg .chat-setting,
svg .audio-setting,
svg .st1,
svg .account-recovery-success-2,
svg .password-reset-2,
svg .transaction-history-1,
svg .transaction-history-4,
svg .st11,
svg .wallet-coins-2,
svg .verification-doc,
svg .copy-notification-0,
svg .account-svg-1,
svg .incoming-btc-01,
svg .incoming-coin-01,
svg .coin-btc-5,
svg .search-blockchain,
svg .sparkle-dust,
svg .dust-sweeper,
svg .dust-settlement {
  fill: var(--labels_secondary-inactive-label-text-graphics); }

svg .fill_secondary-color {
  fill: var(--labels_secondary-inactive-label-text-graphics); }

svg .fill_main-color {
  fill: var(--labels_important-active-labels-text-graphics); }

svg .stroke_none {
  stroke: none; }

svg .dust-settlement-green {
  fill: var(--specials_checks-okay-done); }

svg .account-inactive {
  stroke: var(--labels_secondary-inactive-label-text-graphics); }

svg .withdraw-2,
svg .withdraw-3,
svg .deposit-bitcoin-2,
svg .deposit-bitcoin-3,
svg .deposit-history-1,
svg .withdrawals-history-1,
svg .risk-order-warning,
svg .otp-key,
svg .refer-icon,
svg .send-request,
svg .stake_icon,
svg .otp-code,
svg .st0,
svg .st9,
svg .st10,
svg .contact-us-icon,
svg .xht-coin-stack,
svg .account-recovery-1,
svg .account-recovery-success-1,
svg .password-reset-1,
svg .success-black-1,
svg .check-st,
svg .level_icon,
svg .warning-icon,
svg .transaction-history-2,
svg .transaction-history-3,
svg .resend-email-st,
svg .email-confirmation,
svg .secure-1,
svg .wallet-coins-1,
svg .api-key-active,
svg .api-key-st,
svg .generate_api,
svg .key-trashed,
svg .api-key-revoked,
svg .risk-order-warning,
svg .announcement,
svg .xht-pdf,
svg .xht-faq,
svg .xht-email,
svg .wave-icon,
svg .xht-docs,
svg .account-summary1,
svg .hap-icon,
svg .expired_icon,
svg .connect-loading,
svg .note-kyc,
svg .partial-fill,
svg .limit-trade-tick,
svg .chat-0,
svg .quick-trade-insufficient-funds,
svg .quick-trade-success,
svg .gender-female,
svg .gender-male,
svg .volume-pending,
svg .quick-trade-4,
svg .quick_trade-tab-icon,
svg .check-send-btc-1,
svg .copy-notification-1,
svg .data-1,
svg .deposit-complete-btc-01,
svg .deposit-complete-01,
svg .email_sent,
svg .gear-grey-1,
svg .g1_3,
svg .incoming-btc-2,
svg .incoming-coin-02,
svg .security-grey-1,
svg .coin-btc-1,
svg .wallet-selected-2,
svg .account-active-1,
svg .post-icon,
svg .item-options,
svg .check-order,
svg .otp-inactive,
svg .otp-active,
svg .unknown-error-icon,
svg .cancel-all,
svg .fiat-page-under-construction,
svg .generate-reference-number,
svg .osko-logo,
svg .stake-variable,
svg .stake-pending,
svg .staking-unlock,
svg .send-email-code,
svg .move-xht,
svg .connect-desktop,
svg .wallet-graphic,
svg .wallet-graphic-1 {
  fill: var(--labels_important-active-labels-text-graphics); }

svg .deposit-history-2,
svg .deposit-history-4,
svg .withdrawals-history-2,
svg .withdrawals-history-4,
svg .transaction-history-4,
svg .wallet-coins-1,
svg .quick-trade-3 {
  stroke: none; }

svg .stake-error-outline {
  fill: var(--labels_secondary-inactive-label-text-graphics); }

svg .stake-error-qmark {
  fill: var(--calculated_notification-pending-disabled); }

svg .deposit-bitcoin-1,
svg .withdraw-1,
svg .transaction-history-5,
svg .pending-timer,
svg .quick-trade-2,
svg .coin-btc-6,
svg .coin-btc-4,
svg .cancel-cross-3,
svg .cancel-cross-2,
svg .account-inactive {
  fill: none; }

svg .pending-timer,
svg .tab-close,
svg .icon-check-1,
svg .quick-trade-1,
svg .success-check-box-0,
svg .coin-btc-6,
svg .coin-btc-4,
svg .cancel-cross-3,
svg .cancel-cross-2 {
  stroke: var(--labels_important-active-labels-text-graphics); }

svg .quick-trade-tab-active {
  stroke: var(--calculated_base_top-bar-navigation_text-inactive);
  fill: var(--calculated_base_top-bar-navigation_text-inactive); }

svg .astrics-1 {
  fill: var(--labels_important-active-labels-text-graphics); }

svg .red-warning-0,
svg .astrics-3,
svg .astrics-2 {
  fill: var(--specials_notifications-alerts-warnings); }

svg .success-check-box-1 {
  stroke: var(--specials_checks-okay-done); }

svg .red-warning-1 {
  fill: var(--calculated_specials_notifications-alerts-text); }

svg .session-timeout-1 {
  fill: var(--specials_pending-waiting-caution); }

svg .session-timeout-0 {
  fill: var(--calculated_specials_notifications-pending-text); }

svg .deposit-complete-02,
svg .deposit-complete-btc-02 {
  fill: var(--specials_checks-okay-done); }
