:root {
	/*_________________________________*/

	/*COLOR MANAGEMENT*/

	/*_________________________________*/

	--base_background: #ffffff;
	--base_top-bar-navigation: #000000;
	--base_secondary-navigation-bar: #f2f2f3;
	--base_wallet-sidebar-and-popup: #f3f3f3;
	--base_footer: #000000;

	--labels_important-active-labels-text-graphics: #000000;
	--labels_secondary-inactive-label-text-graphics: #808080;
	--calculated_important-border: #000000b3;
	--calculated_secondary-border: #00000054;
	--calculated_footer_border: #ffffff26;
	--calculated_super-pale_label-text-graphics: #00000026;
	--labels_fields: #f0f1f1;
	--labels_inactive-button: #535353;

	--trading_selling-related-elements: #ee4036;
	--trading_buying-related-elements: #00a69c;

	--specials_buttons-links-and-highlights: #0066b4;
	--specials_chat-messages: #98ccb2;
	--specials_my-username-in-chat: #ffff00;
	--specials_checks-okay-done: #008000;
	--specials_pending-waiting-caution: #f6921e;
	--specials_notifications-alerts-warnings: #ed1c24;
	--specials_quick-trade-gradient: #00000000;

	/*_________________________________*/

	/*Calculated colors*/

	/*_________________________________*/

	--calculated_tag-background: #ffffffaa;
	--calculated_background-overlay: #ffffffaa;
	--calculated_stake-table_highlight: #ffffffaa;
	--calculated_trade-block_highlight: #000000fa;
	--calculated_market-card_gradient_background: #ffffff;
	--calculated_trading_selling-subtracted-bg: #ee403688;
	--calculated_trading_selling-added-bg: #ee403688;
	--calculated_trading_selling-best-subtracted: #ee403688;
	--calculated_trading_buying-subtracted-bg: #00a69c88;
	--calculated_trading_buying-added-bg: #00a69c88;
	--calculated_trading_buying-best-subtracted: #00a69c88;
	--calculated_public-sale_buying-zeros: #ee403688;
	--calculated_public-sale_selling-zeros: #00a69c88;
	--calculated_trading_selling-sparkline-fill: #ee40364d;
	--calculated_trading_buying-sparkline-fill: #00a69c4d;
	--calculated_trading_selling-orderbook-bar: #ee40361f;
	--calculated_trading_buying-orderbook-bar: #00a69c1f;
	--calculated_trading_buying-related-text: #000000;
	--calculated_trading_selling-related-text: #ffffff;
	--calculated_base_modal-overlay: #ffffffbf;
	--calculated_landing_background: #ffffff00;
	--calculated_landing_markets_table: #fffffff0;
	--calculated_specials_highlight-box: #0066b41a;
	--calculated_base_top-bar-navigation_text: #ffffff;
	--calculated_base_top-bar-navigation_text-inactive: #ffffffb3;
	--calculated_base_footer_text: #ffffff;
	--calculated_trad-view_watermark: #0000001a;
	--calculated_trad-view_axis: #00000080;
	--calculated_trad-view_text: #000000cc;
	--calculated_specials_notifications-alerts-inactive: #ed1c24bf;
	--calculated_specials_notifications-alerts-pale: #ed1c241f;
	--calculated_specials_notifications-alerts-text: #ffffff;
	--calculated_specials_notifications-pending-text: #ffffff;
	--calculated_specials_notifications-success-text: #ffffff;
	--calculated_notification-pending-disabled: #f6921e4d;
	--calculated_notifications-blue-disabled: #0066b44d;

	/*_________________________________*/

	/*_____Remaining_____*/
	--dark-accordion-arrow-active: #ffffff;
}
